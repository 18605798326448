import {
  forwardRef,
  MouseEvent,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { getCollectionRequest } from "services/apiRequests";
import { Course, Program, Project, Module, QuizListType } from "utils/interfaces";
import QuizCard from "./cards/QuizCard";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";

interface QuizzesListProps {
  ref: any;
  userRole: string;
  searchQuery: string;
  selectedData?: Program | Course | Project | Module | null;
  navRegister?: {
    lessonListToShow: string;
    selectedData: Program | Course | Project | Module | null;
    record: Program | Course | Project | Module | null;
  }[];
}

const QuizzesList: React.FC<QuizzesListProps> = forwardRef(
  ({
    userRole,
    searchQuery,
    selectedData,
    navRegister
  }, ref) => {
    const [data, setData] = useState<QuizListType[]>([]);

    // Define a navigator state
    const navigate = useNavigate();
    const { state } = useLocation();

    useImperativeHandle(ref, () => ({ }));

    /**
     * Root definition
     */
    useEffect(() => {
      if (selectedData !== null && selectedData !== undefined) {
        // console.log("selected data has been provided :)", selectedData)
        let _quizzes: QuizListType[] = [];
        if ('quizzes' in selectedData) {
          // console.log('quizzes in selected data, setting quizzes to show: ', _quizzes)
          _quizzes = selectedData.quizzes;
        }
        getData(_quizzes)
      } else {
        getData();
      }
    }, [selectedData]);

    /**
     * Function to get the data depending on the class
     * @returns data
     */
    const getData = async (quizIds: any[] | undefined = undefined) => {
      // Define the population option
      if (quizIds !== undefined) {
        const data = await getCollectionRequest(
          "/api/quizzes",
          [],
          {_id: {$in: quizIds}},
        );
        setData(data);
        return data;
      }

      const data = await getCollectionRequest(
        "/api/quizzes",
        [ ],
      );

      // Assign the data to the corresponding element
      setData(data);
      return data;
    };

    

    /**
     * Function applied when we press the edit button on cards
     */
    const handleCardClick = (
      record: QuizListType,
      selectedTab: number
    ) => {
      if (selectedTab === 0) {
        console.log(state);
        if (navRegister !== undefined) {
          navigate(`/quiz/course/${record._id}`, {
            state: {
              activeItem: "lessons",
              activeLesson: selectedData?._id,
              navRegister: navRegister,
            },
          });
        } else {
          navigate(`/quiz/course/${record._id}`, {state});
        }
      }
    };

    /**
     * Expand click definition
     */
    const handleExpandClick = (record: QuizListType) => {
      return (event: React.MouseEvent) => {
        event.stopPropagation();
        handleCardClick(record, 0);
      }
    };

    /**
     * Edit click definition
     */
    const handleEditClick = (record: QuizListType) => {
      return (event: React.MouseEvent) => {
        event.stopPropagation();
        handleCardClick(record, 1);
      };
    };

    /**
     * Assign to classes click definition
     */
    const handleAddToClasses = (record: QuizListType) => {
      return (event: React.MouseEvent) => {
        event.stopPropagation();
        handleCardClick(record, 2);
      }
    };

    /**
     * Function that renders the list of cards depending of the activeClass selected
     * @returns a list of cards
     */
    const cardsToRender = () => {
      return (
        data.length > 0 &&
        data
          .filter((item: QuizListType) =>
            item.title.toLowerCase().includes(searchQuery.toLowerCase())
          )
          .map((quiz: any, index: number) => (
            <QuizCard
              key={index}
              userRole={userRole}
              quiz={quiz}
              handleExpand={handleExpandClick(quiz)}
              handleEdit={handleEditClick(quiz)}
              handleAddToClasses={handleAddToClasses(quiz)}
            />
          ))
      );
    };

    return (
      <div className="w-full h-full">
        {data !== undefined && (
          <AnimatePresence>
            <motion.div className="w-full h-full overflow-y-auto custom-scroll">
              {cardsToRender()}
            </motion.div>
          </AnimatePresence>
        )}
      </div>
    );
  }
);

export default QuizzesList;
