import React from 'react'
import { MdCheckCircle, MdInfo, MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';

type QuizResponseStatusType = "unselected" | "selected" | "correct" | "incorrect" | "disabled" | "grey-disabled";

interface QuizResponseProps {
  index: number;
  text: string;
  status: QuizResponseStatusType;
  onClick: (index: number) => void;
}

const QuizResponse: React.FC<QuizResponseProps> = ({
  index,
  text,
  status,
  onClick
}) => {
  const clickEnabled = status === "unselected";

  const boxStyles = (status: QuizResponseStatusType) => {
    const className = 'w-full rounded-lg border justify-start items-start gap-3 inline-flex select-none box-border '
    switch (status) {
      case "unselected":
        return className + 'border border-neutral-500 p-3 cursor-pointer';
      case "selected":
        return className + 'bg-neutral-25 border-2 border-neutral-700 p-[11px]';
      case "correct":
        return className + 'bg-success-50 border-2 border-success-600 p-[11px]';
      case "incorrect":
        return className + 'bg-error-50 border-2 border-error-500 p-[11px]';
      case "grey-disabled":
        return className + 'bg-neutral-25 border-2 border-neutral-300 p-[11px]';
      case "disabled":
        return className + 'border border-neutral-500 p-3';
    }
  }

  const icon = (status: QuizResponseStatusType) => {
    switch (status) {
      case "unselected":
        return <MdRadioButtonUnchecked className="w-full h-full text-neutral-300" />;
      case "selected":
        return <MdRadioButtonChecked className="w-full h-full text-neutral-700" />;
      case "correct":
        return <MdCheckCircle className="w-full h-full text-success-600" />;
      case "incorrect":
        return <MdInfo className="w-full h-full text-error-500" />;
      case "grey-disabled":
        return <MdRadioButtonUnchecked className="w-full h-full text-neutral-300" />;
      case "disabled":
        return <MdRadioButtonUnchecked className="w-full h-full text-neutral-300" />;
    }
  }

  return (
    <div className={boxStyles(status)} onClick={() => clickEnabled && onClick(index)} >
      <div className="w-5 h-5 relative">
        {icon(status)}
      </div>
      <div className={`grow shrink basis-0 text-base font-normal leading-[19px] ${
        status === "grey-disabled"
          ? 'text-neutral-300'
          : 'text-neutral-700'
        }`}
      >
        {text}
      </div>
    </div>
  )
}

export default QuizResponse