import React from "react";
import {
  MdDeleteOutline,
  MdOutlineMeetingRoom,
  MdOutlineSchool,
} from "react-icons/md";
import { ClassListType, ContactListType } from "utils/interfaces";

interface ClassDetailsFormCardProps {
  id: string;
  name: string;
  teachers: ContactListType[];
  setSelectedClasses: React.Dispatch<React.SetStateAction<ClassListType[]>>;
}

const ClassDetailsFormCard: React.FC<ClassDetailsFormCardProps> = ({
  id,
  name,
  teachers,
  setSelectedClasses,
}) => {
  // Handle delete button
  const handleDelete = () => {
    // Delete the id from the list of selectedClassDataIds
    setSelectedClasses((currentList) =>
      currentList.filter((classElem: ClassListType) => classElem._id !== id)
    );
  };

  return (
    <div className="w-full h-fit p-4 bg-white rounded-lg border border-Subtle justify-center items-center gap-3 inline-flex">
      <div className="grow shrink basis-0 h-[65px] justify-center items-center gap-4 flex min-w-0">
        <div className="w-[68px] h-[60px] p-1.5 rounded border border-neutral-200 flex-col justify-start items-center gap-1 inline-flex">
          <div className="flex-col justify-start items-center gap-1 flex">
            <div className="w-8 h-8 relative">
              <MdOutlineMeetingRoom className="w-full h-full fill-neutral-500" />
            </div>
            <div className="text-neutral-500 text-[10px] font-normal font-sans uppercase leading-3 tracking-wide">
              Class
            </div>
          </div>
        </div>
        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1.5 inline-flex min-w-0 overflow-hidden">
          <div className="grow shrink basis-0 text-neutral-700 text-base font-semibold font-sans leading-[19px]">
            {name}
          </div>
          <div className="justify-start items-center gap-2 inline-flex overflow-hidden">
            <div className="grow shrink basis-0 h-[17px] justify-start items-center gap-1 flex overflow-hidden">
              <div className="w-4 h-4 relative">
                <MdOutlineSchool className="w-full h-full fill-neutral-600" />
              </div>
              <div className="justify-start items-start gap-2 inline-flex flex-shrink-0 overflow-hidden">
                {teachers.map((teacher: ContactListType, index: number) => (
                  <React.Fragment key={index}>
                    {index > 0 && (
                      <div className="self-stretch justify-start items-start gap-2 flex">
                        <div className="w-px self-stretch bg-neutral-200" />
                      </div>
                    )}
                    <div className="text-neutral-600 text-sm font-normal font-sans leading-[17px] whitespace-nowrap">
                      {`${teacher.firstName} ${teacher.lastName}`}
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
          {/* <div className="self-stretch justify-start items-center gap-2 inline-flex">
            <div
              className="self-stretch text-neutral-700 text-sm font-normal font-sans underline leading-[17px] cursor-pointer"
              onClick={() => {}}
            >
              View all
            </div>
          </div> */}
        </div>
      </div>
      <div className="w-10 self-stretch justify-center items-center gap-2 flex flex-shrink-0">
        <div
          className="p-2.5 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex cursor-pointer"
          onClick={handleDelete}
        >
          <div className="w-5 h-5 relative">
            <MdDeleteOutline className="w-full h-full fill-neutral-700" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassDetailsFormCard;
