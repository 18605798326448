import { useEffect, useState } from "react";
import { MdAdd, MdAddCircleOutline } from "react-icons/md";
import { ClassListType, ContactListType } from "utils/interfaces";
import TeacherCard from "./TeacherCard";
import AddTeacherPanel from "../add-teacher-pop-up/AddTeacherPanel";
import { createRecordRequest, updateRecordRequest } from "services/apiRequests";
import { formatUpdateClass } from "utils/formatUpdate";

interface CreateNewClassProps {
  selectedData?: ClassListType;
  popUpState: "main" | "create-new" | "add-teacher";
  setPopUpState: React.Dispatch<
    React.SetStateAction<"main" | "create-new" | "add-teacher">
  >;
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  afterCreateFunction: () => void;
}

const CreateNewClass: React.FC<CreateNewClassProps> = ({
  selectedData,
  popUpState,
  setPopUpState,
  setShowPopUp,
  afterCreateFunction
}) => {
  // States definition
  const [className, setClassName] = useState("");
  const [selectedTeachers, setSelectedTeachers] = useState<ContactListType[]>(
    []
  );
  const [activateCreateButton, setActivateCreateButton] = useState(false);

  /**
     * If there is selected data, then prefill the defined fields
     */
  useEffect(() => {
    if (selectedData) {
      setClassName(selectedData.name);
      setSelectedTeachers(selectedData.teachers);
    }
  }, []);

  /**
   * Function to activate or deactivate create parent button
   */
  useEffect(() => {
    // Check if they have content
    const condition = !!className && selectedTeachers.length > 0;
    setActivateCreateButton(condition);
  }, [className, selectedTeachers]);

  /**
   * Function that returns all the form data
   * @returns data
   */
  const provideData = () => {
    // Get the ids of the teachers selected
    const teacherIds = selectedTeachers.map(
      (teacher: ContactListType) => teacher._id
    );

    // Return all the gathered data in the form
    const dataToProvide = {
      name: className,
      teachers: teacherIds,
    };

    // Formatting the data to be sent as a request
    const formattedData = formatUpdateClass(selectedData, dataToProvide)

    return formattedData;
  };

  /**
   * Handle press on add a teacher button
   */
  const handleAddATeacher = () => {
    setPopUpState("add-teacher");
  };

  /**
   * Function to handle creating a contact when the button is pressed
   * @returns
   */
  const handleCreateClass = async () => {
    // Check if the button is already available
    if (!activateCreateButton) return;

    // Get all the data from the form
    const data = provideData();

    // Request for creating new contact
    let response = {successful: false, message: ""}
    if (selectedData) {
      response = await updateRecordRequest(
        { _id: selectedData._id, toUpdate: data },
        "/api/classes"
      );
    }
    else {
      response = await createRecordRequest(data, "/api/classes");
    }

    // Check if the response is successful
    if (!response.successful) {
      alert(response.message);
      return;
    };

    // Execute the defined actions after the creation
    afterCreateFunction(); 
  };

  /**
   * Function that
   * @param contact
   */
  const deleteAction = (contact: ContactListType) => {
    // Deleting the contact with the current id
    const contactsFiltered = selectedTeachers.filter(
      (currentContact: ContactListType) => currentContact._id !== contact._id
    );
    setSelectedTeachers(contactsFiltered);
  };

  return (
    <>
      {popUpState !== "add-teacher" && (
        <>
          <div className="w-full h-[595px] max-h-[90%] flex-col justify-start items-start gap-8 inline-flex overflow-y-auto pop-up-scrollbar">
            <div className="self-stretch h-fit flex-col justify-start items-start gap-4 flex">
              <div className="self-stretch text-neutral-500 text-base font-semibold font-sans leading-[19px]">
                Basic details
              </div>
              <div className="self-stretch h-fit flex-col justify-start items-start gap-1.5 flex">
                <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
                  Class Name*
                </div>
                <div className="self-stretch bg-neutral-50 rounded-lg justify-start items-center gap-2 inline-flex">
                  <input
                    type="text"
                    value={className}
                    onChange={(event) => setClassName(event.target.value)}
                    className="w-full h-full p-3 text-neutral-700 placeholder:text-neutral-300 text-base font-sans leading-[19px] bg-transparent border-none focus:outline-none"
                    placeholder="Enter name of the class"
                  />
                </div>
              </div>
            </div>
            <div className="self-stretch h-fit flex-col justify-start items-start gap-4 flex">
              <div className="self-stretch text-neutral-500 text-base font-semibold font-sans leading-[19px]">
                Teachers
              </div>
              {selectedTeachers.length === 0 ? (
                <div
                  className="self-stretch px-2 py-3 rounded-lg border border-dashed border-neutral-300 justify-center items-center gap-2 inline-flex cursor-pointer"
                  onClick={handleAddATeacher}
                >
                  <div className="w-6 h-6 relative">
                    <MdAdd className="w-full h-full fill-neutral-500" />
                  </div>
                  <div className="text-neutral-500 text-base font-normal font-sans leading-[19px]">
                    Add a Teacher
                  </div>
                </div>
              ) : (
                <div className="w-full h-fit flex-col justify-start items-start gap-4 inline-flex">
                  {selectedTeachers.map(
                    (teacher: ContactListType, index: number) => (
                      <TeacherCard
                        key={index}
                        contact={teacher}
                        deleteAction={deleteAction}
                      />
                    )
                  )}
                  <div className="self-stretch justify-between items-center inline-flex">
                    <div
                      className="self-stretch rounded justify-center items-center gap-2 flex cursor-pointer"
                      onClick={handleAddATeacher}
                    >
                      <div className="w-6 h-6 relative">
                        <MdAddCircleOutline className="w-full h-full fill-dc-secondary-600" />
                      </div>
                      <div className="text-dc-secondary-600 text-lg font-semibold font-sans leading-snug">
                        Add additional teachers
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className={
              activateCreateButton
                ? "self-end w-full h-fit px-5 py-3 bg-dc-secondary-600 rounded justify-center items-center gap-2 inline-flex cursor-pointer"
                : "self-end w-full h-fit px-5 py-3 bg-Subtle rounded justify-center items-center gap-2 inline-flex cursor-pointer"
            }
            onClick={handleCreateClass}
          >
            <div
              className={
                activateCreateButton
                  ? "text-white text-lg font-semibold font-sans leading-snug"
                  : "text-neutral-400 text-lg font-semibold font-sans leading-snug"
              }
            >
              {selectedData ? "Save class" : "Create class"}
            </div>
          </div>
        </>
      )}
      {popUpState === "add-teacher" && (
        <AddTeacherPanel
          selectedTeachers={selectedTeachers}
          setSelectedTeachers={setSelectedTeachers}
          setShowPopUp={setShowPopUp}
          externalSetPopUpState={setPopUpState}
        />
      )}
    </>
  );
};

export default CreateNewClass;
