/**
 * This file coordinates the workflow for the main view.
 */
import React, { useEffect, useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import MainHeader from "components/super-admin/utils/main-header/MainHeader";
import SideBarMenu from "components/super-admin/utils/SideBarMenu";
import Lessons from "components/super-admin/lessons/Lessons";
import Classes from "components/super-admin/classes/Classes";
import SubHeaderBar from "components/super-admin/utils/sub-header/SubHeaderBar";
import Dashboard from "components/super-admin/dashboard/Dashboard";
import Cookies from "js-cookie";
import { getCollectionRequest } from "services/apiRequests";
import { useLocation } from "react-router-dom";
import { SchoolListType } from "utils/interfaces";
import Quizzes from "components/super-admin/quizzes/Quizzes";
import Customers from "components/super-admin/customers/customers";

export const SuperAdmin: React.FC = () => {
  // States definition
  const [activeItem, setActiveItem] = useState("dashboard");
  const [backPressedToggle, setBackPressedToggle] = useState(false);
  const [showSideBarMenu, setShowSideBarMenu] = useState(true);

  const [userRole, setUserRole] = useState("");
  // Hardcode this with whatever organisation _id
  const [schoolId, setSchoolId] = useState("66a8214b17d0576be19b2a47");
  const [userSchool, setUserSchool] = useState<SchoolListType | null>(null);

  // References definition
  const dashboardRef = useRef();
  const lessonsRef = useRef();
  const classesRef = useRef();
  const customersRef = useRef();
  const quizzesRef = useRef();
  const headerRef = useRef();
  const subHeaderRef = useRef();

  // Get a state if we are receiving a state variable from another url
  const { state } = useLocation();

  /**
   * Define the come back behaviour based on an active item if there is 
   * a state with an active item defined
   */
  useEffect(() => {
    if (state) {
      // Set the selected active item
      if (state.activeItem) setActiveItem(state.activeItem);

      // Check first if we have a navRegister defined
      if (state.navRegister && state.navRegister.length > 0) {
        // Wait until lessonsRef is set
        const waitForLessonsRef = new Promise((resolve) => {
          const checkRef = () => {
            if (lessonsRef.current) {
              resolve(true);
            } else {
              requestAnimationFrame(checkRef);
            }
          };
          checkRef();
        });

        // Once the lessons ref is defined, set the navRegister
        waitForLessonsRef.then(() => {
          (lessonsRef.current as any).list().startFromLastNavRegister(state.navRegister);
        });
      }
      // And set the lessons list to the selected lesson if available
      else if (state.activeLesson) {
        // Wait until lessonsRef is set
        const waitForLessonsRef = new Promise((resolve) => {
          const checkRef = () => {
            if (lessonsRef.current) {
              resolve(true);
            } else {
              requestAnimationFrame(checkRef);
            }
          };
          checkRef();
        });

        // Once the lessons ref is defined, set the active lesson
        waitForLessonsRef.then(() => {
          (lessonsRef.current as any).setActiveLesson(state.activeLesson);
        });
      }
    }
  }, []);

  /**
   * Function to get data
   * @param filter
   * @returns
   */
  const getData = async () => {
    const data = await getCollectionRequest(
      "/api/organisations",
      ["_id", "name", "logo"],
      { _id: schoolId }
    );

    // Assign the data to the corresponding element
    setUserSchool(data[0]);

    return data;
  };

  /**
   * Get data on loading
   */
  useEffect(() => {
    if (userRole === "customer admin") getData();
  }, [activeItem, userRole]);

  /**
   * Assign the role based on the specified in the login view
   */
  useEffect(() => {
    // Get the role cookie
    const roleCookie = Cookies.get("role");
    // And set the value
    setUserRole(roleCookie || "");
  }, [])

  // Function used to render the main content based on the tab selected
  const renderContent = () => {
    switch (activeItem) {
      case "dashboard":
        return (
          <Dashboard
            ref={dashboardRef}
            headerRef={headerRef}
            subHeaderRef={subHeaderRef}
            customersRef={customersRef}
            userRole={userRole}
            backPressedToggle={backPressedToggle}
          />
        );
      case "school-options":
        return (
          <Customers
            ref={customersRef}
            userRole={userRole}
            backPressedToggle={backPressedToggle}
            headerRef={headerRef} 
            subHeaderRef={subHeaderRef}
            from="main"
            />
        );
      case "classes":
        return (
          <Classes
            ref={classesRef}
            userRole={userRole}
            backPressedToggle={backPressedToggle}
            headerRef={headerRef}
          />
        );
      case "users":
        return (
          <Classes
            ref={classesRef}
            userRole={userRole}
            backPressedToggle={backPressedToggle}
            headerRef={headerRef}
          />
        );
      case "lessons":
        return (
          <Lessons
            quizzesRef={quizzesRef}
            ref={lessonsRef}
            userRole={userRole}
            backPressedToggle={backPressedToggle}
            setShowSideBarMenu={setShowSideBarMenu}
            headerRef={headerRef}
          />
        );
      case "quizzes":
        return (
          <Quizzes
            ref={quizzesRef}
            userRole={userRole}
            backPressedToggle={backPressedToggle}
            headerRef={headerRef}
            quizzesRef={quizzesRef}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="w-screen h-screen min-h-screen p-8 flex flex-col gap-4">
      <MainHeader
        ref={headerRef}
        userRole={userRole}
        activeItem={activeItem}
        backPressedToggle={backPressedToggle}
        setBackPressedToggle={setBackPressedToggle}
        setShowSideBarMenu={setShowSideBarMenu}
        setActiveItem={setActiveItem}
        lessonsRef={lessonsRef}
        classesRef={classesRef}
        customersRef={customersRef}
        dashboardRef={dashboardRef}
        quizzesRef={quizzesRef}
        subHeaderRef={subHeaderRef}
      />
      <SubHeaderBar
        ref={subHeaderRef}
        activeItem={activeItem}
        dashboardRef={dashboardRef}
      />
      <div className="w-full h-full grow shrink justify-start items-start gap-8 inline-flex overflow-auto">
        {showSideBarMenu && (
          <SideBarMenu
            selected={activeItem}
            setActiveItem={setActiveItem}
            userRole={userRole}
            schoolId={schoolId}
            userSchool={userSchool}
            customersRef={customersRef}
          />
        )}
        {renderContent()}
      </div>
    </div>
  );
};

export default SuperAdmin;
