import React from "react";
import {
  MdArrowForward,
  MdBolt,
  MdOutlineAddToPhotos,
  MdOutlineEdit,
  MdStackedBarChart,
  MdTerminal,
} from "react-icons/md";
import { Module } from "utils/interfaces";

export interface ModuleCardProps {
  userRole: string;
  module: Module;
  handleExpand?: () => void;
  handleEdit?: (event: React.MouseEvent) => void;
  handleAdd?: (event: React.MouseEvent) => void;
  handleAddToClasses: (event: React.MouseEvent) => void;
}

const ModuleCard: React.FC<ModuleCardProps> = ({
  userRole,
  module,
  handleEdit,
  handleAdd,
  handleExpand,
  handleAddToClasses,
}) => {
  return (
    <div
      className="w-full h-[110px] p-6 bg-neutral-25 rounded-xl justify-between items-center inline-flex cursor-pointer"
      onClick={handleExpand}
    >
      <div className="w-[250px] h-[62px] justify-start items-center gap-2 flex">
        <img
          className="w-14 h-14 rounded"
          src={module.badgeImage || "logo.svg"}
          alt={module.title}
        />
        <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
          <div className="self-stretch text-neutral-700 text-sm font-semibold font-sans leading-[17px]">
            {module.title || "-"}
          </div>
          <div className="self-stretch text-neutral-600 text-[10px] font-normal font-sans leading-3 tracking-tight">
            {module.description || "-"}
          </div>
        </div>
      </div>
      <div className="justify-start items-center gap-1 flex">
        <div className="p-1 rounded-full justify-start items-end gap-2 flex">
          <div className="w-6 h-6 relative">
            <MdStackedBarChart className="w-full h-full fill-neutral-700" />
          </div>
        </div>
        <div className="flex-col justify-start items-center gap-1 inline-flex">
          <div className="flex-col justify-center items-center gap-1 flex">
            <div className="self-stretch text-neutral-700 text-sm font-semibold font-sans leading-[17px]">
              {module.level || "-"}
            </div>
            <div className="pb-[3px] flex-col justify-center items-end gap-2 flex">
              <div className="justify-center items-end gap-2 inline-flex">
                <div className="text-center text-neutral-500 text-[10px] font-normal font-sans uppercase leading-3 tracking-wide">
                  Program Level
                </div>
              </div>
            </div>
          </div>
          {/* <div className="self-stretch h-0.5 bg-neutral-200 flex-col justify-start items-start gap-2 flex">
            <div className="w-[21px] h-0.5 bg-success-500" />
          </div> */}
        </div>
      </div>
      <div className="justify-start items-center gap-1 flex">
        <div className="p-1 rounded-full justify-start items-end gap-2 flex">
          <div className="w-6 h-6 relative">
            <MdBolt className="w-full h-full fill-neutral-700" />
          </div>
        </div>
        <div className="flex-col justify-start items-center gap-1 inline-flex">
          <div className="flex-col justify-center items-center gap-1 flex">
            <div className="self-stretch text-neutral-700 text-sm font-semibold font-sans leading-[17px]">
              {module.experiencePoints || 0}
            </div>
            <div className="pb-[3px] flex-col justify-center items-end gap-2 flex">
              <div className="justify-center items-end gap-2 inline-flex">
                <div className="text-center text-neutral-500 text-[10px] font-normal font-sans uppercase leading-3 tracking-wide">
                  Knowledge points
                </div>
              </div>
            </div>
          </div>
          {/* <div className="self-stretch h-0.5 bg-neutral-200 flex-col justify-start items-start gap-2 flex">
            <div className="w-[21px] h-0.5 bg-success-500" />
          </div> */}
        </div>
      </div>
      <div className="justify-start items-center gap-1 flex">
        <div className="p-1 rounded-full justify-start items-end gap-2 flex">
          <div className="w-6 h-6 relative">
            <MdTerminal className="w-full h-full fill-neutral-700" />
          </div>
        </div>
        <div className="flex-col justify-start items-center gap-1 inline-flex">
          <div className="flex-col justify-center items-center gap-1 flex">
            <div className="self-stretch text-neutral-700 text-sm font-semibold font-sans leading-[17px]">
              {module.estimatedTime || 0}
            </div>
            <div className="pb-[3px] flex-col justify-center items-end gap-2 flex">
              <div className="justify-center items-end gap-2 inline-flex">
                <div className="text-center text-neutral-500 text-[10px] font-normal font-sans uppercase leading-3 tracking-wide">
                  Estimated time ( in mins)
                </div>
              </div>
            </div>
          </div>
          {/* <div className="self-stretch h-0.5 bg-neutral-200 flex-col justify-start items-start gap-2 flex">
            <div className="w-[21px] h-0.5 bg-success-500" />
          </div> */}
        </div>
      </div>
      {userRole === "master admin" && (
        <div className="w-[108px] self-stretch justify-center items-center gap-3 flex">
          <div
            className="p-3 bg-neutral-100 rounded-full justify-start items-start gap-2 flex cursor-pointer"
            onClick={handleAdd}
          >
            <div className="w-6 h-6 relative">
              <MdOutlineAddToPhotos className="w-full h-full fill-neutral-700" />
            </div>
          </div>
          <div
            className="p-3 bg-neutral-100 rounded-full justify-start items-start gap-2 flex cursor-pointer"
            onClick={handleEdit}
          >
            <div className="w-6 h-6 relative">
              <MdOutlineEdit className="w-full h-full fill-neutral-700" />
            </div>
          </div>
        </div>
      )}
      {userRole === "customer admin" && (
        <div className="w-[108px] self-stretch justify-center items-center gap-3 flex">
          <div
            className="p-3 bg-neutral-100 rounded-full justify-start items-start gap-2 flex cursor-pointer"
            onClick={handleAddToClasses}
          >
            <div className="w-6 h-6 relative">
              <MdOutlineAddToPhotos className="w-full h-full fill-neutral-700" />
            </div>
          </div>
          <div className="p-3 bg-neutral-100 rounded-full justify-start items-start gap-2 flex cursor-pointer">
            <div className="w-6 h-6 relative">
              <MdArrowForward className="w-full h-full fill-neutral-700" />
            </div>
          </div>
        </div>
      )}
      {["parent", "student"].includes(userRole) && (
        <div className="w-12 self-stretch justify-center items-center gap-3 flex">
          <div
            className="p-3 bg-neutral-100 rounded-full justify-start items-start gap-2 flex cursor-pointer"
            onClick={() => {}}
          >
            <div className="w-6 h-6">
              <MdArrowForward className="w-full h-full fill-neutral-700" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ModuleCard;
