import watermarkImage3 from "assets/imgs/watermark3.png";
import knowledgeImage from "assets/imgs/knowledgeLogo.svg";

interface MyKnowledgePointsProps {
  points: number;
}

const MyKnowledgePoints: React.FC<MyKnowledgePointsProps> = ({points}) => {
  return (
    <div className="self-stretch h-[362px] bg-neutral-25 rounded-3xl flex-col justify-between items-start flex overflow-hidden relative">
      <img
        className="absolute w-[362px] h-[362px] right-[-140px] rotate-[5.94deg] opacity-20 select-none"
        src={watermarkImage3}
      />
      <div className="self-stretch py-5 px-4 ustify-between items-start inline-flex">
        <img src={knowledgeImage} className="w-fit h-fit relative" />
      </div>
      <div className="self-stretch p-6 justify-between items-end inline-flex">
        <div className="grow shrink basis-0 flex-col justify-start items-start gap-4 inline-flex">
          <div className="self-stretch relative">
            <div className="text-neutral-700 text-[33px] font-semibold font-sans leading-10">
              {points}
            </div>
          </div>
          <div className="self-stretch h-fit flex-col justify-start items-start gap-2 flex">
            <div className="self-stretch text-neutral-700 text-lg font-normal font-sans leading-snug">
              My Knowledge Points*
            </div>
            <div className="h-fit text-neutral-500 text-xs font-sans leading-[14px]">
              <span className="font-normal">You have earned </span>
              <span className="font-semibold">{points} </span>
              <span className="font-normal">knowledge points</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyKnowledgePoints;
