import CreateNewPopUpClasses from "components/super-admin/classes/utils/CreateNewPopUp";
import CreateNewPopUpCustomers from "components/super-admin/customers/utils/CreateNewPopUp";
import { forwardRef, ReactElement, useImperativeHandle, useState, useEffect } from "react";
import {
  MdAdd,
  MdClose,
  MdCorporateFare,
  MdOutlineExpandMore,
  MdOutlinePerson,
  MdOutlineSave,
} from "react-icons/md";
import { AnimatePresence, motion } from "framer-motion";

interface DashboardHeaderOptionsProps {
  ref: any;
  userRole: string;
  setActiveItem: React.Dispatch<React.SetStateAction<string>>;
  setShowSideBarMenu: React.Dispatch<React.SetStateAction<boolean>>;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setDescription: React.Dispatch<React.SetStateAction<ReactElement | string>>;
  setShowBackButton: React.Dispatch<React.SetStateAction<boolean>>;
  dashboardRef: React.MutableRefObject<any>;
  customersRef: React.MutableRefObject<any>;
  subHeaderRef: React.MutableRefObject<any>;
}

const DashboardHeaderOptions: React.FC<DashboardHeaderOptionsProps> =
  forwardRef(
    (
      {
        userRole,
        setActiveItem,
        setShowSideBarMenu,
        setTitle,
        setDescription,
        setShowBackButton,
        dashboardRef,
        customersRef,
        subHeaderRef,
      },
      ref
    ) => {
      // States definition
      const [firstName, setFirstName] = useState("Kyah");
      const [lastName, setLastName] = useState("Symons");
      const [showCreateNewPopUp, setShowCreateNewPopUp] = useState(false);
      const [showCreateNew, setShowCreateNew] = useState(true);
      const [saveButton, setSaveButton] = useState<{
        show: boolean;
        disabled: boolean;
      }>({ show: false, disabled: true });
      // State for controlling where to redirect on customer admin school logo pressing
      const [activeItemOnBack, setActiveItemOnBack] = useState("");
      // State for controlling what create pop up I should display on master admin dashboard
      const [createTypeMasterAdmin, setCreateTypeMasterAdmin] = useState<
        "customers" | "classes"
      >("customers");

      /**
       * Define the list of functions that can be used from external components
       * using a reference
       */
      useImperativeHandle(ref, () => ({
        setShowCreateNewButton(bool: boolean) {
          setShowCreateNew(bool);
        },
        setSaveButton(options: { show: boolean; disabled: boolean }) {
          setSaveButton(options);
        },
        pressCreateNewSchool() {
          onCreateEditSchool();
        },
        pressEditSchool(title: string = "", description: string = "") {
          onCreateEditSchool(title, description);
        },
        onDiscard() {
          onDiscard();
        },
        setActiveItemOnBack(activeItem: string) {
          setActiveItemOnBack(activeItem);
        },
        setFirstName(firstName: string) {
          setFirstName(firstName);
        },
        setLastName(lastName: string) {
          setLastName(lastName);
        },
        setCreateTypeMasterAdmin(type: "customers" | "classes") {
          setCreateTypeMasterAdmin(type);
        },
      }));

      /**
       * Handle profile pressing
       */
      const handlePressProfile = () => {
        dashboardRef.current.student().setShowProfilePopUp(true);
      };

      /**
       * CreateNewPopUp functions definition
       */
      // Create class
      const onCreateClass = () => {
        // Close the pop up panel menu
        setShowCreateNewPopUp(false);

        // And open the create class pop up window
        dashboardRef.current.setShowCreateNewClass(true);
      };
      // Create Student
      const onCreateEditStudent = (
        title = "New Student",
        description = "Student"
      ) => {
        // Regarding the header
        setShowCreateNewPopUp(false);
        setShowBackButton(true);
        setShowSideBarMenu(false);
        setShowCreateNew(false);
        setSaveButton({ show: true, disabled: true });
        setTitle(title);
        setDescription(
          <div className="w-fit flex gap-1">
            <MdOutlinePerson />
            <div>{description}</div>
          </div>
        );

        // And set the status
        dashboardRef.current.setCreateNewStudent(true);
        // And hide the subheader
        subHeaderRef.current.setShow(false);
      };

      // Create Student
      const onCreateEditSchool = (
        title = "New School",
        description = "School"
      ) => {
        // Regarding the header
        setShowCreateNewPopUp(false);
        setShowBackButton(true);
        setShowSideBarMenu(false);
        setShowCreateNew(false);
        setSaveButton({ show: true, disabled: true });
        setTitle(title);
        setDescription(
          <div className="w-fit flex gap-1">
            <MdCorporateFare />
            <div>{description}</div>
          </div>
        );

        // And set the status
        customersRef.current.setCreateSchool(true);
      };

      /**
       * Function that defines the root view
       */
      const onDiscard = () => {
        if (userRole === "master admin") {
          // And set the status
          customersRef.current.setCreateSchool(false);
          // And force unselect data
          customersRef.current.setSelectedData(null);
          // And force hide the subheader
          subHeaderRef.current.setShow(false);

          // Activate the side bar menu
          setShowSideBarMenu(true);

          // Change the tab name
          setTitle("Schools");
          setDescription("List of current schools");
          // Delete back button
          setShowBackButton(false);
          // And turn off the other buttons
          setSaveButton({ show: false, disabled: true });
          // Activating the create new button
          setShowCreateNew(true);

          // If there is a specified tab to come back, then come back
          if (activeItemOnBack) {
            setActiveItem(activeItemOnBack);
          }
          // Set the type back to customers
          setCreateTypeMasterAdmin("customers");
        }
        if (userRole === "customer admin") {
          console.log("paso");
          // And set the status
          dashboardRef.current.setCreateNewStudent(false);
          // And show the subheader again
          subHeaderRef.current.setShow(true);

          // Activate the side bar menu
          setShowSideBarMenu(true);

          // Change the tab name
          setTitle("Dashboard");
          setDescription("Welcome, Customer Admin");
          // Delete back button
          setShowBackButton(false);
          // And turn off the other buttons
          setSaveButton({ show: false, disabled: true });
          // Activating the create new button
          setShowCreateNew(true);
        }
      };

      useEffect(() => {
        setTitle("Dashboard");
        setDescription(`Welcome, ${firstName}`)
      }, [firstName])

      /**
       * Function to control the save button
       */
      const handleSave = () => {
        // If the save button is available, then make it work
        if (!saveButton.disabled) {
          if (userRole === "master admin") {
            customersRef.current.pressSaveButton();
          } else if (userRole === "customer admin") {
            dashboardRef.current.pressSaveButton();
          }
        }
      };

      return (
        <>
          {userRole === "student" && (
            <div
              className="h-24 pl-3 py-3 rounded-full flex-col justify-center items-center gap-2 inline-flex cursor-pointer"
              onClick={handlePressProfile}
            >
              <div className="justify-center items-center gap-0.5 inline-flex">
                <div className="px-[9.50px] py-2.5 bg-Canvas rounded-full justify-center items-center gap-2 flex">
                  <div className="text-dc-secondary-600 text-[23px] font-semibold font-sans leading-7">
                    {firstName[0].toUpperCase() || "-"}
                    {lastName[0].toUpperCase() || "-"}
                  </div>
                </div>
                <div className="w-4 h-4 relative">
                  <MdOutlineExpandMore className="w-4 h-4 text-dc-secondary-600" />
                </div>
              </div>
              <div className="justify-center items-center gap-0.5 inline-flex">
                <div className="text-center text-dc-secondary-600 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide">
                  My Profile
                </div>
                <div className="w-4 h-4 px-[4.28px] opacity-0 justify-center items-center flex" />
              </div>
            </div>
          )}
          {userRole === "master admin" && showCreateNew && (
            <div className="p-3 rounded-full flex-col justify-center items-center gap-2 inline-flex relative">
              <div
                className="justify-center items-center gap-0.5 inline-flex cursor-pointer"
                onClick={() => {
                  if (!showCreateNewPopUp)
                    setShowCreateNewPopUp(!showCreateNewPopUp);
                }}
              >
                <div className="p-3 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex">
                  <div className="w-6 h-6 relative">
                    <MdAdd className="w-full h-full fill-dc-secondary-600" />
                  </div>
                </div>
                <div className="w-4 h-4 relative">
                  <MdOutlineExpandMore className="w-4 h-4 text-dc-secondary-600" />
                </div>
              </div>
              <div className="justify-center items-center gap-0.5 inline-flex">
                <div className="text-center text-dc-secondary-600 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide whitespace-nowrap select-none">
                  Create
                </div>
                <div className="w-4 h-4 px-[4.28px] opacity-0 justify-center items-center flex" />
              </div>
              {showCreateNewPopUp && (
                <>
                  {createTypeMasterAdmin === "customers" ? (
                    <CreateNewPopUpCustomers
                      setShowCreateNewPopUp={setShowCreateNewPopUp}
                      onCreateEditSchool={() => onCreateEditSchool()}
                    />
                  ) : createTypeMasterAdmin === "classes" ? (
                    <CreateNewPopUpClasses
                      setShowCreateNewPopUp={setShowCreateNewPopUp}
                      onCreateClass={onCreateClass}
                      onCreateEditStudent={() => onCreateEditStudent()}
                    />
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          )}
          <AnimatePresence initial={false}>
            {userRole === "customer admin" && showCreateNew && (
              <motion.div
                className="p-3 rounded-full flex-col justify-center items-center gap-2 inline-flex relative"
                initial={{
                  opacity: 0,
                }}
                animate={{
                  opacity: 1,
                }}
                exit={{
                  opacity: 0,
                }}
              >
                <div
                  className="justify-center items-center gap-0.5 inline-flex cursor-pointer"
                  onClick={() => {
                    if (!showCreateNewPopUp)
                      setShowCreateNewPopUp(!showCreateNewPopUp);
                  }}
                >
                  <div className="p-3 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex">
                    <div className="w-6 h-6 relative">
                      <MdAdd className="w-full h-full fill-dc-secondary-600" />
                    </div>
                  </div>
                  <div className="w-4 h-4 relative">
                    <MdOutlineExpandMore className="w-4 h-4 text-dc-secondary-600" />
                  </div>
                </div>
                <div className="justify-center items-center gap-0.5 inline-flex">
                  <div className="text-center text-dc-secondary-600 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide whitespace-nowrap select-none">
                    Create
                  </div>
                  <div className="w-4 h-4 px-[4.28px] opacity-0 justify-center items-center flex" />
                </div>
                {showCreateNewPopUp && (
                  <CreateNewPopUpClasses
                    setShowCreateNewPopUp={setShowCreateNewPopUp}
                    onCreateClass={onCreateClass}
                    onCreateEditStudent={() => onCreateEditStudent()}
                  />
                )}
              </motion.div>
            )}
          </AnimatePresence>
          {saveButton.show && (
            <div className="justify-start items-start gap-1 flex">
              <div className="p-3 rounded-full flex-col justify-center items-center gap-2 inline-flex">
                <div className="justify-center items-center gap-0.5 inline-flex">
                  <div
                    className="p-3 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex cursor-pointer"
                    onClick={onDiscard}
                  >
                    <div className="w-6 h-6 relative">
                      <MdClose className="w-full h-full fill-dc-secondary-600" />
                    </div>
                  </div>
                </div>
                <div className="justify-center items-center gap-0.5 inline-flex">
                  <div className="text-center text-dc-secondary-600 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide">
                    Discard
                  </div>
                </div>
              </div>
              <div className="p-3 rounded-full flex-col justify-center items-center gap-2 inline-flex">
                <div
                  className={
                    saveButton.disabled
                      ? "justify-center items-center gap-0.5 inline-flex"
                      : "justify-center items-center gap-0.5 inline-flex cursor-pointer"
                  }
                  onClick={handleSave}
                >
                  <div className="p-3 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex">
                    <div className="w-6 h-6 relative">
                      <MdOutlineSave
                        className={
                          saveButton.disabled
                            ? "w-full h-full fill-neutral-300"
                            : "w-full h-full fill-dc-secondary-600"
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="justify-center items-center gap-0.5 inline-flex">
                  <div
                    className={
                      saveButton.disabled
                        ? "text-center text-neutral-300 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide whitespace-nowrap"
                        : "text-center text-dc-secondary-600 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide whitespace-nowrap"
                    }
                  >
                    Save
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      );
    }
  );

export default DashboardHeaderOptions;
