import { ChangeEvent } from "react";
import { MdOutlineBackup } from "react-icons/md";

interface InputBadgeImageProps {
  text: string;
  badgeImage: string | null;
  setBadgeImage: React.Dispatch<React.SetStateAction<string | null>>;
}

const InputBadgeImage: React.FC<InputBadgeImageProps> = ({
  text,
  badgeImage,
  setBadgeImage,
}) => {
  // Parameters definition
  const formatsAllowed = ["image/png", "image/jpeg"];
  const maxFileSize = 4 * 1024 * 1024; // 4 MB

  /**
   * Function to handle the image upload
   * @param event
   */
  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    // Get the file
    const file = event.target.files?.[0];

    // Check if there is an inputted file
    if (!file) {
      alert("No file was selected.");
      return;
    }

    // Check if the of the uploaded file is the defined in the
    // formats list allowed for this placeholder
    if (!formatsAllowed.includes(file.type)) {
      alert(
        `Format "${file.type}" is not allowed. Please try with another file.`
      );
      return;
    }

    // Check that the size is the allowed one
    if (file.size > maxFileSize) {
      const sizeInMb = (file.size / 1024 / 1024).toFixed(2);
      alert(`File size (${sizeInMb} MB) is larger than allowed.`);
      return;
    }

    // Perform the loading
    const reader = new FileReader();
    reader.onloadend = () => {
      setBadgeImage(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  /**
   * For removing badge image
   */
  const removeImage = () => {
    setBadgeImage("");
  };

  return (
    <div className="self-stretch h-fit flex-col justify-center items-center gap-2 flex">
      <div className="w-[109px] h-[109px] rounded-full border border-neutral-400 border-dashed flex-col justify-center items-center gap-2 flex relative">
        {badgeImage ? (
          <img
            src={badgeImage}
            alt="Badge"
            className="w-full h-full object-cover rounded-full"
          />
        ) : (
          <>
            <div className="w-10 h-10 relative">
              <MdOutlineBackup className="w-full h-full fill-neutral-300" />
            </div>
            <input
              type="file"
              accept={formatsAllowed.join(", ")}
              onChange={handleImageUpload}
              className="hidden"
              id="badge-image-upload"
            />
            <label
              htmlFor="badge-image-upload"
              className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
            />
          </>
        )}
      </div>
      {badgeImage ? (
        <button
          onClick={removeImage}
          className="text-error-500 text-xs font-normal font-sans mt-2"
        >
          Remove Image
        </button>
      ) : (
        <div className="self-stretch h-7 flex-col justify-center items-center gap-0.5 flex">
          <div className="self-stretch justify-start items-center gap-2 inline-flex">
            <div className="grow shrink basis-0 text-center text-neutral-450 text-xs font-normal font-sans leading-[14px]">
              {text}
            </div>
          </div>
          <div className="self-stretch justify-start items-center gap-2 inline-flex">
            <div className="grow shrink basis-0 text-center text-neutral-450 text-[10px] font-sans leading-3 tracking-tight">
              <span className="font-normal">(</span>
              <span className="font-semibold">File types</span>
              <span className="font-normal">: PNG, JPEG, </span>
              <span className="font-semibold">Max. file size</span>
              <span className="font-normal">: 2 MB)</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InputBadgeImage;
