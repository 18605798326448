import React, { useState } from "react";
import {
  MdAddCircleOutline,
  MdOutlineAddLocationAlt,
  MdOutlineArrowCircleLeft,
  MdOutlineArrowCircleRight,
} from "react-icons/md";
import LocationCardForm from "./LocationCardForm";
import { LocationListType } from "utils/interfaces";
import PopUpPanel from "../pop-up-panels/PopUpPanel";

interface LocationDetailsProps {
  locations: LocationListType[];
  setLocations: React.Dispatch<React.SetStateAction<LocationListType[]>>;
  setStage: React.Dispatch<React.SetStateAction<number>>;
}

const LocationDetails: React.FC<LocationDetailsProps> = ({
  locations,
  setLocations,
  setStage,
}) => {
  // States definition
  const [showAddLocationPopUp, setShowAddLocationPopUp] = useState(false);

  /**
   * Define handle previous button
   */
  const handlePrev = () => {
    setStage(1);
  };

  /**
   * Define handle next button
   */
  const handleNext = () => {
    setStage(3);
  };

  /**
   * Function that handle the add location pop up
   */
  const handleAddLocation = () => {
    setShowAddLocationPopUp(true);
  };

  /**
   * Function to handle the delete action
   */
  const deleteAction = (index: number) => {
    setLocations([...locations.slice(0, index), ...locations.slice(index + 1)]);
  };

  return (
    <div className="w-[562px] h-full p-6 rounded-3xl justify-start items-start gap-2 inline-flex">
      <div className="w-full flex-col justify-start items-start gap-12 inline-flex">
        <div className="self-stretch h-7 flex-col justify-start items-start gap-12 flex">
          <div className="self-stretch text-neutral-700 text-[23px] font-semibold font-sans leading-7">
            Location details
          </div>
        </div>
        {locations.length === 0 && (
          <div
            className="self-stretch h-12 flex-col justify-start items-start gap-12 flex cursor-pointer"
            onClick={handleAddLocation}
          >
            <div className="w-full px-2 py-3 rounded-lg border border-dashed border-neutral-300 justify-center items-center gap-2 inline-flex">
              <div className="w-6 h-6 relative">
                <MdOutlineAddLocationAlt className="w-full h-full fill-neutral-500" />
              </div>
              <div className="text-gray-500 text-base font-normal font-sans leading-[19px]">
                Add a location
              </div>
            </div>
          </div>
        )}
        {locations.length > 0 && (
          <div className="self-stretch h-fit flex-col justify-start items-start gap-5 flex">
            {locations.map((location: LocationListType, index: number) => (
              <LocationCardForm
                key={index}
                index={index}
                location={location}
                deleteAction={deleteAction}
              />
            ))}
            <div className="self-stretch justify-between items-center inline-flex">
              <div
                className="self-stretch rounded justify-center items-center gap-2 flex cursor-pointer"
                onClick={handleAddLocation}
              >
                <div className="w-6 h-6 relative">
                  <MdAddCircleOutline className="w-full h-full fill-dc-secondary-600" />
                </div>
                <div className="text-dc-secondary-600 text-lg font-semibold font-sans leading-snug">
                  Add additional location
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="h-[46px] flex flex-row justify-between items-center w-full">
          <div
            className="w-[74px] h-6 flex rounded justify-center items-center gap-2 cursor-pointer"
            onClick={handlePrev}
          >
            <div className="w-6 h-6 relative">
              <MdOutlineArrowCircleLeft className="w-full h-full fill-dc-secondary-600" />
            </div>
            <div className="text-dc-secondary-600 text-lg font-semibold font-sans">
              Prev
            </div>
          </div>

          <div
            className="rounded justify-center items-center gap-2 flex cursor-pointer"
            onClick={handleNext}
          >
            <div
              className={
                true
                  ? "text-dc-secondary-600 text-lg font-semibold font-sans leading-snug"
                  : "text-stone-300 text-lg font-semibold font-sans leading-snug"
              }
            >
              Next
            </div>
            <div className="w-6 h-6 relative">
              <MdOutlineArrowCircleRight
                className={
                  true
                    ? "w-full h-full fill-dc-secondary-600"
                    : "w-full h-full fill-stone-300"
                }
              />
            </div>
          </div>
        </div>
      </div>
      <PopUpPanel
        type={"add-location"}
        showPopUp={showAddLocationPopUp}
        setShowPopUp={setShowAddLocationPopUp}
        setLocations={setLocations}
      />
    </div>
  );
};

export default LocationDetails;
