import {
  getIconText,
  getProgressBarColor,
  getStatusIcon,
} from "components/super-admin/utils/progressUtils";
import React from "react";
import { MdOutlineEdit } from "react-icons/md";
import { ClassListType, ContactListType, StudentListType } from "utils/interfaces";

interface StudentCardProps {
  student: StudentListType;
  handleCardClick: (card: ClassListType | ContactListType | any) => void;
}

const StudentCard: React.FC<StudentCardProps> = ({
  student,
  handleCardClick
}) => {
  const courseCompletionColor = getProgressBarColor(student.progressRate || 0);
  const averageScoreColor = getProgressBarColor(student.averageScore || 0);

  return (
    <div className="w-full h-fit px-6 py-4 bg-neutral-25 rounded-xl justify-between items-center inline-flex">
      <div className="w-[190px] self-stretch flex-col justify-center items-center inline-flex">
        <div className="self-stretch text-neutral-700 text-lg font-semibold font-sans leading-snug overflow-hidden break-keep whitespace-nowrap text-ellipsis">
          {`${student.firstName} ${student.lastName}` || "-"}
        </div>
      </div>
      <div className="flex-col justify-center items-center gap-1 inline-flex">
        <div className="text-center text-neutral-500 text-lg font-semibold font-sans leading-snug">
          {student.progressRate || 0}%
        </div>
        <div className="text-center text-neutral-500 text-xs font-normal font-inter uppercase leading-[14px] tracking-wide">
          Course Completed
        </div>
        <div className="self-stretch h-1.5 bg-neutral-200 rounded-sm flex-col justify-between items-start flex">
          <div
            className={`h-1.5 ${courseCompletionColor} rounded-tl-sm rounded-bl-sm`}
            style={{ width: `${student.progressRate}%` }}
          />
        </div>
      </div>
      <div className="flex-col justify-center items-center gap-1 inline-flex">
        <div className="text-center text-neutral-500 text-lg font-semibold font-sans leading-snug">
          {student.averageScore || 0}%
        </div>
        <div className="text-center text-neutral-500 text-xs font-normal font-inter uppercase leading-[14px] tracking-wide">
          Avg. Quiz Score
        </div>
        <div className="self-stretch h-1.5 bg-neutral-200 rounded-sm flex-col justify-between items-start flex">
          <div
            className={`h-1.5 ${averageScoreColor} rounded-tl-sm rounded-bl-sm`}
            style={{ width: `${student.averageScore}%` }}
          />
        </div>
      </div>
      <div className="flex-col justify-center items-center gap-1 inline-flex">
        <div className="text-center text-neutral-500 text-lg font-semibold font-sans leading-snug">
          {student.badges ? student.badges.length : 0}
        </div>
        <div className="text-center text-neutral-500 text-xs font-normal font-inter uppercase leading-[14px] tracking-wide">
          Bagdes Earned
        </div>
        <div className="self-stretch h-1.5 opacity-0 bg-neutral-200 rounded-sm flex-col justify-between items-start flex">
          <div className="w-24 h-1.5 bg-emerald-500 rounded-tl-sm rounded-bl-sm" />
        </div>
      </div>
      <div className="w-[45px] self-stretch justify-center items-center flex">
        <div className="flex-col justify-start items-center gap-1 inline-flex">
          <div className="w-8 h-8 relative">
            {getStatusIcon(student.progressRate || 0)}
          </div>
          <div className="justify-center items-center gap-2 inline-flex">
            <div className="text-neutral-500 text-xs font-semibold font-sans leading-[14px]">
              {getIconText(student.progressRate || 0)}
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch justify-center items-center gap-3 flex">
        <div
          className="p-3 bg-neutral-100 rounded-full justify-start items-start gap-2 flex cursor-pointer"
          onClick={() => handleCardClick(student)}
        >
          <div className="w-6 h-6 relative">
            <MdOutlineEdit className="w-full h-full fill-neutral-700" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentCard;
