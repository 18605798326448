import { MdOutlineEdit } from "react-icons/md";
import formatPhoneNumber from "utils/formatPhoneNumber";
import { ClassListType, TeacherListType, ParentListType } from "utils/interfaces";

interface TeacherCardProps {
  teacher: TeacherListType;
  handleCardClick: (card: ClassListType | TeacherListType | ParentListType | any) => void;
}

const TeacherCard: React.FC<TeacherCardProps> = ({
  teacher,
  handleCardClick
}) => {
  return (
    <div className="w-full h-20 px-6 py-4 bg-neutral-25 rounded-xl justify-between items-center inline-flex">
      <div className="w-[190px] self-stretch flex-col justify-center items-start inline-flex">
        <div className="self-stretch text-neutral-700 text-lg font-semibold font-sans leading-snug overflow-hidden break-keep whitespace-nowrap text-ellipsis">
          {(teacher.firstName && teacher.lastName) ? `${teacher.firstName} ${teacher.lastName}` : "-"}
        </div>
      </div>
      <div className="flex-col justify-center items-center gap-1 inline-flex">
        <div className="text-center text-neutral-500 text-sm font-normal font-sans leading-[17px]">
          {teacher.phone ? formatPhoneNumber(teacher.phone) : "-"}
        </div>
        <div className="text-center text-neutral-500 text-[10px] font-normal font-sans uppercase leading-3 tracking-wide">
          Phone number
        </div>
      </div>
      <div className="flex-col justify-center items-center gap-1 inline-flex">
        <div className="text-center text-neutral-500 text-lg font-semibold font-sans leading-snug">
          {teacher.classes ? teacher.classes.length : 0}
        </div>
        <div className="text-center text-neutral-500 text-[10px] font-normal font-sans uppercase leading-3 tracking-wide">
          Allocated to classes
        </div>
        {/* <div className="self-stretch h-1.5 opacity-0 bg-neutral-200 rounded-sm flex-col justify-between items-start flex">
          <div className="w-24 h-1.5 bg-success-500 rounded-tl-sm rounded-bl-sm" />
        </div> */}
      </div>
      <div className="w-[239px] flex-col justify-center items-center gap-1 inline-flex">
        <div className="self-stretch text-center text-neutral-500 text-sm font-normal font-sans leading-[17px]">
          {teacher.email || "-"}
        </div>
        <div className="text-center text-neutral-500 text-[10px] font-normal font-sans uppercase leading-3 tracking-wide">
          Email address
        </div>
      </div>
      <div className="self-stretch justify-center items-center gap-3 flex">
        <div
          className="p-3 bg-neutral-100 rounded-full justify-start items-start gap-2 flex cursor-pointer"
          onClick={() => handleCardClick(teacher)}
        >
          <div className="w-6 h-6 relative">
            <MdOutlineEdit className="w-full h-full fill-neutral-700" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherCard;
