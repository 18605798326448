import { forwardRef } from "react";
import ProgressCard from "./ProgressCard";
import { ClassListType } from "utils/interfaces";
import ActivityChart from "./ActivityChart";
import Leaderboard from "../utils/leaderboard";

interface ClassDashboardProps {
  ref?: any;
  headerRef: React.MutableRefObject<any>;
  classData: ClassListType | any;
}

const ClassDashboard: React.FC<ClassDashboardProps> = forwardRef(
  ({ headerRef, classData }, ref) => {
    return (
      <>
        {/* Main components */}
        {/* w-full h-full flex-col justify-start items-start gap-4 inline-flex overflow-y-auto custom-scroll */}
        <div className="w-full h-full justify-start flex-col inline-flex gap-8 overflow-y-auto custom-scroll">
          <div className="w-full flex gap-8">
            <ProgressCard
              percentage={classData.enrollmentRate}
              progressType={"Enrolment rate"}
            >
              <span className="font-semibold">25</span> Students enrolled of{" "}
              <span className="font-semibold">100</span> licenses
            </ProgressCard>
            <ProgressCard
              percentage={classData.progressRate}
              progressType={"Progress"}
            >
              Average completion of assigned projects
            </ProgressCard>
            <ProgressCard
              percentage={classData.aptitudeRate}
              progressType={"Aptitude"}
            >
              Average score on completed projects
            </ProgressCard>
          </div>
          <div className="w-full flex gap-8">
            <Leaderboard
              students={classData.students}
              showStatus={false}
              showRightArrow={false}
              arrow={""}
            />
            <ActivityChart hasStudents={classData.students.length > 0} />
          </div>
        </div>
      </>
    );
  }
);

export default ClassDashboard;
