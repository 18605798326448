import {
  getIconText,
  getProgressBarColor,
  getStatusIcon,
} from "components/super-admin/utils/progressUtils";
import {
  MdModelTraining,
  MdOutlineEdit,
  MdOutlineHowToReg,
  MdOutlinePsychology,
  MdOutlineSchool,
} from "react-icons/md";
import {
  ClassListType,
  ContactListType,
} from "utils/interfaces";

interface ClassCardProps {
  classItem: any;
  canEdit: boolean;
  handleCardClick: (
    card: ClassListType
  ) => void;
  setShowTeachersList: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedTeachers: React.Dispatch<React.SetStateAction<ContactListType[]>>;
}

const ClassCard: React.FC<ClassCardProps> = ({
  classItem,
  canEdit,
  handleCardClick,
  setShowTeachersList,
  setSelectedTeachers,
}) => {
  /**
   * Handle view all click
   */
  const handleClickViewAll = (event: React.MouseEvent) => {
    // First of all, stop propagating to prevent click on the card
    event.stopPropagation();
    // And open the pop up for showing
    setShowTeachersList(true);
    // Set the selected teachers of this class
    setSelectedTeachers(classItem.teachers);
  };

  return (
    <div
      className={`w-full h-44 p-6 bg-neutral-25 rounded-xl justify-between items-center inline-flex ${canEdit ? '' : "cursor-pointer"}`}
      onClick={canEdit ? () => {} : () => handleCardClick(classItem)}
    >
      {/* Left section (class name and teacher(s))*/}
      <div className="w-[190px] self-stretch flex-col justify-between items-start inline-flex">
        <div className="self-stretch text-neutral-700 text-lg font-semibold font-sans leading-snug overflow-hidden break-keep whitespace-nowrap text-ellipsis">
          {classItem.name}
        </div>
        <div className="self-stretch justify-center items-center gap-2 inline-flex">
          <div className="w-8 h-8 relative">
            <MdOutlineSchool className="w-full h-full fill-neutral-300" />
          </div>
          <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
            <div className="text-center text-neutral-500 text-[10px] font-normal font-sans uppercase leading-3 tracking-wide">
              Teacher
            </div>
            <div className="self-stretch text-neutral-600 text-xs font-normal font-sans leading-[14px]">
              {`${classItem.teachers[0].firstName} ${classItem.teachers[0].lastName}`}
            </div>
            {classItem.teachers.length > 1 && (
              <div
                className="self-stretch text-neutral-500 text-xs font-normal font-sans underline leading-[14px] cursor-pointer"
                onClick={handleClickViewAll}
              >
                View all
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-[190px] p-1 rounded flex-col justify-start items-start gap-3 inline-flex">
        <div className="self-stretch h-20 flex-col justify-start items-start gap-1 flex">
          <div className="p-2 bg-Canvas rounded-full justify-start items-end gap-2 inline-flex">
            <div className="w-8 h-8 relative">
              <MdOutlineHowToReg className="w-full h-full fill-neutral-700" />
            </div>
          </div>
          <div className="self-stretch justify-start items-end gap-1 inline-flex">
            <div className="w-[41px] text-right text-neutral-700 text-lg font-semibold font-sans leading-snug">{classItem.enrollmentRate}%</div>
            <div className="pb-[3px] flex-col justify-center items-end gap-2 inline-flex">
              <div className="justify-center items-end gap-2 inline-flex">
                <div className="text-center text-neutral-500 text-[10px] font-normal font-sans uppercase leading-3 tracking-wide">Enrolment</div>
              </div>
            </div>
          </div>
          <div className="self-stretch h-0.5 bg-neutral-100 flex-col justify-start items-start gap-2 flex">
            <div
              className={`h-0.5 ${getProgressBarColor(classItem.enrollmentRate)}`}
              style={{ width: `${classItem.enrollmentRate}%` }}
            />
          </div>
        </div>
        <div className="self-stretch flex-col justify-start items-start gap-0.5 flex">
          <div className="justify-start items-center gap-1 inline-flex">
            <div className="w-8 text-center text-neutral-500 text-sm font-semibold font-sans leading-[17px]">{classItem.students.length || 0}</div>
            <div className="text-center text-neutral-500 text-xs font-normal uppercase leading-[14px] tracking-wide">Students enrolled</div>
          </div>
          <div className="justify-start items-center gap-1 inline-flex">
            <div className="w-8 text-center text-neutral-500 text-sm font-semibold font-sans leading-[17px]">{classItem.licenses || 0}</div>
            <div className="text-center text-neutral-500 text-xs font-normal uppercase leading-[14px] tracking-wide">Licenses</div>
          </div>
        </div>
      </div>
      <div className="w-[190px] p-1 rounded flex-col justify-start items-start gap-3 inline-flex">
        <div className="self-stretch h-20 flex-col justify-start items-start gap-1 flex">
          <div className="p-2 bg-Canvas rounded-full justify-start items-end gap-2 inline-flex">
            <div className="w-8 h-8 relative">
              <MdModelTraining className="w-full h-full fill-neutral-700" />
            </div>
          </div>
          <div className="self-stretch justify-start items-end gap-1 inline-flex">
            <div className="w-[41px] text-right text-neutral-700 text-lg font-semibold font-sans leading-snug">{classItem.progressRate}%</div>
            <div className="pb-[3px] flex-col justify-center items-end gap-2 inline-flex">
              <div className="justify-center items-end gap-2 inline-flex">
                <div className="text-center text-neutral-500 text-[10px] font-normal font-sans uppercase leading-3 tracking-wide">Progress</div>
              </div>
            </div>
          </div>
          <div className="self-stretch h-0.5 bg-neutral-100 flex-col justify-start items-start gap-2 flex">
            <div
              className={`h-0.5 ${getProgressBarColor(classItem.progressRate)}`}
              style={{ width: `${classItem.progressRate}%` }}
            />
          </div>
        </div>
        <div className="self-stretch flex-col justify-start items-start gap-0.5 flex">
          <div className="self-stretch h-7 flex-col justify-start items-start gap-0.5 flex">
            <div className="self-stretch text-neutral-500 text-xs font-normal font-sans leading-[14px]">Average completion of assigned projects</div>
          </div>
        </div>
      </div>
      <div className="w-[190px] p-1 rounded flex-col justify-start items-start gap-3 inline-flex">
        <div className="self-stretch h-20 flex-col justify-start items-start gap-1 flex">
          <div className="p-2 bg-Canvas rounded-full justify-start items-end gap-2 inline-flex">
            <div className="w-8 h-8 relative">
              <MdOutlinePsychology className="w-full h-full fill-neutral-700" />
            </div>
          </div>
          <div className="self-stretch justify-start items-end gap-1 inline-flex">
            <div className="w-[41px] text-right text-neutral-700 text-lg font-semibold font-sans leading-snug">{classItem.aptitudeRate}%</div>
            <div className="pb-[3px] flex-col justify-center items-end gap-2 inline-flex">
              <div className="justify-center items-end gap-2 inline-flex">
                <div className="text-center text-neutral-500 text-[10px] font-normal font-sans uppercase leading-3 tracking-wide">Aptitude</div>
              </div>
            </div>
          </div>
          <div className="self-stretch h-0.5 bg-neutral-100 flex-col justify-start items-start gap-2 flex">
            <div
              className={`h-0.5 ${getProgressBarColor(classItem.aptitudeRate)}`}
              style={{ width: `${classItem.aptitudeRate}%` }}
            />
          </div>
        </div>
        <div className="flex-col justify-start items-start gap-0.5 flex">
          <div className="self-stretch h-7 flex-col justify-start items-start gap-0.5 flex">
            <div className="self-stretch text-neutral-500 text-xs font-normal font-sans leading-[14px]">Average score on completed projects</div>
          </div>
        </div>
      </div>
      <div className="w-[60px] self-stretch justify-between items-center flex">
        <div className="flex-col justify-start items-center gap-1 inline-flex">
          <div className="w-8 h-8 relative">
            {getStatusIcon(classItem.overallRate)}
          </div>
          <div className="justify-center items-center gap-2 inline-flex">
            <div className="text-neutral-500 text-xs font-semibold font-sans leading-[14px]">{getIconText(classItem.overallRate)}</div>
          </div>
        </div>
      </div>
      {canEdit && (
        <div className="self-stretch justify-center items-center gap-3 flex">
          <div
            className="p-3 bg-neutral-100 rounded-full justify-start items-start gap-2 flex cursor-pointer"
            onClick={() => handleCardClick(classItem)}
          >
            <div className="w-6 h-6 relative">
              <MdOutlineEdit className="w-full h-full fill-neutral-700" />
            </div>
          </div>
        </div>
      )}
  </div>
  );
};

export default ClassCard;
