import { GoArrowLeft } from "react-icons/go";
import LocationCard from "./LocationCard";
import { useEffect, useRef, useState } from "react";
import { MdAddCircleOutline, MdSearch } from "react-icons/md";
import { LocationListType } from "utils/interfaces";
import CreateLocation from "./CreateLocation";

interface AddLocationPanelProps {
  setLocations: React.Dispatch<React.SetStateAction<LocationListType[]>>;
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddLocationPanel: React.FC<AddLocationPanelProps> = ({
  setLocations,
  setShowPopUp,
}) => {
  // States defintion
  const [locationData, setLocationData] = useState<LocationListType[]>([]);
  const [searchText, setSearchText] = useState("");
  const [popUpState, setPopUpState] = useState<
    "main" | "create-new" | "select-state"
  >("create-new");

  /**
   * Definition of the function when pressed back button
   */
  const handlePressBack = () => {
    // If we are inputing a state, then come back
    if (popUpState === "select-state") setPopUpState("create-new");
    // Otherwise, close the pop up
    else setShowPopUp(false);
  };

  // Input reference for focus
  const searchNumberInputRef = useRef<HTMLInputElement>(null);

  // And definition of the focus function
  const handleFocus = () => {
    if (searchNumberInputRef.current) {
      searchNumberInputRef.current.focus();
    }
  };

  /**
   * Load data function
   */
  const getData = async () => {
    // Get the parents from the database record
    let data = [
      {
        address: "1 Flinders Ave",
        city: "Hillarys",
        state: "WA",
        postCode: "6025",
      },
      {
        address: "2 Flinders Ave",
        city: "Hillarys",
        state: "WA",
        postCode: "6025",
      },
      {
        address: "3 Flinders Ave",
        city: "Hillarys",
        state: "WA",
        postCode: "6025",
      },
      {
        address: "4 Flinders Ave",
        city: "Hillarys",
        state: "WA",
        postCode: "6025",
      },
      {
        address: "5 Flinders Ave",
        city: "Hillarys",
        state: "WA",
        postCode: "6025",
      },
      {
        address: "6 Flinders Ave",
        city: "Hillarys",
        state: "WA",
        postCode: "6025",
      },
    ];
    return [];
    return data;
  };

  /**
   * Function used when selecting a card
   * @param location
   */
  const selectAction = (location: LocationListType) => {
    // Add to the current selected contact data ids
    setLocations((currentLocations) => [
      ...new Set([...currentLocations, location]),
    ]);

    // And close pop up
    setShowPopUp(false);
  };

  /**
   * Load class data when init
   */
  useEffect(() => {
    // Get the data
    getData().then((data: LocationListType[]) => {
      // Set the data
      setLocationData(data);
    });
  }, []);

  /**
   * Function to handle when clicking the search icon
   */
  const handleSearch = () => {};

  return (
    <>
      <div className="self-stretch justify-between items-center inline-flex">
        <div className="justify-start items-center gap-3 flex">
          <div className="p-1.5 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex">
            <div
              className="w-5 h-5 justify-center items-center flex cursor-pointer"
              onClick={handlePressBack}
            >
              <div className="w-5 h-5 relative">
                <GoArrowLeft className="w-full h-full fill-dc-secondary-600" />
              </div>
            </div>
          </div>
          <div className="text-neutral-600 text-lg font-normal font-sans leading-snug">
            {popUpState === "select-state"
              ? "Select State/Territory"
              : popUpState === "create-new"
              ? "Add Location"
              : ""}
          </div>
        </div>
        {popUpState === "main" && (
          <div
            className="rounded justify-center items-center gap-2 flex cursor-pointer"
            onClick={() => setPopUpState("create-new")}
          >
            <div className="w-6 h-6 relative">
              <MdAddCircleOutline className="w-full h-full fill-dc-secondary-600" />
            </div>
            <div className="text-dc-secondary-600 text-lg font-semibold font-sans leading-snug">
              Create new
            </div>
          </div>
        )}
      </div>
      {["create-new", "select-state"].includes(popUpState) ? (
        <CreateLocation popUpState={popUpState} setPopUpState={setPopUpState} setLocations={setLocations} setShowPopUp={setShowPopUp} />
      ) : (
        <>
          <div className="w-full h-[51px] bg-neutral-50 rounded-lg justify-start items-center gap-2 inline-flex relative">
            <input
              ref={searchNumberInputRef}
              type="text"
              value={searchText}
              onChange={(event) => setSearchText(event.target.value)}
              className="w-full h-full p-3 pr-9 bg-transparent focus:outline-none text-neutral-700 placeholder:text-neutral-300 text-base font-normal font-sans leading-[19px]"
              placeholder="Search address"
            />
            <div
              className="absolute right-3 w-5 h-5 cursor-pointer"
              onClick={handleSearch}
            >
              <MdSearch className="w-full h-full fill-neutral-600" />
            </div>
          </div>
          <div className="self-stretch flex-col justify-start items-start gap-2 inline-flex overflow-y-auto pop-up-scrollbar">
            {locationData.map((location: LocationListType, index: number) => (
              <LocationCard
                key={index}
                location={location}
                selectAction={selectAction}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default AddLocationPanel;
