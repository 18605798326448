interface ChartTooltipProps {
  userName: string;
}

const ChartTooltip: React.FC<ChartTooltipProps> = ({userName}) => {
  return (
    <div className="bg-white border border-neutral-300 rounded-md p-4 last:flex-col justify-start items-start gap-2 flex">
      <div className="justify-start items-center gap-2 inline-flex">
        <div className="w-6 h-1.5 relative bg-dc-accent-500 rounded" />
        <div className="text-neutral-500 text-[10px] font-normal font-sans leading-3 tracking-tight">
          {userName}
        </div>
      </div>
      <div className="justify-start items-center gap-2 inline-flex">
        <div className="w-6 h-1.5 relative bg-neutral-500 rounded" />
        <div className="text-neutral-500 text-[10px] font-normal font-sans leading-3 tracking-tight">
          Class
        </div>
      </div>
    </div>
  );
};

export default ChartTooltip;
