import { MdAddCircleOutline } from "react-icons/md";
import { GoArrowLeft } from "react-icons/go";
import { useEffect, useState } from "react";
import AssignLessonCard from "./AssignLessonCard";
import lessonInformation from "components/super-admin/lessons/utils/lessonInformation";
import {
  getCollectionRequest,
  updateRecordRequest,
} from "services/apiRequests";
import { Program, Course, Project, Module } from "utils/interfaces";
import SuccessPopUpMessage from "./SuccessPopUpMessage";
import EmptyViewPopUp from "components/super-admin/utils/pop-up-panels/EmptyViewPopUp";

interface AssignLessonPanelProps {
  selectedDataId: string;
  selectedLesson: string;
  fromAddModuleCardButton: boolean;
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  setLinkedId: React.Dispatch<React.SetStateAction<string>>;
  setLinkedLesson: React.Dispatch<React.SetStateAction<string>>;
  headerRef?: React.MutableRefObject<any>;
}

const AssignLessonPanel: React.FC<AssignLessonPanelProps> = ({
  selectedDataId,
  selectedLesson,
  fromAddModuleCardButton,
  setShowPopUp,
  setLinkedId,
  setLinkedLesson,
  headerRef,
}) => {
  // States definition
  const [lessonData, setLessonData] = useState<
    Program[] | Course[] | Project[] | Module[]
  >([]);
  const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
  const [successPopUpText, setSuccessPopUpText] = useState("");
  const [addOrRemovePopUp, setAddOrRemovePopUp] = useState<"add" | "remove">(
    "add"
  );
  // State used to remember the last selection for the dismiss operation
  const [lastSelectedId, setLastSelectedId] = useState("");
  const [lastNumElement, setLastNumElement] = useState(0);
  const [setLastCard, setSetLastCard] = useState(() => {
    return (state: { selected: boolean; numElements: number }) => {};
  });
  // State defined to deal with the card render of this element when creating
  // new lesson from this pop up
  const [interestParent, setInterestParent] = useState(false);

  /**
   * Constants definition based on the origin button
   */
  // We want to get the grandparent info from selectedLesson.
  // Example: When we click edit on a "course", the selectedLesson is
  // "module" (what we are watching). So we want to add the "course"
  // to a "program", which is the grandparent of selectedLesson = "module".
  const interestLesson =
    fromAddModuleCardButton || interestParent
      ? lessonInformation(selectedLesson).parent
      : lessonInformation(lessonInformation(selectedLesson).parent).parent;
  // Definition of the lesson that needs to be assigned
  const lessonTobeAssigned =
    fromAddModuleCardButton || interestParent
      ? selectedLesson
      : lessonInformation(selectedLesson).parent;

  /**
   * Function to get all the data of the list
   * @returns data: Program | Course | Project | Module
   */
  const getData = async () => {
    // Get the data from the database record
    const data = await getCollectionRequest(`/api/${interestLesson}`);
    return data;
  };

  /**
   * Definition of the Add or remove action
   * @param id of the selected id in the pop up menu
   * @param action "add" or "remove" "selectedData._id" from "id"
   */
  const selectAction = async (
    lesson: Program | Course | Project | Module,
    action: "add" | "remove",
    newNumElements: number,
    setSelected: React.Dispatch<React.SetStateAction<boolean>>,
    setDynamicNumElements: React.Dispatch<React.SetStateAction<number>>
  ) => {
    // Set the type of action
    setAddOrRemovePopUp(action);

    // And add or remove the data depending on the case
    let response;
    if (action === "add") {
      response = await updateRecordRequest(
        {
          _id: lesson._id,
          toUpdate: { $addToSet: { [lessonTobeAssigned]: selectedDataId } },
        },
        `/api/${interestLesson}`
      );
    } else if (action === "remove") {
      response = await updateRecordRequest(
        {
          _id: lesson._id,
          toUpdate: { $pull: { [lessonTobeAssigned]: selectedDataId } },
        },
        `/api/${interestLesson}`
      );
    }

    // Check if the response was successful
    if (!response.successful) return false;

    // Send the signal to show the pop up message
    setSuccessPopUpText(lesson.title);
    setShowSuccessPopUp(true);
    // Define the state handlers of the last card selected for "dismiss" purposes
    setLastSelectedId(lesson._id);
    setLastNumElement(newNumElements);
    setSetLastCard(() => {
      return (state: { selected: boolean; numElements: number }) => {
        setSelected(state.selected);
        setDynamicNumElements(state.numElements);
      };
    });

    return true;
  };

  // Definition of the dismiss action
  const dismissAction = async () => {
    // Definition of the final states after dismiss
    let selected: boolean = false;
    let numElements: number = 0;

    if (addOrRemovePopUp === "add") {
      let response = await updateRecordRequest(
        {
          _id: lastSelectedId,
          toUpdate: { $pull: { [lessonTobeAssigned]: selectedDataId } },
        },
        `/api/${interestLesson}`
      );

      // And return to the old values
      selected = false;
      numElements = lastNumElement - 1;
    } else if (addOrRemovePopUp === "remove") {
      let response = await updateRecordRequest(
        {
          _id: lastSelectedId,
          toUpdate: { $addToSet: { [lessonTobeAssigned]: selectedDataId } },
        },
        `/api/${interestLesson}`
      );

      // And return to the old values
      selected = true;
      numElements = lastNumElement + 1;
    }

    // And come back to the original state
    setLastCard({ selected: selected, numElements: numElements });
  };

  /**
   * Select create new inside the pop up
   */
  const handleCreateNew = () => {
    // Check if the header reference is included
    if (!headerRef) return;

    // If we are creating then, define the lesson to be added as child
    setLinkedId(selectedDataId);
    setLinkedLesson(lessonTobeAssigned);

    // Set interest lesson as the parent instead of the grandparent
    // to avoid the conflict between the chain effect produced by
    // changing selectedLesson when calling the header function.
    // Other solution for this problem would be prevent displaying
    // the cards in this element when "showPopUp" is false
    setInterestParent(true);

    // Navigate to create a new lesson
    if (interestLesson === "programs")
      headerRef.current.lessons().pressCreateNewProgram();
    else if (interestLesson === "courses")
      headerRef.current.lessons().pressCreateNewCourse();
    else if (interestLesson === "projects")
      headerRef.current.lessons().pressCreateNewProject();
    else if (interestLesson === "modules")
      headerRef.current.lessons().pressCreateNewModule();

    // And close the pop up
    setShowPopUp(false);
  };

  /**
   * Load lesson data when init
   */
  useEffect(() => {
    // Get all the data to show as options
    getData().then((data: Program[] | Course[] | Project[] | Module[]) => {
      // Set the data
      setLessonData(data);
    });
  }, []);

  /**
   * Timer for the success pop up message
   */
  useEffect(() => {
    // Starting the timer
    const timer = setTimeout(() => {
      // Once finished, close the pop up
      setShowSuccessPopUp(false);

      // And forget the information of the last selected card
      setLastSelectedId("");
      setLastNumElement(0);
      setSetLastCard(() => {
        return (state: { selected: boolean; numElements: number }) => {};
      });
    }, 5000);
    return () => clearTimeout(timer);
  }, [showSuccessPopUp]);

  return (
    <>
      <div className="self-stretch justify-between items-center inline-flex">
        <div className="justify-start items-center gap-3 flex">
          <div
            className="p-1.5 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center flex cursor-pointer"
            onClick={() => setShowPopUp(false)}
          >
            <div className="w-5 h-5 justify-center items-center flex">
              <div className="w-5 h-5 relative">
                <GoArrowLeft className="w-full h-full fill-dc-secondary-600" />
              </div>
            </div>
          </div>
          <div className="text-neutral-600 text-lg font-normal font-sans leading-snug">
            Assign to {interestLesson.slice(0, -1)}
          </div>
        </div>
        <div className="p-2 opacity-0 bg-neutral-50 rounded-full justify-start items-start gap-2 flex">
          <div className="w-4 h-4 p-[3.61px] justify-center items-center flex" />
        </div>
        <div
          className="rounded justify-center items-center gap-2 flex cursor-pointer"
          onClick={handleCreateNew}
        >
          <div className="w-6 h-6 relative">
            <MdAddCircleOutline className="w-full h-full fill-dc-secondary-600" />
          </div>
          <div className="text-dc-secondary-600 text-lg font-semibold font-sans leading-snug">
            Create new
          </div>
        </div>
      </div>
      <div className="max-h-[400px] grow shrink justify-start items-start gap-[5px] inline-flex overflow-y-auto pop-up-scrollbar pr-[5px]">
        <div className="w-[403px] h-fit self-stretch flex-col justify-start items-start gap-4 inline-flex">
          {lessonData.length > 0 ? (
            lessonData.map((item: any, index: number) => (
              <AssignLessonCard
                key={index}
                selectedLesson={selectedLesson}
                lesson={item}
                numElements={item[lessonTobeAssigned] ? item[lessonTobeAssigned].length : 0}
                selectedInit={item[lessonTobeAssigned] ? item[lessonTobeAssigned].includes(selectedDataId) : false}
                selectAction={selectAction}
              />
            ))
          ) : (
            <EmptyViewPopUp />
          )}
        </div>
      </div>
      <SuccessPopUpMessage
        lessonToAdd={interestLesson.slice(0, -1)}
        showSuccessPopUp={showSuccessPopUp}
        successPopUpText={successPopUpText}
        addOrRemove={addOrRemovePopUp}
        setShowSuccessPopUp={setShowSuccessPopUp}
      />
    </>
  );
};

export default AssignLessonPanel;
