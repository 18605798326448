import { ClassListType } from "utils/interfaces";
import AssignClassCard from "./AssignClassCard";
import { useEffect, useState } from "react";
import removeItem from "utils/removeItem";
import EmptyViewPopUp from "../EmptyViewPopUp";
import {
  getCollectionRequest,
  updateRecordRequest,
} from "services/apiRequests";

interface ClassesCardsListProps {
  selectedClasses: ClassListType[];
  closeOnceClickOnCard: boolean;
  selectedLesson?: string;
  lessonIdToAssign?: string;
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedClasses: React.Dispatch<React.SetStateAction<ClassListType[]>>;
}

const ClassesCardsList: React.FC<ClassesCardsListProps> = ({
  selectedClasses,
  closeOnceClickOnCard,
  selectedLesson,
  lessonIdToAssign,
  setShowPopUp,
  setSelectedClasses,
}) => {
  // States definition
  const [classData, setClassData] = useState<ClassListType[]>([]);
  const [selectedClassDataIds, setSelectedClassDataIds] = useState<string[]>(
    []
  );
  const [addOrRemovePopUp, setAddOrRemovePopUp] = useState<"add" | "remove">(
    "add"
  );
  const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);

  /**
   * Function to load the data
   */
  const getData = async () => {
    const data = await getCollectionRequest(
      "/api/classes",
      ["_id", "name", "teachers"],
      {},
      [{ path: "teachers", select: ["firstName", "lastName"] }]
    );

    return data;
  };

  /**
   * Definition of the Add or remove action
   * @param id of the selected id in the pop up menu
   * @param action "add" or "remove" "selectedData._id" from "id"
   */
  const selectAction = async (id: string, action: "add" | "remove") => {
    // Send the update request if we are on the assign lesson to class
    if (selectedLesson && lessonIdToAssign) {
      // Set the type of action
      setAddOrRemovePopUp(action);

      // And add or remove the data depending on the case
      const response = await updateRecordRequest(
        {
          classId: id,
          lessonId: lessonIdToAssign,
          lesson: selectedLesson,
          action: action,
        },
        "/api/classes/assign-lesson"
      );

      // Check if the response was successful
      if (!response.successful) {
        alert(response.message);
        return false
      };

      // Send the signal to show the pop up message
      // setSuccessPopUpText(lesson.title);
      setShowSuccessPopUp(true);
    }

    // Add to the current selected contact data ids
    if (action === "add") {
      // Add to the ids list
      setSelectedClassDataIds([...new Set([...selectedClassDataIds, id])]);

      // And close the panel
      if (closeOnceClickOnCard) setShowPopUp(false);
    }
    // Or delete from it
    else if (action === "remove") {
      setSelectedClassDataIds(removeItem(selectedClassDataIds, id));
    }
  };

  /**
   * Load class data when init
   */
  useEffect(() => {
    // Get the data
    getData().then((data) => {
      // Set the data
      setClassData(data);

      // Define the current selected ids
      setSelectedClassDataIds(
        selectedClasses.map((elem: ClassListType) => elem._id)
      );
    });
  }, []);

  /**
   * Function to update the selected classes state
   */
  useEffect(() => {
    // Define the output classes based on the selected ids
    const selectedClasses = classData.filter((classElem: ClassListType) =>
      selectedClassDataIds.includes(classElem._id)
    );

    // And update
    setSelectedClasses(selectedClasses);
  }, [selectedClassDataIds]);

  return (
    <div className="w-full max-h-[70%] grow shrink justify-start items-start gap-[5px] inline-flex overflow-y-auto pop-up-scrollbar">
      <div className="w-full h-fit self-stretch flex-col justify-start items-start gap-4 inline-flex">
        {classData.length > 0 ? (
          classData.map((item: ClassListType, index: number) => (
            <AssignClassCard
              key={index}
              id={item._id}
              name={item.name}
              teachers={item.teachers}
              selectedClassDataIds={selectedClassDataIds}
              selectAction={selectAction}
            />
          ))
        ) : (
          <EmptyViewPopUp />
        )}
      </div>
    </div>
  );
};

export default ClassesCardsList;
