import { Transition } from "@headlessui/react";
import { Console } from "console-feed";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { MdRefresh } from "react-icons/md";

interface ConsolePaneType {
  consolePanelRef: React.RefObject<HTMLDivElement>;
  normalisedLeftWidth: number;
  normalisedConsoleHeight: number;
  consoleBottomRef: React.RefObject<HTMLDivElement>;
  consoleNotification: boolean;
  logs: any[];
  isConsoleOpen: boolean;
  consoleEditorSizes: number[];
  consoleLabelTransition: string;
  inputConsoleBool: boolean;
  setLogs: React.Dispatch<React.SetStateAction<any[]>>;
  setInputConsoleBool: React.Dispatch<React.SetStateAction<boolean>>;
  setConsoleNotification: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConsolePane: React.FC<ConsolePaneType> = ({
  normalisedLeftWidth,
  normalisedConsoleHeight,
  consoleNotification,
  consoleBottomRef,
  consolePanelRef,
  logs,
  isConsoleOpen,
  consoleEditorSizes,
  consoleLabelTransition,
  inputConsoleBool,
  setLogs,
  setInputConsoleBool,
  setConsoleNotification,
}) => {
  // States definition
  const [inputConsoleText, setInputConsoleText] = useState<string>("");
  const [inputPrompt, setInputPrompt] = useState<string>("");

  /**
   * Control the behaviour of the console input when required
   */
  useEffect(() => {
    if (inputConsoleBool) {
      // If it's closed, show the notification bubble
      if (normalisedConsoleHeight === 0) {
        setConsoleNotification(true);
      }
      // Otherwise just focus the input text
      else {
        // Focus when starting
        document.getElementById("console-input-text")?.focus();
      }
    } else {
      // Reset when finished
      setInputConsoleText("");
    }
  }, [inputConsoleBool]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === "SELECT_INPUT") {
        setInputConsoleBool(true);
        setInputPrompt(event.data.prompt);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  // This value defines the limit of left-right paning where the opacity should be 0
  const opacityLimit = 0.3;

  const inputPressEnter = (event: { key: string }) => {
    if (event.key === "Enter") {
      // Un-render the input text
      setInputConsoleBool(false);
      // Get the iframe and send a message
      const iframe = document.getElementById("output-iframe");
      // @ts-ignore
      iframe.contentWindow.postMessage(inputConsoleText, "*");
      // And send to the console
      setLogs((logs) => [
        ...logs,
        { method: "log", data: [`${inputPrompt}${inputConsoleText}`] },
      ]);
    }
  };

  return (
    <div
      className="flex flex-row w-full h-full rounded-xl"
      ref={consolePanelRef}
    >
      <div className="flex-none w-12 h-full mr-auto z-20">
        <div className="w-fit h-fit translate-x-[-2.4rem] select-none">
          <motion.div
            className="whitespace-nowrap inline-flex"
            style={{
              originX: 1,
              originY: 0,
              opacity: !isConsoleOpen
                ? 1
                : -0.5 *
                    Math.min(normalisedLeftWidth, normalisedConsoleHeight) +
                  1,
              rotate: !isConsoleOpen
                ? -90 * (1 - normalisedLeftWidth)
                : Math.round(
                    // The rotation effect of paning down, compensated
                    // with the left width pane movement. i.e. if the normalissedLeftWidth
                    // is 0 (or closed), we don't want to see the effect of the top-down
                    // movement)
                    -90 * normalisedConsoleHeight * normalisedLeftWidth +
                      // The rotation effect of shrinking the pane when moving left
                      -90 * (1 - normalisedLeftWidth)
                  ),
              translateX: !isConsoleOpen
                ? 100 * normalisedLeftWidth + (1 - normalisedLeftWidth) * 31
                : Math.round(
                    // The effect of shrinking the pane when moving left
                    (1 - normalisedLeftWidth) * 31 +
                      // The corrected (because is faded by the left-rigth effect)
                      // effect of shrinking the pane when moving down
                      (1 - normalisedConsoleHeight) * 100 * normalisedLeftWidth
                  ),
              translateY: !isConsoleOpen
                ? ((consoleEditorSizes[1] - 43) / 2) * normalisedLeftWidth +
                  (1 - normalisedLeftWidth) * 16
                : Math.round(
                    // The effect of shrinking the pane when moving left
                    (1 - normalisedLeftWidth) * 12 +
                      // The corrected (because is faded by the left-rigth effect)
                      // effect of shrinking the pane when moving down
                      (1 - normalisedConsoleHeight) * 9 * normalisedLeftWidth +
                      // A correcting value just to align the text better
                      3
                  ),
              transition: consoleLabelTransition,
            }}
          >
            Console
            <Transition
              show={consoleNotification && normalisedLeftWidth === 1}
              enter="transition-opacity duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <motion.div
                className="w-4 h-4 px-2 bg-teal-500 rounded-full"
                animate={{ scale: [1, 1.5, 1, 1.5, 1] }}
                transition={{
                  duration: 1,
                  ease: "easeInOut",
                  times: [0, 0.2, 0.4, 0.6, 1],
                }}
              />
            </Transition>
          </motion.div>
        </div>
      </div>
      <div className="flex-1 flex flex-col h-full pr-4 py-4 bg-Canvas rounded-xl overflow-hidden relative z-10">
        <Transition
          as="div"
          show={logs.length > 0}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="absolute flex justify-end w-fit right-0 px-4 z-20 overflow-visible"
        >
          <motion.div
            className="px-2 overflow-hidden"
            animate={{ scale: [1, 1.2, 1, 1.2, 1] }}
            transition={{
              duration: 1,
              ease: "easeInOut",
              times: [0, 0.2, 0.4, 0.6, 1],
            }}
          >
            <Transition
              show={isConsoleOpen}
              enter="transition-transform duration-[700ms]"
              enterFrom="translate-y-[-120px]"
              enterTo="translate-y-0"
              leave="transition-opacity duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <button
                className="flex justify-center items-center w-11 h-11 bg-stone-500 rounded-full cursor-pointer"
                title="Clear console"
                onClick={() => setLogs([])}
                style={{
                  opacity: Math.max(
                    Math.min(
                      normalisedConsoleHeight,
                      1 + (1 / (1 - opacityLimit)) * (normalisedLeftWidth - 1)
                    ),
                    0
                  ),
                }}
              >
                <MdRefresh className="w-6 h-6 fill-neutral-50"/>
              </button>
            </Transition>
          </motion.div>
        </Transition>
        <Transition
          as="div"
          show={isConsoleOpen}
          enter="transition duration-[700ms]"
          enterFrom="translate-y-[120px] opacity-0"
          enterTo="translate-y-0 opacity-100"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="w-full h-full custom-scroll-Canvas" //text-nowrap break-keep whitespace-nowrap
        >
          <motion.div
            style={{
              opacity: Math.max(
                Math.min(
                  normalisedConsoleHeight,
                  1 + (1 / (1 - opacityLimit)) * (normalisedLeftWidth - 1)
                ),
                0
              ),
            }}
          >
            <Transition
              as="div"
              show={logs.length > 0}
              enter="transition-opacity duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-1000"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              style={{}}
            >
              <Console
                logs={logs}
                logGrouping={false}
                styles={{
                  BASE_FONT_FAMILY: "Source Code Pro, monospace",
                  BASE_LINE_HEIGHT: 16,
                  BASE_FONT_SIZE: 16,
                  TABLE_BORDER_COLOR: "rgba(0, 0, 0, 0)",
                }}
              />
            </Transition>
            <div id="consoleBottom" ref={consoleBottomRef}>
              {inputConsoleBool && (
                <div className="w-full h-6 px-8 py-0.5 flex">
                  <div className="w-fit flex items-center font-mono break-keep whitespace-nowrap">
                    {inputPrompt.replace(" ", "\u00A0")}
                  </div>
                  <input
                    type="text"
                    id="console-input-text"
                    className="w-full pr-1 focus:outline-none font-mono bg-transparent text-PureBlue caret-PureBlue"
                    onChange={(event) =>
                      setInputConsoleText(event.target.value)
                    }
                    onKeyDown={inputPressEnter}
                  />
                </div>
              )}
            </div>
          </motion.div>
        </Transition>
        {/* <input className="fixed w-100 h-10 bg-red-500" id="input-field"></input> */}
      </div>
    </div>
  );
};

export default ConsolePane;
