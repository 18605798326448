import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { createRecordRequest, updateRecordRequest } from "services/apiRequests";
import { allowOnlyNumbers } from "utils/allowOnlyNumbers";
import { ContactListType } from "utils/interfaces";

export interface CreateNewContactProps {
  ref?: any;
  role: "student" | "parent" | "class admin" | "customer admin";
  contactToEdit?: ContactListType | null;
  selectedContacts: ContactListType[];
  confirmButtonText: string;
  showPrimaryOption?: boolean;
  setSelectedContacts: React.Dispatch<React.SetStateAction<ContactListType[]>>;
  setLastSelectedContact?: React.Dispatch<
    React.SetStateAction<ContactListType>
  >;
  afterCreateFunction: () => void;
}

const CreateNewContact: React.FC<CreateNewContactProps> = forwardRef(
  (
    {
      role,
      contactToEdit,
      selectedContacts,
      confirmButtonText,
      showPrimaryOption = true,
      setSelectedContacts,
      setLastSelectedContact,
      afterCreateFunction,
    },
    ref
  ) => {
    // States definition
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [addAsPrimaryContact, setAddAsPrimaryContact] = useState(
      selectedContacts.length === 0
    );
    const [activateCreateButton, setActivateCreateButton] = useState(false);

    /**
     * Functions to externalise using refs
     */
    useImperativeHandle(ref, () => ({
      // Function to present all the gathered data in the form
      provideData() {
        return provideData();
      },
    }));

    /**
     * If there is selected data, then prefill the defined fields
     */
    useEffect(() => {
      if (contactToEdit) {
        setFirstName(contactToEdit.firstName);
        setMiddleName(contactToEdit.middleName ? contactToEdit.middleName : "");
        setLastName(contactToEdit.lastName);
        setEmail(contactToEdit.email);
        setPhoneNumber(contactToEdit.phone);
      }
    }, []);

    /**
     * Function that returns all the form data
     * @returns data
     */
    const provideData = () => {
      // Return all the gathered data in the form
      return {
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        email: email,
        phone: phoneNumber,
        addAsPrimaryContact: addAsPrimaryContact,
      };
    };

    const handleNumberChange = (text: string) => {
      // Prevent from inputing letters and symbols but "+"
      setPhoneNumber(allowOnlyNumbers(text));
    };

    /**
     * Function to handle the "Add as primary contact" checkbox
     */
    const handleAddAsPrimaryContact = () => {
      setAddAsPrimaryContact(!addAsPrimaryContact);
    };

    /**
     * Function to handle creating a contact when the button is pressed
     * @returns
     */
    const handleCreateContact = async () => {
      // Check if the button is already available
      if (!activateCreateButton) return;

      // Get all the data from the form
      const { addAsPrimaryContact, ...data } = provideData();

      // Add role as parent
      const dataToSend = { ...data, role: role };

      let response = { successful: false, _id: "", message: "" };
      // Request for editing a contact
      if (contactToEdit) {
        response = await updateRecordRequest(
          { _id: contactToEdit._id, toUpdate: dataToSend },
          "/api/users"
        );
      }
      // Request for creating new contact
      else {
        response = await createRecordRequest(dataToSend, "/api/users");
      }

      // Check if the response is successful
      if (!response.successful) {
        alert(response.message);
        return;
      }

      // And add as a new contact (execute just if we are creating)
      if (!contactToEdit) {
        const dataOut = {
          _id: response._id,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phone: data.phone,
        };

        // Put first if it's added as first contact
        if (addAsPrimaryContact && showPrimaryOption) {
          setSelectedContacts([dataOut].concat(selectedContacts));
        } else {
          setSelectedContacts(selectedContacts.concat([dataOut]));
        }

        // Add information for the success message
        if (setLastSelectedContact) setLastSelectedContact(dataOut);
      }

      // Execute the defined actions after the creation
      afterCreateFunction();
    };

    /**
     * Function to activate or deactivate create contact button
     */
    useEffect(() => {
      // Check if they have content
      const condition = !!(firstName && lastName && email && phoneNumber);
      setActivateCreateButton(condition);
    }, [firstName, lastName, email, phoneNumber]);

    return (
      <>
        <div className="w-full h-full overflow-y-auto pop-up-scrollbar flex-col justify-start items-start gap-8 inline-flex">
          <div className="self-stretch h-[280px] flex-col justify-start items-start gap-4 flex">
            <div className="self-stretch text-neutral-500 text-base font-semibold font-sans leading-[19px]">
              Basic details
            </div>
            <div className="self-stretch h-fit flex-col justify-start items-start gap-1.5 flex">
              <div className="rounded justify-start items-start gap-2 inline-flex text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
                First Name*
              </div>
              <div className="self-stretch bg-neutral-50 rounded-lg justify-start items-center gap-2 inline-flex">
                <input
                  type="text"
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                  className="w-full h-full p-3 text-neutral-700 placeholder:text-neutral-300 text-base font-sans leading-[19px] bg-transparent border-none focus:outline-none"
                  placeholder="Enter first name"
                />
              </div>
            </div>
            <div className="self-stretch h-fit flex-col justify-start items-start gap-1.5 flex">
              <div className="rounded justify-start items-start gap-2 inline-flex text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
                Middle Name (Optional)
              </div>
              <div className="self-stretch bg-neutral-50 rounded-lg justify-start items-center gap-2 inline-flex">
                <input
                  type="text"
                  value={middleName}
                  onChange={(event) => setMiddleName(event.target.value)}
                  className="w-full h-full p-3 text-neutral-700 placeholder:text-neutral-300 text-base font-sans leading-[19px] bg-transparent border-none focus:outline-none"
                  placeholder="Enter middle name"
                />
              </div>
            </div>
            <div className="self-stretch h-fit flex-col justify-start items-start gap-1.5 flex">
              <div className="rounded justify-start items-start gap-2 inline-flex text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
                Last Name*
              </div>
              <div className="self-stretch bg-neutral-50 rounded-lg justify-start items-center gap-2 inline-flex">
                <input
                  type="text"
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                  className="w-full h-full p-3 text-neutral-700 placeholder:text-neutral-300 text-base font-sans leading-[19px] bg-transparent border-none focus:outline-none"
                  placeholder="Enter last name"
                />
              </div>
            </div>
          </div>
          <div className="self-stretch h-[236px] flex-col justify-start items-start gap-4 flex">
            <div className="self-stretch text-neutral-500 text-base font-semibold font-sans leading-[19px]">
              Contact information
            </div>

            <div className="self-stretch h-fit flex-col justify-start items-start gap-1.5 flex">
              <div className="rounded justify-start items-start gap-2 inline-flex text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
                Email*
              </div>
              <div className="self-stretch bg-neutral-50 rounded-lg justify-start items-center gap-2 inline-flex">
                <input
                  type="text"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  className="w-full h-full p-3 text-neutral-700 placeholder:text-neutral-300 text-base font-sans leading-[19px] bg-transparent border-none focus:outline-none"
                  placeholder="Enter email"
                />
              </div>
            </div>

            <div className="self-stretch h-fit flex-col justify-start items-start gap-1.5 flex">
              <div className="rounded justify-start items-start gap-2 inline-flex text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
                Phone number - Mobile*
              </div>
              <div className="self-stretch bg-neutral-50 rounded-lg justify-start items-center gap-2 inline-flex">
                <input
                  type="text"
                  value={phoneNumber}
                  onChange={(event) => handleNumberChange(event.target.value)}
                  className="w-full h-full p-3 text-neutral-700 placeholder:text-neutral-300 text-base font-sans leading-[19px] bg-transparent border-none focus:outline-none"
                  placeholder="Enter phone number"
                />
              </div>
            </div>
            {showPrimaryOption && (
              <div
                className="w-fit h-[27px] flex-col justify-start items-start gap-3 flex cursor-pointer"
                onClick={handleAddAsPrimaryContact}
              >
                <div className="self-stretch rounded-lg justify-start items-center gap-3 inline-flex">
                  <div className="w-6 h-6 relative">
                    {addAsPrimaryContact ? (
                      <MdCheckBox className="w-full h-full fill-neutral-300" />
                    ) : (
                      <MdCheckBoxOutlineBlank className="w-full h-full fill-neutral-300" />
                    )}
                  </div>
                  <div className="grow shrink basis-0 h-[27px] py-1 justify-start items-start gap-2 flex text-zinc-700 text-base font-normal font-sans leading-[19px] select-none">
                    Add as primary contact
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className={
            activateCreateButton
              ? "w-full h-[46px] px-5 py-3 bg-dc-secondary-600 rounded justify-center items-center gap-2 inline-flex cursor-pointer"
              : "w-full h-[46px] px-5 py-3 bg-Subtle rounded justify-center items-center gap-2 inline-flex cursor-pointer"
          }
          onClick={handleCreateContact}
        >
          <div
            className={
              activateCreateButton
                ? "text-white text-lg font-semibold font-sans leading-snug select-none"
                : "text-neutral-400 text-lg font-semibold font-sans leading-snug select-none"
            }
          >
            {confirmButtonText}
          </div>
        </div>
      </>
    );
  }
);

export default CreateNewContact;
