export const isObjectEmpty = (objectName: object) => {
  return (
    objectName &&
    Object.keys(objectName).length === 0 &&
    objectName.constructor === Object
  );
};

export const clockify = (time: number) => {
  if (time <= 0) {
    return '00:00';
  }
  
  // Define the minutes and seconds
  let minutes = Math.floor(time / 60);
  let seconds = time % 60;
  
  // Add '0' when minutes and seconds are less than 10
  // let zeroSec = seconds < 10 ? '0': '';
  // let zeroMin = minutes < 10 ? '0': '';
  let minutesStr = minutes.toString().padStart(2, '0');
  let secondsStr = seconds.toString().padStart(2, '0');
  
  return `${minutesStr}:${secondsStr}`;
}
