import { useState, useEffect } from "react";
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  BarChart,
} from "recharts";
import ChartTooltip from "./ChartTooltip";

interface MyProgressProps {
  activity: {
    language: string;
    userPerformance: number;
    classAverage: number;
  }[];
}

const MyProgress: React.FC<MyProgressProps> = ({ activity }) => {
  // States definition
  const [chartData, setChartData] = useState<
    { language: string; user: number; class: number }[]
  >([]);

  /**
   * Transform the activity data names for the chart
   */
  useEffect(() => {
    const updateChartData = () => {
      const updatedData = activity.map((item) => ({
        language: item.language,
        user: item.userPerformance,
        class: item.classAverage,
      }));

      setChartData(updatedData);
    };

    updateChartData();
  }, [activity]);

  return (
    <div className="w-1/3 h-[555px] p-6 bg-neutral-25 rounded-3xl flex flex-col justify-start items-start gap-8 overflow-hidden relative">
      <div className="self-stretch justify-between items-center inline-flex">
        <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
          <div className="self-stretch text-neutral-700 text-lg font-normal font-sans leading-snug">
            My Progress
          </div>
        </div>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={chartData}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
          barGap={2}
        >
          <XAxis
            dataKey="language"
            axisLine={false}
            tickLine={false}
            fontSize={10}
          />
          <YAxis width={25} axisLine={false} tickLine={false} fontSize={12} />
          <Tooltip content={<ChartTooltip userName="Kyah"/>}/>
          <Bar
            dataKey="user"
            fill="#16a7b6"
            radius={5}
          />
          <Bar
            dataKey="class"
            fill="#596876"
            radius={5}
          />
        </BarChart>
      </ResponsiveContainer>
      {/* <Example /> */}
      <div className="flex-col justify-start items-start gap-2 flex">
        <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
          Legend
        </div>
        <div className="justify-start items-center gap-2 inline-flex">
          <div className="w-6 h-4 relative bg-dc-accent-500 rounded" />
          <div className="text-neutral-500 text-[10px] font-normal font-sans leading-3 tracking-tight">
            Your Progress
          </div>
        </div>
        <div className="justify-start items-center gap-2 inline-flex">
          <div className="w-6 h-4 relative bg-neutral-500 rounded" />
          <div className="text-neutral-500 text-[10px] font-normal font-sans leading-3 tracking-tight">
            Class Average
          </div>
        </div>
        <div className="text-neutral-500 text-[10px] font-normal font-sans leading-3 tracking-tight">
          Number of Projects completed
        </div>
      </div>
    </div>
  );
};

export default MyProgress;
