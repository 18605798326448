import "index.css";
import reportWebVitals from "reportWebVitals";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Editor from "routes/editor/Editor";
import SuccessRegister from "routes/success-register/SuccessRegister";
import SuccessResetPassword from "routes/success-reset-password/SuccessResetPassword";
import SuccessSetupPassword from "routes/success-setup-password/SuccessSetupPassword";
import Login from "routes/login/Login";
import ResetPassword from "routes/reset-password/ResetPassword";
import SetupPassword from "routes/setup-password/SetupPassword";
import TwoFactorAuthentication from "routes/two-factor-authentication/TwoFactorAuthentication";
import URLErrorMessage from "routes/url-error/URLErrorMessage";
import SuperAdmin from "routes/super-admin/SuperAdmin";
import Quiz from "routes/quiz/Quiz";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/home",
    element: <SuperAdmin />
  },
  {
    path: "/editor/:moduleOrProject/:id",
    element: <Editor />,
  },
  {
    path: "/quiz/course/:quizId",
    element: <Quiz />,
  },
  {
    path: "reset-password",
    element: <ResetPassword />,
  },
  {
    path: "setup-password",
    children: [
      {
        path: ":resetTokenId",
        element: <SetupPassword />,
      },
    ],
  },
  {
    path: "success-register",
    element: <SuccessRegister />,
  },
  {
    path: "success-reset-password",
    element: <SuccessResetPassword />,
  },
  {
    path: "success-setup-password",
    element: <SuccessSetupPassword />,
  },
  {
    path: "two-factor-authentication",
    element: <TwoFactorAuthentication />,
    children: [{ path: ":twoFactorId", element: <TwoFactorAuthentication /> }],
  },
  {
    path: "url-error",
    element: <URLErrorMessage text="page" />,
  },
]);

createRoot(document.getElementById("root") as HTMLElement).render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
