import InputHeaderImage from "components/super-admin/utils/placeholders/InputHeaderImage";
import React, { useEffect, useState, ChangeEvent } from "react";
import {
  MdOutlineBackup,
  MdArrowDropDown,
  MdRemove,
  MdBolt,
  MdAdd,
  MdOutlineArrowCircleRight,
} from "react-icons/md";
import PopUpPanel from "../pop-up-panels/PopUpPanel";

interface BasicDetailsProps {
  selectedLesson: string;
  name: string;
  level: string;
  expPoints: number;
  headerImage: string | null;
  setName: React.Dispatch<React.SetStateAction<string>>;
  setLevel: React.Dispatch<React.SetStateAction<string>>;
  setExpPoints: React.Dispatch<React.SetStateAction<number>>;
  setStage: React.Dispatch<React.SetStateAction<number>>;
  setHeaderImage: React.Dispatch<React.SetStateAction<string | null>>;
}

const BasicDetails: React.FC<BasicDetailsProps> = ({
  selectedLesson,
  name,
  level,
  expPoints,
  headerImage,
  setName,
  setLevel,
  setExpPoints,
  setStage,
  setHeaderImage,
}) => {
  // States definition
  const [nextAvailable, setNextAvailable] = useState(false);
  const [showLevelPopUp, setShowLevelPopUp] = useState(false);

  /**
   * Condition to activate the next button
   */
  useEffect(() => {
    let condition: boolean;

    if (selectedLesson === "programs") {
      condition = !!name;
    } else {
      condition = !!(name && level);
    }

    setNextAvailable(condition);
  }, [name, level]);

  /**
   * Go to the second stage
   */
  const handleNext = () => {
    if (nextAvailable) setStage(2);
  };

  return (
    <div className="w-[562px] flex flex-col gap-12 p-6">
      <div className="flex items-start justify-start w-full text-neutral-600 text-[23px] font-bold font-sans leading-7">
        Basic Details
      </div>
      <div className="flex flex-col items-center justify-center gap-8">
        {/* Image */}
        {["programs", "courses", "projects"].includes(selectedLesson) && (
          <InputHeaderImage
            selectedLesson={selectedLesson}
            headerImage={headerImage}
            setHeaderImage={setHeaderImage}
          />
        )}
        {/* Name */}
        <div className="w-full h-fit flex flex-col gap-[6px]">
          <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
            {selectedLesson.slice(0, -1)} Name*
          </div>
          <div className="w-full h-[51px] bg-neutral-50 rounded-lg justify-start items-center gap-2 inline-flex">
            <input
              type="text"
              value={name}
              onChange={(event) => setName(event.target.value)}
              className={
                name
                  ? "w-full h-full p-3 text-neutral-700 text-base font-normal font-sans leading-[19px] bg-transparent border-none focus:outline-none"
                  : "w-full h-full p-3 text-neutral-300 text-base font-normal font-sans leading-[19px] bg-transparent border-none focus:outline-none"
              }
              placeholder={`Enter ${selectedLesson.slice(0, -1)} name`}
            />
          </div>
        </div>
        {/* Level: for everything but "programs" */}
        {["courses", "projects", "modules"].includes(selectedLesson) && (
          <div className="w-full h-fit flex-col justify-start items-start gap-1.5 inline-flex">
            <div className="rounded justify-start items-start gap-2 inline-flex">
              <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
                {selectedLesson.slice(0, -1)} Level*
              </div>
            </div>
            <div
              className="self-stretch p-3 bg-neutral-50 rounded-lg justify-start items-center gap-2 inline-flex cursor-pointer"
              onClick={() => setShowLevelPopUp(true)}
            >
              <div className="grow shrink basis-0 h-[27px] py-1 justify-start items-start gap-2 flex">
                <div
                  className={
                    level
                      ? "grow shrink basis-0 h-[19px] text-neutral-700 text-base font-normal font-sans leading-[19px]"
                      : "grow shrink basis-0 h-[19px] text-neutral-300 text-base font-normal font-sans leading-[19px]"
                  }
                >
                  {level
                    ? level
                    : `Pick a level for this ${selectedLesson.slice(0, -1)}`}
                </div>
              </div>
              <div className="w-5 h-5 relative">
                <MdArrowDropDown className="w-full h-full fill-neutral-600" />
              </div>
            </div>
          </div>
        )}
        {/* Exp points: for everything but "programs" */}
        {["courses", "projects", "modules"].includes(selectedLesson) && (
          <div className="w-full h-fit flex-col justify-start items-start gap-1.5 inline-flex">
            <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
              Knowledge points*
            </div>
            <div className="justify-center items-center gap-5 inline-flex">
              {["projects", "modules"].includes(selectedLesson) && (
                <div
                  className="p-2 bg-neutral-50 rounded-full justify-start items-end gap-2 flex cursor-pointer"
                  onClick={() => setExpPoints(Math.max(0, expPoints - 10))}
                >
                  <div className="w-4 h-4 relative">
                    <MdRemove className="w-full h-full fill-neutral-500" />
                  </div>
                </div>
              )}
              <div className="w-full self-stretch py-1 justify-center items-center gap-2 flex">
                <div className="h-6 justify-center items-center gap-2 flex">
                  <div className="w-6 h-6 relative">
                    <MdBolt className="w-full h-full fill-neutral-500" />
                  </div>
                </div>
                {["projects", "modules"].includes(selectedLesson) ? (
                  <input
                    className="w-10 text-neutral-700 text-lg font-semibold font-sans leading-snug"
                    type="text"
                    value={expPoints}
                    onChange={(event) =>
                      setExpPoints(parseInt(event.target.value) || 0)
                    }
                  />
                ) : (
                  <div className="w-10 text-neutral-700 text-lg font-semibold font-sans leading-snug">
                    {expPoints}
                  </div>
                )}
              </div>
              {["projects", "modules"].includes(selectedLesson) && (
                <div
                  className="p-2 bg-neutral-50 rounded-full justify-start items-end gap-2 flex cursor-pointer"
                  onClick={() => setExpPoints(expPoints + 10)}
                >
                  <div className="w-4 h-4 relative">
                    <MdAdd className="w-full h-full fill-neutral-500" />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="w-full self-stretch justify-between items-center inline-flex">
        <div className="opacity-0 rounded justify-center items-center gap-2 flex" />
        <div
          className="rounded justify-center items-center gap-2 flex cursor-pointer"
          onClick={handleNext}
        >
          <div
            className={
              nextAvailable
                ? "text-dc-secondary-600 text-lg font-semibold font-sans leading-snug"
                : "text-stone-300 text-lg font-semibold font-sans leading-snug"
            }
          >
            Next
          </div>
          <div className="w-6 h-6 relative">
            <MdOutlineArrowCircleRight
              className={
                nextAvailable
                  ? "w-full h-full fill-dc-secondary-600"
                  : "w-full h-full fill-stone-300"
              }
            />
          </div>
        </div>
      </div>
      <PopUpPanel
        type="level"
        selectedLesson={selectedLesson}
        showPopUp={showLevelPopUp}
        setShowPopUp={setShowLevelPopUp}
        setOption={setLevel}
      />
    </div>
  );
};

export default BasicDetails;
