import CreateNewPopUp from "components/super-admin/lessons/utils/CreateNewPopUp";
import lessonInformation from "components/super-admin/lessons/utils/lessonInformation";
import {
  forwardRef,
  ReactElement,
  useImperativeHandle,
  useState,
} from "react";
import {
  MdOutlineSave,
  MdOutlineBookmarkAdd,
  MdOutlineBallot,
  MdOutlineAutoStories,
  MdOutlineCollectionsBookmark,
  MdOutlineFeaturedPlayList,
  MdAdd,
  MdOutlineExpandMore,
} from "react-icons/md";
import capitaliseFirstChar from "utils/capitaliseFirstChar";

interface LessonsHeaderOptionsProps {
  ref: any;
  userRole: string;
  setShowSideBarMenu: React.Dispatch<React.SetStateAction<boolean>>;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setDescription: React.Dispatch<React.SetStateAction<ReactElement | string>>;
  setShowBackButton: React.Dispatch<React.SetStateAction<boolean>>;
  lessonsRef: React.MutableRefObject<any>;
}

const LessonsHeaderOptions: React.FC<LessonsHeaderOptionsProps> = forwardRef(
  (
    {
      userRole,
      setShowSideBarMenu,
      setTitle,
      setDescription,
      setShowBackButton,
      lessonsRef,
    },
    ref
  ) => {
    // States definition
    const [showCreateNewPopUp, setShowCreateNewPopUp] = useState(false);
    const [showCreateNew, setShowCreateNew] = useState(true);
    const [showAddButton, setShowAddButton] = useState(false);
    const [addToButtonText, setAddToButtonText] = useState("");
    const [manageButtonText, setManageButtonText] = useState("");
    const [showAddManageButtons, setShowAddManageButtons] = useState(false);
    const [saveButton, setSaveButton] = useState<{
      show: boolean;
      disabled: boolean;
    }>({ show: false, disabled: true });

    /**
     * Define the list of functions that can be used from external components
     * using a reference
     */
    useImperativeHandle(ref, () => ({
      setShowCreateNewButton(bool: boolean) {
        setShowCreateNew(bool);
      },
      setShowAddButton(bool: boolean) {
        setShowAddButton(bool);
      },
      setShowAddManageButtons(bool: boolean) {
        setShowAddManageButtons(bool);
      },
      setSaveButton(options: { show: boolean; disabled: boolean }) {
        setSaveButton(options);
      },
      pressCreateNewProgram() {
        onCreateFunction("programs");
      },
      pressCreateNewCourse() {
        onCreateFunction("courses");
      },
      pressCreateNewProject() {
        onCreateFunction("projects");
      },
      pressCreateNewModule() {
        onCreateFunction("modules");
      },
      handleComeBackHeader(activeLesson: string) {
        handleComeBackHeader(activeLesson);
      },
      setOptionButtons(selectedLesson: string, selectedTab: number){
        setOptionButtons(selectedLesson, selectedTab);
      },
    }));

    /**
     * OnCreate function for the different lessons
     */
    const onCreateFunction = (
      lesson: "programs" | "courses" | "projects" | "modules"
    ) => {
      /**
       * General values
       */
      // Hide the Add button
      setShowAddButton(false);
      // Hide the Add/manage button
      setShowAddManageButtons(false);
      // Hide the create new button
      setShowCreateNew(false);
      // And the create new pop up menu
      setShowCreateNewPopUp(false);
      // Hide the lateral bar menu
      setShowSideBarMenu(false);
      // Reveal the back button
      setShowBackButton(true);
      // Reveal the save button, but disabled at first
      setSaveButton({ show: true, disabled: true });

      /**
       * Regarding lessons
       */
      // Set the navRegister to come back to the previous view
      lessonsRef.current.list().addCurrentToNavRegister();
      // Hide the tab search bar (programs, courses, ...)
      lessonsRef.current.setShowTabSearchBar(false);
      // Select the "settings" tab (second tab)
      lessonsRef.current.setSelectSettingTabs(1);
      // Set the state of createNew (which controls what
      // lessonListToShow)
      lessonsRef.current.setFromCreateNew(true);
      // Force the selected data to undefine the selectedData
      lessonsRef.current.setSelectedData(null);

      /**
       * Specific parameters (which depends of the lesson)
       */
      // Define the title and description text
      const textToDisplay = capitaliseFirstChar(lesson.slice(0, -1));
      // Define the icon to display next to the subtitle
      const displayIcon =
        lesson === "programs" ? (
          <MdOutlineFeaturedPlayList />
        ) : lesson === "courses" ? (
          <MdOutlineCollectionsBookmark />
        ) : lesson === "projects" ? (
          <MdOutlineAutoStories />
        ) : lesson === "modules" ? (
          <MdOutlineBallot />
        ) : (
          ""
        );
      
      // Specific parameters for header
      setTitle(`New ${textToDisplay}`);
      setDescription(
        <div className="w-fit flex gap-1">
          {displayIcon}
          <div>{textToDisplay}</div>
        </div>
      );
      
      /**
       * And regarding lessons
       */ 
      // Set the active lesson based on the lesson selected
      lessonsRef.current.setActiveLesson(lessonInformation(lesson).child);
      // And show the content-settings tab for everything but modules
      lessonsRef.current.setShowSettingTabs(lesson !== "modules");
    };

    /**
     * Function used when press save button
     */
    const handleSave = () => {
      // If the save button is available, then make it work
      if (!saveButton.disabled) lessonsRef.current.pressSaveButton();
    };

    /**
     * Auxiliar function to set the header when coming back to
     * the main view
     * @param activeLesson 
     */
    const handleComeBackHeader = (activeLesson: string) => {
      // And turn off the other buttons
      setSaveButton({ show: false, disabled: true });
      // Activating the create new button
      setShowCreateNew(true);
      // Remove add course
      setShowAddButton(false);
      // Remove add project and manage modules
      setShowAddManageButtons(false);
      // Set the header title
      setTitle(capitaliseFirstChar(activeLesson));
      // Set the description based on the userRole
      if (userRole === "student") setDescription("Lessons");
      else setDescription(`List of current ${activeLesson}`);
    }
    
    /**
     * Function used to define what buttons should be displayed based on
     * the current lesson to show
     */
    const setOptionButtons = (selectedLesson: string, selectedTab: number) => {
      // First of all, check that the user is a master admin
      if (userRole !== "master admin") return;

      // Get the lesson information
      const lessonInfo = lessonInformation(selectedLesson);

      // Define the text for the addButton/addManageButton text
      setAddToButtonText(lessonInfo.parent);
      // Define the text for the manage button text
      setManageButtonText(lessonInfo.child);

      // Select the buttons based on the lessonListToShow
      if (selectedLesson === "programs") {
        // Activate the add course button
        setShowAddButton(selectedTab === 0);
        // And make sure the rest of the buttons are off
        setShowAddManageButtons(false);
      } else if (selectedLesson === "courses") {
        // Activate the add and manage pair of buttons
        setShowAddManageButtons(selectedTab === 0);
        // And make sure the rest of the buttons are off
        setShowAddButton(false);
      } else if (selectedLesson === "projects") {
        // Activate the add and manage pair of buttons
        setShowAddManageButtons(selectedTab === 0);
        // And make sure the rest of the buttons are off
        setShowAddButton(false);
      } else if (selectedLesson === "modules") {
        // And set the settings tab in this case
        lessonsRef.current.setSelectSettingTabs(1);
        // And make sure the rest of the buttons are off
        setShowAddButton(false);
        setShowAddManageButtons(false);
      }
    };

    return (
      <>
        {userRole === "master admin" && showCreateNew && (
          <div className="p-3 rounded-full flex-col justify-center items-center gap-2 inline-flex relative">
            <div
              className="justify-center items-center gap-0.5 inline-flex cursor-pointer"
              onClick={() => {
                if (!showCreateNewPopUp)
                  setShowCreateNewPopUp(!showCreateNewPopUp);
              }}
            >
              <div className="p-3 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex">
                <div className="w-6 h-6 relative">
                  <MdAdd className="w-full h-full fill-dc-secondary-600" />
                </div>
              </div>
              <div className="w-4 h-4 relative">
                <MdOutlineExpandMore className="w-4 h-4 text-dc-secondary-600" />
              </div>
            </div>
            <div className="justify-center items-center gap-0.5 inline-flex">
              <div className="text-center text-dc-secondary-600 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide whitespace-nowrap select-none">
                Create New
              </div>
              <div className="w-4 h-4 px-[4.28px] opacity-0 justify-center items-center flex" />
            </div>
            {showCreateNewPopUp && (
              <CreateNewPopUp
                setShowCreateNewPopUp={setShowCreateNewPopUp}
                onCreateFunction={onCreateFunction}
              />
            )}
          </div>
        )}
        {showAddButton && (
          <div className="p-3 rounded-full flex-col justify-center items-center gap-2 inline-flex">
            <div
              className="justify-center items-center gap-0.5 inline-flex cursor-pointer"
              onClick={() => {
                lessonsRef.current.setShowAddPopUp(true);
              }}
            >
              <div className="p-3 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex">
                <div className="w-6 h-6 relative">
                  <MdAdd className="w-full h-full fill-dc-secondary-600" />
                </div>
              </div>
            </div>
            <div className="justify-center items-center gap-0.5 inline-flex">
              <div className="text-center text-dc-secondary-600 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide whitespace-nowrap">
                {/* In the particular case of selecting a program, add course is a type of manage button */}
                Add {manageButtonText.slice(0, -1)}
              </div>
            </div>
          </div>
        )}
        {saveButton.show && (
          <div className="p-3 rounded-full flex-col justify-center items-center gap-2 inline-flex">
            <div
              className={
                saveButton.disabled
                  ? "justify-center items-center gap-0.5 inline-flex"
                  : "justify-center items-center gap-0.5 inline-flex cursor-pointer"
              }
              onClick={handleSave}
            >
              <div className="p-3 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex">
                <div className="w-6 h-6 relative">
                  <MdOutlineSave
                    className={
                      saveButton.disabled
                        ? "w-full h-full fill-neutral-300"
                        : "w-full h-full fill-dc-secondary-600"
                    }
                  />
                </div>
              </div>
            </div>
            <div className="justify-center items-center gap-0.5 inline-flex">
              <div
                className={
                  saveButton.disabled
                    ? "text-center text-neutral-300 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide whitespace-nowrap"
                    : "text-center text-dc-secondary-600 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide whitespace-nowrap"
                }
              >
                Save
              </div>
            </div>
          </div>
        )}
        {showAddManageButtons && (
          <div className="flex">
            <div className="w-[139px] h-[94px] p-3 rounded-full flex-col justify-center items-center gap-2 inline-flex">
              <div className="justify-center items-center gap-0.5 inline-flex">
                <div
                  className="p-3 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex cursor-pointer"
                  onClick={() => lessonsRef.current.setShowAddToLesson(true)}
                >
                  <div className="w-6 h-6 relative">
                    <MdOutlineBookmarkAdd className="w-full h-full fill-dc-secondary-600" />
                  </div>
                </div>
              </div>
              <div className="justify-center items-center gap-0.5 inline-flex">
                <div className="text-center text-dc-secondary-600 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide">
                  Add To {addToButtonText.slice(0, -1)}
                </div>
              </div>
            </div>
            <div className="w-[149px] h-[94px] p-3 rounded-full flex-col justify-center items-center gap-2 inline-flex">
              <div className="justify-center items-center gap-0.5 inline-flex">
                <div
                  className="p-3 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex cursor-pointer"
                  onClick={() => lessonsRef.current.setShowAddPopUp(true)}
                >
                  <div className="w-6 h-6 relative">
                    <MdOutlineBallot className="w-full h-full fill-dc-secondary-600" />
                  </div>
                </div>
              </div>
              <div className="justify-center items-center gap-0.5 inline-flex">
                <div className="text-center text-dc-secondary-600 text-xs font-semibold font-sans uppercase leading-[14px] tracking-wide">
                  Manage {manageButtonText}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);

export default LessonsHeaderOptions;
