import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import CustomerAdminDashboard from "./customer-admin/CustomerAdminDashboard";
import StudentDashboard from "./student-dashboard/StudentDashboard";
import Customers from "../customers/customers";

interface DashboardProps {
  ref?: any;
  headerRef: React.MutableRefObject<any>;
  subHeaderRef: React.MutableRefObject<any>;
  customersRef: React.MutableRefObject<any>;
  userRole: string;
  backPressedToggle: boolean;
}

const Dashboard: React.FC<DashboardProps> = forwardRef(
  (
    { headerRef, subHeaderRef, customersRef, userRole, backPressedToggle },
    ref
  ) => {
    // References definition
    const customerAdminDashboardRef = useRef(null);
    const studentDashboardRef = useRef(null);

    /**
     * Define the list of functions that can be used from external components
     * using a reference
     */
    useImperativeHandle(ref, () => ({
      setCreateNewStudent(bool: boolean) {
        let currentHandler: any;
        if (customerAdminDashboardRef.current)
          currentHandler = customerAdminDashboardRef.current;
        currentHandler.setCreateNewStudent(bool);
      },
      setShowCreateNewClass(bool: boolean) {
        let currentHandler: any;
        if (customerAdminDashboardRef.current)
          currentHandler = customerAdminDashboardRef.current;
        currentHandler.setShowCreateNewClass(bool);
      },
      pressSaveButton() {
        let currentHandler: any;
        if (customerAdminDashboardRef.current)
          currentHandler = customerAdminDashboardRef.current;
        currentHandler.handleSaveNewStudent();
      },
      student() {
        return studentDashboardRef.current;
      },
      pressBackButtonSubHeader() {
        if (customerAdminDashboardRef.current)
          (customerAdminDashboardRef.current as any).onBackClick();
      },
    }));

    /**
     * Ensure to close the back button on starting
     */
    useEffect(() => {
      headerRef.current.setHeaderBackButton(false);
    }, []);

    /**
     * Function to render the content
     */
    const renderContent = () => {
      switch (userRole) {
        case "master admin":
          return (
            <Customers
              ref={customersRef}
              userRole={userRole}
              backPressedToggle={backPressedToggle}
              headerRef={headerRef}
              subHeaderRef={subHeaderRef}
              customerAdminDashboardRef={customerAdminDashboardRef}
            />
          );
        case "customer admin":
          return (
            <CustomerAdminDashboard
              ref={customerAdminDashboardRef}
              userRole={userRole}
              headerRef={headerRef}
              subHeaderRef={subHeaderRef}
              backPressedToggle={backPressedToggle}
            />
          );
        case "student":
          return (
            <StudentDashboard ref={studentDashboardRef} headerRef={headerRef} />
          );
        default:
          return <></>;
      }
    };

    return renderContent();
  }
);

export default Dashboard;
