import React from "react";
import { Project } from "utils/interfaces";
import {
  MdArrowForward,
  MdBolt,
  MdOutlineAddToPhotos,
  MdOutlineEdit,
  MdStackedBarChart,
  MdTerminal,
} from "react-icons/md";

export interface ProjectCardProps {
  userRole: string;
  project: Project;
  handleExpand?: () => void;
  handleEdit?: (event: React.MouseEvent) => void;
  handleAddToClasses: (event: React.MouseEvent) => void;
}

const ProjectCard: React.FC<ProjectCardProps> = ({
  userRole,
  project,
  handleEdit,
  handleExpand,
  handleAddToClasses,
}) => {
  return (
    <div
      className="w-full h-[137px] px-6 py-5 bg-neutral-25 rounded-xl justify-between items-center inline-flex cursor-pointer"
      onClick={handleExpand}
    >
      <div className="w-[270px] h-[97px] justify-start items-center gap-2 flex">
        <img
          className="w-[68px] h-[68px] rounded-full"
          src={project.headerImage || "logo.svg"}
          alt={project.title}
        />
        <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
          <div className="self-stretch text-neutral-700 text-base font-semibold font-sans leading-[19px]">
            {project.title || "-"}
          </div>
          <div className="self-stretch text-neutral-600 text-xs font-normal font-sans leading-[18px]">
            {project.description || "-"}
          </div>
        </div>
      </div>
      <div className="justify-start items-center gap-1 flex">
        <div className="p-1 rounded-full justify-start items-end gap-2 flex">
          <div className="w-8 h-8 relative">
            <MdStackedBarChart className="w-full h-full fill-neutral-700" />
          </div>
        </div>
        <div className="flex-col justify-center items-center gap-1 inline-flex">
          <div className="flex-col justify-center items-center gap-1 flex">
            <div className="self-stretch text-neutral-700 text-base font-semibold font-sans leading-[19px]">
              {project.level || "-"}
            </div>
            <div className="pb-[3px] flex-col justify-center items-end gap-2 flex">
              <div className="justify-center items-end gap-2 inline-flex">
                <div className="text-center text-neutral-500 text-[10px] font-normal font-sans uppercase leading-3 tracking-wide">
                  Program Level
                </div>
              </div>
            </div>
          </div>
          {/* <div className="self-stretch h-0.5 bg-neutral-200 flex-col justify-start items-start gap-2 flex">
            <div className="w-[21px] h-0.5 bg-success-500" />
          </div> */}
        </div>
      </div>
      <div className="justify-start items-center gap-1 flex">
        <div className="p-1 rounded-full justify-start items-end gap-2 flex">
          <div className="w-8 h-8 relative">
            <MdBolt className="w-full h-full fill-neutral-700" />
          </div>
        </div>
        <div className="flex-col justify-center items-center gap-1 inline-flex">
          <div className="flex-col justify-center items-center gap-1 flex">
            <div className="self-stretch text-neutral-700 text-base font-semibold font-sans leading-[19px]">
              {project.experiencePoints || 0}
            </div>
            <div className="pb-[3px] flex-col justify-center items-end gap-2 flex">
              <div className="justify-center items-end gap-2 inline-flex">
                <div className="text-center text-neutral-500 text-[10px] font-normal font-sans uppercase leading-3 tracking-wide">
                  Knowledge points
                </div>
              </div>
            </div>
          </div>
          {/* <div className="self-stretch h-0.5 bg-neutral-200 flex-col justify-start items-start gap-2 flex">
            <div className="w-[21px] h-0.5 bg-success-500" />
          </div> */}
        </div>
      </div>
      <div className="justify-start items-center gap-1 flex">
        <div className="p-1 rounded-full justify-start items-end gap-2 flex">
          <div className="w-8 h-8 relative">
            <MdTerminal className="w-full h-full fill-neutral-700" />
          </div>
        </div>
        <div className="flex-col justify-start items-center gap-1 inline-flex">
          <div className="flex-col justify-center items-center gap-1 flex">
            <div className="self-stretch text-neutral-700 text-base font-semibold font-sans leading-[19px]">
              {project.estimatedTime || 0}
            </div>
            <div className="pb-[3px] flex-col justify-center items-end gap-2 flex">
              <div className="justify-center items-end gap-2 inline-flex">
                <div className="text-center text-neutral-500 text-[10px] font-normal font-sans uppercase leading-3 tracking-wide">
                  Estimated time ( in mins)
                </div>
              </div>
            </div>
          </div>
          {/* <div className="self-stretch h-0.5 bg-neutral-200 flex-col justify-start items-start gap-2 flex">
            <div className="w-[21px] h-0.5 bg-success-500" />
          </div> */}
        </div>
      </div>
      {userRole === "master admin" && (
        <div className="w-12 self-stretch justify-center items-center gap-3 flex">
          <div
            className="p-3 bg-neutral-100 rounded-full justify-start items-start gap-2 flex cursor-pointer"
            onClick={handleEdit}
          >
            <div className="w-6 h-6">
              <MdOutlineEdit className="w-full h-full fill-neutral-700" />
            </div>
          </div>
        </div>
      )}
      {userRole === "customer admin" && (
        <div className="w-[108px] self-stretch justify-center items-center gap-3 flex">
          <div
            className="p-3 bg-neutral-100 rounded-full justify-start items-start gap-2 flex cursor-pointer"
            onClick={handleAddToClasses}
          >
            <div className="w-6 h-6">
              <MdOutlineAddToPhotos className="w-full h-full fill-neutral-700" />
            </div>
          </div>
          <div
            className="p-3 bg-neutral-100 rounded-full justify-start items-start gap-2 flex cursor-pointer"
            onClick={() => {}}
          >
            <div className="w-6 h-6">
              <MdArrowForward className="w-full h-full fill-neutral-700" />
            </div>
          </div>
        </div>
      )}
      {["parent", "student"].includes(userRole) && (
        <div className="w-12 self-stretch justify-center items-center gap-3 flex">
          <div
            className="p-3 bg-neutral-100 rounded-full justify-start items-start gap-2 flex cursor-pointer"
            onClick={() => {}}
          >
            <div className="w-6 h-6">
              <MdArrowForward className="w-full h-full fill-neutral-700" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectCard;
