import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import LessonsList from "./LessonsList";
import ViewHeader from "../utils/view-header/ViewHeader";
import { Course, Module, Program, Project } from "utils/interfaces";

interface LessonsProps {
  userRole: string;
  ref: any;
  backPressedToggle: boolean;
  setShowSideBarMenu: React.Dispatch<React.SetStateAction<boolean>>;
  headerRef: React.MutableRefObject<any>;
  quizzesRef: React.MutableRefObject<any>;
}

const Lessons: React.FC<LessonsProps> = forwardRef(
  ({ userRole, backPressedToggle, setShowSideBarMenu, headerRef, quizzesRef }, ref) => {
    // States definition
    const [lessonListToShow, setLessonListToShow] = useState("programs");
    const [searchQuery, setSearchQuery] = useState("");
    const [showTabSearchBar, setShowTabSearchBar] = useState(true);

    // References definition
    const lessonsListRef = useRef();

    /**
     * Define the list of functions that can be used from external components
     * using a reference
     */
    useImperativeHandle(ref, () => ({
      // Outer function to change the tab
      setShowTabSearchBar(bool: boolean) {
        setShowTabSearchBar(bool);
      },
      getActiveLesson() {
        return lessonListToShow;
      },
      setActiveLesson(lesson: string) {
        setLessonListToShow(lesson);
      },
      setShowSettingTabs(bool: boolean) {
        let currentHandler: any;
        if (lessonsListRef.current) currentHandler = lessonsListRef.current;
        currentHandler.setShowTabs(bool);
      },
      setSelectSettingTabs(tab: number) {
        let currentHandler: any;
        if (lessonsListRef.current) currentHandler = lessonsListRef.current;
        currentHandler.setSelectedTab(tab);
      },
      setFromCreateNew(bool: boolean) {
        let currentHandler: any;
        if (lessonsListRef.current) currentHandler = lessonsListRef.current;
        currentHandler.setFromCreateNew(bool);
      },
      pressSaveButton() {
        let currentHandler: any;
        if (lessonsListRef.current) currentHandler = lessonsListRef.current;
        currentHandler.handleSave();
      },
      getLessonListToShow() {
        let currentHandler: any;
        if (lessonsListRef.current) currentHandler = lessonsListRef.current;
        return currentHandler.getLessonListToShow();
      },
      setShowAddToLesson(bool: boolean) {
        let currentHandler: any;
        if (lessonsListRef.current) currentHandler = lessonsListRef.current;
        return currentHandler.setShowAddToLesson(bool);
      },
      setShowAddPopUp(bool: boolean) {
        let currentHandler: any;
        if (lessonsListRef.current) currentHandler = lessonsListRef.current;
        return currentHandler.setShowAddPopUp(bool);
      },
      setSelectedData(record: Program | Course | Project | Module | null) {
        let currentHandler: any;
        if (lessonsListRef.current) currentHandler = lessonsListRef.current;
        return currentHandler.setSelectedData(record);
      },
      getLinkedData() {
        let currentHandler: any;
        if (lessonsListRef.current) currentHandler = lessonsListRef.current;
        return currentHandler.getLinkedData();
      },
      list() {
        return lessonsListRef.current;
      }
    }));

    return (
      <div className="w-full self-stretch flex-col justify-start items-start gap-4 inline-flex">
        {showTabSearchBar && (
          <ViewHeader
            userRole={userRole}
            activeItem={lessonListToShow}
            searchQuery={searchQuery}
            tabOptions={["Programs", "Courses", "Projects", "Modules"]}
            setActiveItem={setLessonListToShow}
            setSearchQuery={setSearchQuery}
            headerRef={headerRef}
          />
        )}
        <LessonsList
          ref={lessonsListRef}
          userRole={userRole}
          lessonListToShow={lessonListToShow}
          searchQuery={searchQuery}
          backPressedToggle={backPressedToggle}
          setShowSideBarMenu={setShowSideBarMenu}
          setLessonListToShow={setLessonListToShow}
          headerRef={headerRef}
          quizzesRef={quizzesRef}
          lessonsRef={ref}
        />
      </div>
    );
  }
);

export default Lessons;
