import { GoArrowLeft } from "react-icons/go";
import { MdAddCircleOutline } from "react-icons/md";
import { ContactListType } from "utils/interfaces";
import { useEffect, useState } from "react";
import { defaultContact } from "components/super-admin/utils/pop-up-panels/add-contact-pop-up/SearchAndCardsList";
import CreateNewContact from "components/super-admin/utils/pop-up-panels/add-contact-pop-up/CreateNewContact";
import ConfirmPrimaryPopUp from "components/super-admin/utils/pop-up-panels/add-contact-pop-up/ConfirmPrimaryPopUp";
import ContactsCardsList from "components/super-admin/utils/pop-up-panels/add-contact-pop-up/ContactsCardsList";
import SuccessPopUpMessage from "components/super-admin/utils/pop-up-panels/SuccessPopUpMessage";

interface AddContactsPanelProps {
  selectedData?: ContactListType;
  selectedContacts: ContactListType[];
  setSelectedContacts: React.Dispatch<React.SetStateAction<ContactListType[]>>;
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddContactsPanel: React.FC<AddContactsPanelProps> = ({
  selectedData,
  selectedContacts,
  setSelectedContacts,
  setShowPopUp,
}) => {
  // States definition
  const [createNewContact, setCreateNewContact] = useState(!!selectedData);
  const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
  const [showConfirmPrimaryPopUp, setShowConfirmPrimaryPopUp] = useState(false);
  const [lastSelectedContact, setLastSelectedContact] =
    useState<ContactListType>(defaultContact);

  /**
   * Definition of the function when pressed back button
   */
  const handlePressBack = () => {
    // If create new was pressed, then come back to the regular option
    if (createNewContact) {
      // If we are editing a contact, then close the pop up once clicked
      // the press button
      if (selectedData) setShowPopUp(false);
      else setCreateNewContact(false);
    }
    // Otherwise, close the pop up
    else setShowPopUp(false);
  };

  /**
   * Definition of the function when pressed back button
   */
  const afterCreateContact = () => {
    // Come back to the regular view
    setCreateNewContact(false);
    // And display the success message
    // setShowSuccessPopUp(true);
  };

  /**
   * Timer for the success pop up message
   */
  useEffect(() => {
    if (showSuccessPopUp) {
      // Starting the timer
      const timer = setTimeout(() => {
        // Once finished, close the pop up
        setShowSuccessPopUp(false);
        // And forget the last selected id
        // setLastSelectedContact(defaultContact);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showSuccessPopUp]);

  return (
    <div className="w-full h-fit max-h-[80vh] flex-col justify-start items-start gap-6 inline-flex relative">
      <div className="self-stretch justify-between items-center inline-flex">
        <div className="justify-start items-center gap-3 flex">
          <div
            className="p-1.5 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center flex cursor-pointer"
            onClick={handlePressBack}
          >
            <div className="w-5 h-5 justify-center items-center flex">
              <div className="w-5 h-5 relative">
                <GoArrowLeft className="w-full h-full fill-dc-secondary-600" />
              </div>
            </div>
          </div>
          <div className="text-neutral-600 text-lg font-normal font-sans leading-snug">
            {selectedData
              ? "Edit contact"
              : createNewContact
              ? "Create new contact"
              : "Contacts"}
          </div>
        </div>
        {!createNewContact && (
          <div
            className="rounded justify-center items-center gap-2 flex cursor-pointer"
            onClick={() => setCreateNewContact(true)}
          >
            <div className="w-6 h-6 relative">
              <MdAddCircleOutline className="w-full h-full fill-dc-secondary-600" />
            </div>
            <div className="text-dc-secondary-600 text-lg font-semibold font-sans leading-snug">
              Create new
            </div>
          </div>
        )}
      </div>
      {!createNewContact && (
        <ContactsCardsList
          role={"customer admin"}
          selectedContacts={selectedContacts}
          setShowSuccessPopUp={setShowSuccessPopUp}
          setShowConfirmPrimaryPopUp={setShowConfirmPrimaryPopUp}
          setSelectedContacts={setSelectedContacts}
          setLastSelectedContact={setLastSelectedContact}
        />
      )}
      {createNewContact && (
        <CreateNewContact
          role="customer admin"
          confirmButtonText={selectedData ? "Save contact" : "Create contact"}
          contactToEdit={selectedData}
          selectedContacts={selectedContacts}
          showPrimaryOption={!selectedData}
          setSelectedContacts={setSelectedContacts}
          setLastSelectedContact={setLastSelectedContact}
          afterCreateFunction={afterCreateContact}
        />
      )}
      <ConfirmPrimaryPopUp
        showPopUp={showConfirmPrimaryPopUp}
        selectedContacts={selectedContacts}
        setShowPopUp={setShowConfirmPrimaryPopUp}
        setSelectedContacts={setSelectedContacts}
        setShowSuccessPopUp={setShowSuccessPopUp}
      />
      <SuccessPopUpMessage
        role="customer admin"
        name={
          lastSelectedContact.firstName + " " + lastSelectedContact.lastName
        }
        showSuccessPopUp={showSuccessPopUp}
        setShowSuccessPopUp={setShowSuccessPopUp}
        dismissAction={() => {}}
      />
    </div>
  );
};

export default AddContactsPanel;
