import { MdOutlineArrowForward } from "react-icons/md";

interface OptionsCardProps {
  option: string;
  handleOnClick: () => void;
}

const OptionsCard: React.FC<OptionsCardProps> = ({ option, handleOnClick }) => {
  return (
    <div
      className="self-stretch p-3 bg-neutral-25 rounded-xl justify-start items-center gap-3 inline-flex cursor-pointer"
      onClick={handleOnClick}
    >
      <div className="grow shrink basis-0 flex-col justify-center items-start gap-2 inline-flex text-neutral-700 text-sm font-semibold font-sans leading-[17px]">
        {option}
      </div>
      <div className="p-2 bg-neutral-200 rounded-full justify-start items-start gap-2 flex">
        <div className="w-4 h-4 relative">
          <MdOutlineArrowForward className="w-full h-full fill-neutral-700" />
        </div>
      </div>
    </div>
  );
};

export default OptionsCard;
