import { Tab } from "@headlessui/react";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { emailValidation } from "./resetPasswordValidation";
import logo from "assets/imgs/logo.svg";
import backgroundImg from "assets/imgs/uluru.jpeg";
import { LoadingDots } from "utils/loadingAnimation";
import { resetPasswordRequest } from "services/authRequests";
import { AnimatePresence, motion } from "framer-motion";
import { MdOutlinePerson } from "react-icons/md";

const ResetPassword: React.FC = () => {
  // States definition
  const [resetLoading, setResetLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<String>("");
  // Form hooks
  const methods = useForm({ mode: "onSubmit" });
  // Redirect hook
  const navigate = useNavigate();

  // Options definition
  const userOptions = [
    {
      type: "Student",
      description:
        "Enter your student username below to inform your teacher about this request. Your teacher will follow up with this request.",
    },
    {
      type: "Parent",
      description:
        "Enter your email address below to receive a password reset link.",
    },
    {
      type: "Admin",
      description:
        "Enter your email address below to receive a password reset link.",
    },
  ];

  // Function for submitting
  const handleEmailSubmit = methods.handleSubmit(async (programData: any) => {
    // Set animation state
    setResetLoading(true);
    setShowError(false);

    // Define the object to send
    const dataToSend = {
      ...programData,
      role: userOptions[selectedTab].type.toLowerCase(),
    };

    // Send the reset password information for checking
    let response: { success: boolean; message: string } =
      await resetPasswordRequest(dataToSend);

    // Stop the loading animation
    setResetLoading(false);

    // If the response is successful
    if (response.success) {
      // Then go to the home page
      setShowError(false);
      setErrorMessage("");
      navigate("/success-reset-password", { state: { fromApp: true } });
    } else {
      // Display an error message
      setShowError(true);
      setErrorMessage(response.message);
    }
  });

  return (
    <div className="w-full h-full relative">
      <div className="fixed w-full h-screen min-w-[1436px] z-0">
        <img
          className="absolute grow basis-0 size-full flex z-0 resize-none"
          src={backgroundImg}
        />
      </div>
      <div className="w-full h-screen p-14 z-10 flex items-center">
        <div className="w-full h-full min-w-[1276px] p-32 bg-neutral-50 bg-opacity-70 rounded-2xl shadow backdrop-blur-[32px] justify-start items-center gap-24 inline-flex">
          <div className="grow shrink basis-0 self-stretch flex-col justify-start items-center gap-10 inline-flex">
            <div className="self-stretch justify-start items-center gap-2 inline-flex">
              <Link to="/">
                <img
                  className="w-fit h-fit p-2 rounded-xl bg-neutral-50"
                  src={logo}
                />
              </Link>
            </div>
            <div className="self-stretch text-neutral-700 text-base font-normal font-sans leading-7">
              Deadly Coders is a community-managed not-for-profit on a mission
              to ignite learning and career pathways in STEM to Aboriginal and
              Torres Straight Islander primary and secondary students across
              Australia. We provide a culturally safe, connected space for
              learning and creativity in digital technologies – our fully
              subsidised  programs include coding, robotics and digital
              technologies curriculum.
            </div>
          </div>
          <div className="w-px self-stretch justify-start items-start gap-2 flex">
            <div className="w-px self-stretch bg-white" />
          </div>
          <div className="grow shrink basis-0 flex-col justify-center items-center gap-8 inline-flex">
            <div className="self-stretch h-[165px] flex-col justify-start items-start gap-6 flex">
              <div className="self-stretch justify-start items-center gap-2 inline-flex">
                <div className="grow shrink basis-0 text-neutral-700 text-[33px] font-semibold font-sans leading-[34px]">
                  Forgot Password
                </div>
              </div>
              <div className="self-stretch h-[49px] flex-col justify-center items-start gap-2 flex">
                <Tab.Group
                  selectedIndex={selectedTab}
                  onChange={setSelectedTab}
                >
                  <Tab.List className="p-2 bg-stone-500 rounded-full backdrop-blur-[32px] justify-start items-start gap-3 inline-flex">
                    {userOptions.map((option) => {
                      return (
                        <Tab key={option.type}>
                          {({ selected }) => {
                            return (
                              <div
                                className={
                                  selected
                                    ? "px-4 py-2 bg-neutral-50 text-neutral-700 rounded-full justify-center items-center gap-2 flex focus:outline-none"
                                    : "px-4 py-2 text-white rounded-full justify-center items-center gap-2 flex focus:outline-none"
                                }
                              >
                                <div className="text-sm font-normal font-sans leading-[17px] focus:outline-none">
                                  {option.type}
                                </div>
                              </div>
                            );
                          }}
                        </Tab>
                      );
                    })}
                  </Tab.List>
                </Tab.Group>
              </div>
              <div className="self-stretch justify-start items-center gap-2 inline-flex">
                <div className="grow shrink basis-0 text-neutral-700 text-sm font-normal font-sans leading-[21px]">
                  {userOptions[selectedTab].description}
                </div>
              </div>
            </div>
            <FormProvider {...methods}>
              <form
                id="reset-password-form"
                className="w-full"
                onSubmit={(event) => event.preventDefault()}
                noValidate
                autoComplete="off"
              >
                <div className="self-stretch h-full flex-col justify-start items-start gap-6 flex">
                  <div className="self-stretch h-full flex-col justify-start items-start gap-1.5 flex">
                    <div className="rounded justify-start items-start gap-2 inline-flex">
                      <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide select-none">
                        Username
                      </div>
                    </div>
                    <div className="w-full h-[51px] px-3.5 rounded-lg bg-neutral-50 text-neutral-700 placeholder:text-neutral-300 text-base font-normal font-sans leading-[19px] flex items-center">
                      <div className="flex justify-center items-center">
                        <MdOutlinePerson className="w-5 h-5 text-neutral-500"/>
                      </div>
                      <input
                        id="email"
                        type="email"
                        className="w-full h-full px-2 rounded-lg bg-neutral-50 text-neutral-700 placeholder:text-neutral-300 focus:outline-none text-base font-normal font-sans leading-[19px] "
                        placeholder="Enter your username"
                        autoComplete="email"
                        {...methods.register("email", emailValidation)}
                      />
                    </div>
                    {/* Error message */}
                    <AnimatePresence mode="wait" initial={false}>
                      {showError && (
                        <motion.div
                          className="w-full py-1 text-red-600 text-sm font-normal font-sans leading-[17px]"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                        >
                          {errorMessage}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>

                  <button
                    className="self-stretch px-5 py-3 bg-dc-secondary-600 rounded justify-center items-center gap-2 inline-flex"
                    onClick={handleEmailSubmit}
                  >
                    <div className="text-white text-lg font-semibold font-sans leading-snug">
                      {resetLoading ? <LoadingDots /> : "Reset Password"}
                    </div>
                  </button>
                </div>
              </form>
            </FormProvider>
            <div className="w-full justify-start items-start gap-2 inline-flex">
              <div className="text-neutral-700 text-sm font-normal font-sans leading-[17px]">
                Don’t have an account?
              </div>
              <Link to="/how-to-sign-up">
                <div className="text-neutral-700 text-sm font-semibold font-sans underline leading-[17px]">
                  Find out how to sign up
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
