/**
 * SideBarMenu.tsx
 * ---------------
 * Component used as a side bar menu when using the DC platform.
 * This controls what content is being rendered:
 * - Dashboard
 * - Teachers
 * - Students
 * - Lessons
 * - Classes
 * - Quizzes
 */
import { ReactElement } from "react";
import {
  MdChevronRight,
  MdDashboard,
  MdFeaturedPlayList,
  MdOutlineDashboard,
  MdOutlineFeaturedPlayList,
  MdOutlinePeopleAlt,
  MdOutlineQuiz,
  MdOutlineSupervisedUserCircle,
  MdPeopleAlt,
  MdQuiz,
  MdSupervisedUserCircle,
} from "react-icons/md";
import customerAdminLogo from "assets/imgs/PLACEHOLDERcustomerAdminLogo.png";
import { SchoolListType } from "utils/interfaces";

interface LateralMenuOptionProps {
  text: string;
  icon: ReactElement<any, any>;
  active: boolean;
  setActiveItem: React.Dispatch<React.SetStateAction<string>>;
}

interface SideBarMenuProps {
  userRole: string;
  schoolId: string;
  userSchool: SchoolListType | null;
  selected: string;
  setActiveItem: React.Dispatch<React.SetStateAction<string>>;
  customersRef: React.MutableRefObject<any>;
}

const LateralMenuOption: React.FC<LateralMenuOptionProps> = ({
  text,
  icon,
  active,
  setActiveItem,
}) => {
  return (
    <div
      className="self-stretch h-[61px] flex-col justify-center items-center flex cursor-pointer"
      onClick={() => setActiveItem(text.toLowerCase())}
    >
      <div
        className={
          active
            ? "self-stretch p-2 bg-white rounded-[20px] justify-between items-center inline-flex"
            : "self-stretch p-2 rounded-[20px] justify-between items-center inline-flex"
        }
      >
        <div className="w-4 h-4 py-[4.28px] opacity-0 justify-center items-center flex" />
        <div className="justify-start items-start gap-2 flex">
          <div className="w-8 h-8 relative">{icon}</div>
        </div>
        <div className="w-4 h-4 justify-center items-center flex">
          {active ? (
            <MdChevronRight className="w-full h-full fill-neutral-700" />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div
        className={
          active
            ? "text-center text-neutral-700 text-sm font-semibold font-sans leading-[21px] select-none"
            : "text-center text-neutral-700 text-sm font-normal font-sans leading-[21px] select-none"
        }
      >
        {text}
      </div>
    </div>
  );
};

const SideBarMenu: React.FC<SideBarMenuProps> = ({
  userRole,
  schoolId,
  userSchool,
  selected,
  setActiveItem,
  customersRef,
}) => {
  /**
   * Action to perform on click school logo
   */
  const handleClickSchoolLogo = () => {
    // Change the tab to customers
    setActiveItem("school-options");

    // Wait until customersRef is set
    const waitForCustomersRef = new Promise((resolve) => {
      const checkRef = () => {
        if (customersRef.current) {
          resolve(true);
        } else {
          requestAnimationFrame(checkRef);
        }
      };
      checkRef();
    });

    waitForCustomersRef.then(() => {
      customersRef.current.showSchool(schoolId, selected);
    });
  };

  return (
    <div className="w-[103px] self-stretch inline-flex flex-col justify-between items-center gap-4 overflow-hidden">
      <div className="px-3 py-4 bg-neutral-50 rounded-3xl flex flex-col justify-start items-start gap-8">
        <LateralMenuOption
          text="Dashboard"
          icon={
            selected === "dashboard" ? (
              <MdDashboard className="w-full h-full fill-neutral-700" />
            ) : (
              <MdOutlineDashboard className="w-full h-full fill-neutral-700" />
            )
          }
          active={selected === "dashboard"}
          setActiveItem={setActiveItem}
        />
        {/* {["master admin"].includes(userRole) && (
          <LateralMenuOption
            text="Customers"
            icon={
              selected === "customers" ? (
                <MdSupervisedUserCircle className="w-full h-full fill-neutral-700" />
              ) : (
                <MdOutlineSupervisedUserCircle className="w-full h-full fill-neutral-700" />
              )
            }
            active={selected === "customers"}
            setActiveItem={setActiveItem}
          />
        )} */}
        {["customer admin"].includes(userRole) && (
          <LateralMenuOption
            text="Classes"
            icon={
              selected === "classes" ? (
                <MdSupervisedUserCircle className="w-full h-full fill-neutral-700" />
              ) : (
                <MdOutlineSupervisedUserCircle className="w-full h-full fill-neutral-700" />
              )
            }
            active={selected === "classes"}
            setActiveItem={setActiveItem}
          />
        )}
        {["master admin"].includes(userRole) && (
          <LateralMenuOption
            text="Users"
            icon={
              selected === "users" ? (
                <MdPeopleAlt className="w-full h-full fill-neutral-700" />
              ) : (
                <MdOutlinePeopleAlt className="w-full h-full fill-neutral-700" />
              )
            }
            active={selected === "users"}
            setActiveItem={setActiveItem}
          />
        )}
        <LateralMenuOption
          text="Lessons"
          icon={
            selected === "lessons" ? (
              <MdFeaturedPlayList className="w-full h-full fill-neutral-700" />
            ) : (
              <MdOutlineFeaturedPlayList className="w-full h-full fill-neutral-700" />
            )
          }
          active={selected === "lessons"}
          setActiveItem={setActiveItem}
        />
        {["master admin"].includes(userRole) && (
          <LateralMenuOption
              text="Quizzes"
              icon={
                selected === "quizzes" ? (
                  <MdQuiz className="w-full h-full fill-neutral-700" />
                ) : (
                  <MdOutlineQuiz className="w-full h-full fill-neutral-700" />
                )
              }
              active={selected === "quizzes"}
              setActiveItem={setActiveItem}
            />
        )}
      </div>
      {userRole === "customer admin" && (
        <div
          className="overflow-hidden flex-col justify-end items-center gap-1 flex cursor-pointer"
          onClick={handleClickSchoolLogo}
        >
          <div className="p-1 bg-neutral-50 rounded-full justify-start items-center gap-2 inline-flex">
            <div className="w-16 h-16 p-0 rounded-full justify-start items-end gap-2 flex">
              <img className="w-full h-full" alt="" src={userSchool ? userSchool.logo : "/logo.svg"} />
            </div>
          </div>
          <div className="text-center text-neutral-700 text-xs font-normal font-sans leading-[14px]">
            {userSchool ? userSchool.name : "-"}
          </div>
        </div>
      )}
    </div>
  );
};

export default SideBarMenu;
