import { useEffect, useState } from "react";
import { GoArrowLeft } from "react-icons/go";
import { MdAddCircleOutline } from "react-icons/md";
import { ContactListType, TeacherListType } from "utils/interfaces";
import CreateNewContact from "components/super-admin/utils/pop-up-panels/add-contact-pop-up/CreateNewContact";
import { defaultContact } from "components/super-admin/utils/pop-up-panels/add-contact-pop-up/SearchAndCardsList";
import ContactsCardsList from "../add-contact-pop-up/ContactsCardsList";
import SuccessPopUpMessage from "../SuccessPopUpMessage";

interface AddTeacherPanelProps {
  selectedData?: TeacherListType;
  selectedTeachers: ContactListType[];
  justDisplay?: boolean;
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  externalSetPopUpState?: React.Dispatch<
    React.SetStateAction<"main" | "create-new" | "add-teacher">
  >;
  setSelectedTeachers: React.Dispatch<React.SetStateAction<ContactListType[]>>;
  getData?: () => Promise<any>;
}

const AddTeacherPanel: React.FC<AddTeacherPanelProps> = ({
  selectedData,
  selectedTeachers,
  justDisplay = false,
  setShowPopUp,
  externalSetPopUpState,
  setSelectedTeachers,
  getData,
}) => {
  // States definition
  const [createNewTeacher, setCreateNewTeacher] = useState(!!selectedData);
  const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
  const [lastSelectedContact, setLastSelectedContact] =
    useState<ContactListType>(defaultContact);

  /**
   * Definition of the function when pressed back button
   */
  const handlePressBack = () => {
    // If create new was pressed, then come back to the regular option
    if (createNewTeacher) {
      // If we are just editing, then close pop up from teacher
      if (selectedData) setShowPopUp(false);
      // Otherwise, just come back
      else setCreateNewTeacher(false);
    }
    // Otherwise, if we have an externalSetPopUpState which indicates that this
    // module is being called from the create class pop up, then try to use it
    else if (externalSetPopUpState) externalSetPopUpState("create-new");
    // Otherwise, this pop up is being used by itself, so just close it
    else setShowPopUp(false);
  };

  /**
   * Function used after creating a teacher
   */
  const afterCreateTeacher = () => {
    // If editing, just close the pop up
    if (selectedData) {
      // Close the pop up
      setShowPopUp(false);
      // And refresh the list of teachers
      if (getData) getData();
    } else {
      // Come back to the main view
      setCreateNewTeacher(false);
      // And show a successful message
      setShowSuccessPopUp(true);
    }
  };

  /**
   * Timer for the success pop up message
   */
  useEffect(() => {
    if (showSuccessPopUp) {
      // Starting the timer
      const timer = setTimeout(() => {
        // Once finished, close the pop up
        setShowSuccessPopUp(false);
        // And forget the last selected id
        // setLastSelectedContact(defaultContact);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showSuccessPopUp]);

  return (
    <div className="relative w-full h-fit max-h-[80vh] flex-col justify-start items-start gap-6 inline-flex">
      <div className="self-stretch justify-between items-center inline-flex">
        <div className="justify-start items-center gap-3 flex">
          <div
            className="p-1.5 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center flex cursor-pointer"
            onClick={handlePressBack}
          >
            <div className="w-5 h-5 justify-center items-center flex">
              <div className="w-5 h-5 relative">
                <GoArrowLeft className="w-full h-full fill-dc-secondary-600" />
              </div>
            </div>
          </div>
          <div className="text-neutral-600 text-lg font-normal font-sans leading-snug">
            {justDisplay
              ? "Teachers assigned to this class"
              : selectedData
              ? "Edit Teacher"
              : createNewTeacher
              ? "Add new Teacher"
              : "Add Teacher"}
          </div>
        </div>
        {!createNewTeacher && !justDisplay && (
          <div
            className="rounded justify-center items-center gap-2 flex cursor-pointer"
            onClick={() => setCreateNewTeacher(true)}
          >
            <div className="w-6 h-6 relative">
              <MdAddCircleOutline className="w-full h-full fill-dc-secondary-600" />
            </div>
            <div className="text-dc-secondary-600 text-lg font-semibold font-sans leading-snug">
              Create new
            </div>
          </div>
        )}
      </div>
      {!createNewTeacher && (
        <ContactsCardsList
          role="class admin"
          selectedContacts={selectedTeachers}
          primaryTags={false}
          justDisplay={justDisplay}
          setShowSuccessPopUp={setShowSuccessPopUp}
          setSelectedContacts={setSelectedTeachers}
          setLastSelectedContact={setLastSelectedContact}
        />
      )}
      {createNewTeacher && (
        <CreateNewContact
          role="class admin"
          contactToEdit={selectedData as ContactListType}
          selectedContacts={selectedTeachers}
          confirmButtonText={selectedData ? "Save Teacher" : "Add Teacher"}
          showPrimaryOption={false}
          setSelectedContacts={setSelectedTeachers}
          afterCreateFunction={afterCreateTeacher}
          setLastSelectedContact={setLastSelectedContact}
        />
      )}
      <SuccessPopUpMessage
        role="class admin"
        name={
          lastSelectedContact.firstName + " " + lastSelectedContact.lastName
        }
        showSuccessPopUp={showSuccessPopUp}
        setShowSuccessPopUp={setShowSuccessPopUp}
        dismissAction={() => {}}
      />
    </div>
  );
};

export default AddTeacherPanel;
