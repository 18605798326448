import { AnimatePresence, motion } from "framer-motion";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { findInputError } from "routes/login/findInputError";
import { isFormInvalid } from "routes/login/isFormValid";
import { LoadingDots } from "utils/loadingAnimation";
import { getEmailLogin } from "services/loginRequests";
import { useState } from "react";
import { emailValidation, passwordValidation, testEmailValidation } from "./loginValidation";
import { InputError } from "utils/PopUpMessages";
import { MdLockOpen, MdOutlinePerson, MdOutlineVisibility } from "react-icons/md";

const LoginForm = () => {
  // States definition
  const [loginLoading, setLoginLoading] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [pwdVisibility, setPwdVisibility] = useState<boolean>(false);

  // Form hooks
  const methods = useForm({ mode: "onSubmit" });
  // Redirect hook
  const navigate = useNavigate();

  /**
   * Function for submitting
   */
  const handleEmailLogin = methods.handleSubmit(async (programData: any) => {
    // Set animation state
    setShowError(false);
    setLoginLoading(true);

    // Send the login information for checking
    let response: { success: boolean; message: string; twoFactorId?: string } =
      await getEmailLogin(programData);

    // Stop the animation
    setLoginLoading(false);

    // If the response is successful
    if (response.success) {
      // Then go to the home page
      setShowError(false);
      navigate(`/two-factor-authentication/${response.twoFactorId}`, {
        state: { fromApp: true, remember: programData.remember },
      });
    } else {
      // Display an error message
      setShowError(true);
    }
  });

  /**
   * Temporayr test function
   */
  const handleTestLogin = methods.handleSubmit(async (programData: FieldValues) => {
    // Set animation state
    setShowError(false);
    setLoginLoading(true);

    // Get the defined role from the email field
    const role = programData.email;

    // Create a cookie with the role
    document.cookie = `role=${role}`;
    navigate("/home");

    // Stop the animation
    setLoginLoading(false);
  });

  // Get the errors
  const usernameError: { [k: string]: any } = findInputError(
    methods.formState.errors,
    "email"
  );
  
  const passwordError: { [k: string]: any } = findInputError(
    methods.formState.errors,
    "password"
  );

  return (
    <FormProvider {...methods}>
      <form
        id="login-form"
        className="w-full"
        onSubmit={(event) => event.preventDefault()}
        noValidate
        autoComplete="off"
      >
        <div className="self-stretch h-full flex-col justify-start items-start gap-6 flex">
          <div className="w-full">
            <div className="self-stretch h-[71px] flex-col justify-start items-start gap-1.5 flex">
              <div className="rounded justify-start items-start gap-2 inline-flex">
                <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide select-none">
                  Username
                </div>
              </div>
              <div className="w-full h-full px-3.5 rounded-lg bg-neutral-50 text-neutral-700 placeholder:text-neutral-300 text-base font-normal font-sans leading-[19px] flex items-center">
                <div className="flex justify-center items-center">
                  <MdOutlinePerson className="w-5 h-5 text-neutral-500"/>
                </div>
                <input
                  // {...methods.register("email", emailValidation)}
                  {...methods.register("email", testEmailValidation)}
                  id="email"
                  type="text"
                  className="w-full h-full px-2 rounded-lg bg-neutral-50 text-neutral-700 placeholder:text-neutral-300 focus:outline-none text-base font-normal font-sans leading-[19px] "
                  placeholder="Enter your username"
                  autoComplete="email"
                />
              </div>
            </div>
            {/* Error message */}
            <AnimatePresence mode="wait" initial={false}>
              {isFormInvalid(usernameError) && (
                <motion.div
                  className="w-full py-1 text-red-600 text-sm font-normal font-sans leading-[17px]"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  {usernameError.error.message}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div className="w-full">
            <div className="self-stretch h-[71px] flex-col justify-start items-start gap-1.5 flex">
              <div className="rounded justify-start items-start gap-2 inline-flex">
                <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide select-none">
                  Password
                </div>
              </div>
              <div className="w-full h-full px-3.5 rounded-lg bg-neutral-50 text-neutral-700 placeholder:text-neutral-300 text-base font-normal font-sans leading-[19px] flex items-center">
                <div className="flex justify-center items-center">
                  <MdLockOpen className="w-5 h-5 text-neutral-500"/>
                </div>
                <input
                  // {...methods.register("password", passwordValidation)}
                  id="password"
                  type={pwdVisibility ? "text" : "password"}
                  className="w-full h-full px-2 rounded-lg bg-neutral-50 text-neutral-700 placeholder:text-neutral-300 focus:outline-none text-base font-normal font-sans leading-[19px]"
                  placeholder="Enter your password"
                  autoComplete="password"
                />
                <div
                  className="flex justify-center items-center cursor-pointer"
                  onClick={() => setPwdVisibility(!pwdVisibility)}
                >
                  <MdOutlineVisibility className="w-5 h-5 text-neutral-500"/>
                </div>
              </div>
            </div>
            {/* Error message */}
            <AnimatePresence mode="wait" initial={false}>
              {isFormInvalid(passwordError) && (
                <motion.div
                  className="w-full py-1 text-red-600 text-sm font-normal font-sans leading-[17px]"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  A valid password is required
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div className="self-stretch h-full flex-col justify-start items-start gap-3 flex">
            <div className="w-full h-full flex items-center">
              <div className="w-fit h-full flex justify-start items-center gap-1 text-lg">
                <input
                  {...methods.register("remember")}
                  type="checkbox"
                  id="remember"
                  name="remember"
                  className="flex-1 w-4 h-4 accent-neutral-700"
                />
                <label
                  htmlFor="remember"
                  className="text-neutral-700 text-base font-sans leading-[17px] select-none"
                >
                  Remember me
                </label>
              </div>
              <div className="flex-1" />
              <Link to="/reset-password">
                <div className="w-fit h-full items-center gap-2 flex">
                  <div className="text-neutral-700 text-base font-normal font-sans underline leading-[17px]">
                    Forgot Password?
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <button
            className="self-stretch px-5 py-3 bg-dc-secondary-600 rounded justify-center items-center gap-2 inline-flex"
            onClick={handleTestLogin}
          >
            <div className="text-white text-lg font-semibold font-sans leading-snug">
              {loginLoading ? <LoadingDots /> : "Sign in"}
            </div>
          </button>
        </div>
        <AnimatePresence mode="wait">
          {showError && (
            <div className="w-full mt-4 flex justify-center items-center">
              <InputError message="The email and password do not match" />
            </div>
          )}
        </AnimatePresence>
      </form>
    </FormProvider>
  );
};

export default LoginForm;
