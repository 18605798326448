/**
 * SuccessMessage.tsx
 * ------------------
 * Page that appears when the registration process has been completed
 * without problems.
 */
import React from "react";
import logo from "assets/imgs/logo.svg";
import backgroundImg from "assets/imgs/uluru.jpeg";
import { Link, Navigate, useLocation } from "react-router-dom";
import CheckSignIcon from "assets/icons/login-signup/CheckSignIcon";

const SuccessRegister: React.FC = () => {
  // Use the location to avoid enter this page from any other place
  const { state } = useLocation();

  if (!state?.fromApp) {
    return <Navigate to={"/url-error"} />;
  }

  return (
    <div className="w-full h-full relative">
      <div className="absolute w-full h-screen min-w-[1436px] z-0">
        <img
          className="absolute grow basis-0 size-full flex z-0 resize-none"
          src={backgroundImg}
        />
      </div>
      <div className="w-full h-screen p-14 z-10 flex justify-center items-center">
        <div className="w-[628px] p-24 bg-stone-200 bg-opacity-70 rounded-2xl shadow backdrop-blur-[32px] justify-start items-center gap-24 inline-flex">
          <div className="grow shrink basis-0 flex-col justify-center items-center gap-12 inline-flex">
            <div className="self-stretch justify-start items-center gap-2 inline-flex">
              <img className="w-[188px] h-[91px] rounded-xl" src={logo} />
            </div>
            <div className="self-stretch justify-center items-center gap-12 inline-flex">
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-6 inline-flex">
                <div className="self-stretch justify-start items-center gap-2 inline-flex">
                  <div className="grow shrink basis-0 text-neutral-700 text-[33px] font-semibold font-sans leading-[34px]">
                    You're all set!
                  </div>
                </div>
              </div>
              <div className="w-10 h-10 relative">
                <div className="flex justify-center items-center w-[40px] h-[40px] bg-success-teal rounded-full">
                  <CheckSignIcon />
                </div>
              </div>
            </div>
            <div className="self-stretch h-[124px] flex-col justify-start items-start gap-8 flex">
              <div className="self-stretch h-[46px] flex-col justify-start items-start gap-3 flex">
                <div className="self-stretch justify-start items-center gap-2 inline-flex">
                  <div className="grow shrink basis-0 text-neutral-700 text-base font-normal font-sans leading-[17px]">
                    Thank you for completing your account.
                  </div>
                </div>
                <div className="self-stretch justify-start items-center gap-2 inline-flex">
                  <div className="grow shrink basis-0 text-neutral-700 text-base font-normal font-sans leading-[17px]">
                    Click on the button below to start using Deadly Coders.
                  </div>
                </div>
              </div>
              <Link
                className="w-full px-5 py-3 bg-dc-secondary-600 rounded justify-center items-center gap-2 inline-flex"
                to="/login"
              >
                <div className="text-white text-lg font-semibold font-sans leading-snug">
                  Log In to Deadly Coders
                </div>
              </Link>
            </div>
            <div className="self-stretch justify-center items-center gap-2 inline-flex">
              <div className="text-neutral-700 text-base font-normal font-sans leading-[17px]">
                Need help?
              </div>
              <Link to="/contact-us">
                <div className="text-center text-cyan-800 text-base font-bold font-sans underline leading-[17px]">
                  Contact us
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessRegister;
