import { useRef } from "react";
import { MdSearch } from "react-icons/md";

interface ViewSearchBarProps {
  userRole: string;
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  tabOptionsExists: boolean;
}

const ViewSearchBar: React.FC<ViewSearchBarProps> = ({
  userRole,
  searchQuery,
  setSearchQuery,
  tabOptionsExists,
}) => {
  // Input reference for focus
  const searchInputRef = useRef<HTMLInputElement>(null);
  // And definition of the focus function
  const handleFocus = () => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };
  
  return (
    <>
      {!tabOptionsExists && <div className="w-[20%]" />}
      <div
        className={
          tabOptionsExists
            ? "w-80 flex-col justify-start items-start inline-flex"
            : "w-full flex-col justify-start items-start inline-flex"
        }
      >
        <div className="w-full h-[51px] bg-neutral-50 rounded-lg justify-start items-center gap-2 inline-flex">
          <div className="absolute mx-3 w-5 h-5" onClick={handleFocus}>
            <MdSearch className="w-full h-full fill-neutral-500" />
          </div>
          <input
            ref={searchInputRef}
            type="text"
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.target.value)}
            className="w-full h-full p-3 pl-10 bg-transparent focus:outline-none text-neutral-600 placeholder:text-neutral-300 text-base font-normal font-sans leading-[19px]"
            placeholder="Search"
          />
        </div>
      </div>
      {!tabOptionsExists && <div className="w-[20%]" />}
    </>
  );
};

export default ViewSearchBar;
