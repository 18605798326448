import { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { QuestionType, QuizListType } from "utils/interfaces";
import AddQuestion from "./AddQuestion";
import QuestionCard from "./QuestionCard";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { createRecordRequest, updateRecordRequest } from "services/apiRequests";

interface CreateNewQuizProps {
  selectedData?: QuizListType | null;
  popUpState: "main" | "create-new" | "add-question" | "edit-question";
  setPopUpState: React.Dispatch<
    React.SetStateAction<
      "main" | "create-new" | "add-question" | "edit-question"
    >
  >;
  afterCreateFunction: () => void;
}

const CreateNewQuiz: React.FC<CreateNewQuizProps> = ({
  selectedData,
  popUpState,
  setPopUpState,
  afterCreateFunction,
}) => {
  // States definition
  const [quizName, setQuizName] = useState("");
  const [description, setDescription] = useState("");
  const [questionsList, setQuestionsList] = useState<QuestionType[]>([]);
  const [activateCreateQuizButton, setActivateCreateQuizButton] =
    useState(false);
  const [questionIndexToEdit, setQuestionIndexToEdit] = useState(NaN);

  /**
   * If there is selected data, then prefill the defined fields
   */
  useEffect(() => {
    if (selectedData) {
      setQuizName(selectedData.title);
      setDescription(selectedData.description);
      setQuestionsList(selectedData.questions);
    }
  }, []);

  /**
   * Function to check when the required fields are filled out
   * in order to activate the quiz button
   */
  useEffect(() => {
    // Activate when the quizName is filled and we have at least one question
    setActivateCreateQuizButton(!!quizName && questionsList.length > 0);
  }, [quizName, questionsList]);

  /**
   * Function for creating/edit a quiz button
   */
  const handleSaveQuiz = async () => {
    // Check if the button is already available
    if (!activateCreateQuizButton) return;

    // Get the data
    const data = provideData();

    let response = { successful: false, message: "" };
    // Request for editing a quiz
    if (selectedData) {
      response = await updateRecordRequest(
        { _id: selectedData._id, toUpdate: data },
        "/api/quizzes"
      );
    } else {
      response = await createRecordRequest(data, "/api/quizzes");
    }

    // Check if the response is successful
    if (!response.successful) {
      alert(response.message);
      return;
    }

    // Execute the defined actions after the creation
    afterCreateFunction();
  };

  /**
   * Function that returns all the form data
   * @returns data
   */
  const provideData = () => {
    // Return all the gathered data in the form
    return {
      title: quizName,
      description: description,
      questions: questionsList,
    };
  };

  /**
   * Function to delete a question when pressed delete button
   */
  const deleteQuestionAction = (index: number) => {
    // And delete that specific index
    setQuestionsList([
      ...questionsList.slice(0, index),
      ...questionsList.slice(index + 1),
    ]);
  };

  /**
   * Function to edit a question when pressed on the card
   */
  const editQuestionAction = (index: number) => {
    // Set the question to Edit
    setQuestionIndexToEdit(index);
    // And set the pop up state
    setPopUpState("edit-question");
  };

  /**
   * For re-ordering the questions visually on screen
   * @param result
   * @returns
   */
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(questionsList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setQuestionsList(items);
  };

  return (
    <>
      {popUpState === "create-new" && (
        <div className="w-full max-h-[70vh] flex-col justify-start items-start gap-8 inline-flex">
          <div className="w-full h-fit flex-col justify-start items-start gap-8 inline-flex overflow-y-auto pop-up-scrollbar">
            <div className="self-stretch h-fit flex-col justify-start items-start gap-4 flex">
              <div className="self-stretch text-neutral-500 text-base font-semibold font-sans leading-[19px]">
                Basic details
              </div>
              <div className="self-stretch h-fit flex-col justify-start items-start gap-1.5 flex">
                <div className="rounded justify-start items-start gap-2 inline-flex">
                  <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
                    Quiz Name*
                  </div>
                </div>
                <div className="self-stretch bg-neutral-50 rounded-lg justify-start items-center gap-2 inline-flex">
                  <input
                    type="text"
                    value={quizName}
                    onChange={(event) => setQuizName(event.target.value)}
                    className="w-full h-full p-3 text-neutral-700 placeholder:text-neutral-300 text-base font-sans leading-[19px] bg-transparent border-none focus:outline-none"
                    placeholder="Enter name of quiz"
                  />
                </div>
              </div>
              <div className="self-stretch h-[171px] flex-col justify-start items-start gap-1.5 flex">
                <div className="rounded justify-start items-start gap-2 inline-flex">
                  <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
                    Quiz Description (Optional)
                  </div>
                </div>
                <div className="self-stretch grow shrink basis-0 bg-neutral-50 rounded-lg flex-col justify-start items-start flex">
                  <div className="w-full h-[151px] justify-start items-start gap-2 inline-flex">
                    <textarea
                      value={description}
                      onChange={(event) => setDescription(event.target.value)}
                      className="p-3 grow basis-0 self-stretch bg-neutral-50 rounded-lg text-neutral-700 text-base font-normal font-sans leading-[19px] resize-none placeholder:text-neutral-300 focus:outline-none custom-scroll"
                      placeholder="Provide a brief description about this quiz"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch h-fit flex-col justify-start items-start gap-4 flex">
              <div className="self-stretch text-neutral-500 text-base font-semibold font-sans leading-[19px]">
                Questions
              </div>
              <div
                className="self-stretch h-fit flex-col justify-start items-start gap-8 flex cursor-pointer"
                onClick={() => setPopUpState("add-question")}
              >
                <div className="self-stretch px-2 py-3 rounded-lg border border-dashed border-neutral-300 justify-center items-center gap-2 inline-flex">
                  <div className="w-6 h-6 relative">
                    <MdAdd className="w-full h-full fill-neutral-500" />
                  </div>
                  <div className="text-neutral-500 text-base font-normal font-sans leading-[19px] select-none">
                    Add a question
                  </div>
                </div>
              </div>
              {questionsList.length > 0 && (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="questions">
                    {(provided: any) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="w-full space-y-4"
                      >
                        {questionsList.map(
                          (question: QuestionType, index: number) => (
                            <Draggable
                              key={index}
                              draggableId={`question-${index}`}
                              index={index}
                            >
                              {(provided: any) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className="full-width-draggable"
                                >
                                  <QuestionCard
                                    index={index}
                                    question={question.title}
                                    deleteQuestionAction={deleteQuestionAction}
                                    editQuestionAction={editQuestionAction}
                                  />
                                </div>
                              )}
                            </Draggable>
                          )
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
            </div>
          </div>
          <div
            className={
              activateCreateQuizButton
                ? "w-full h-[46px] px-5 py-3 bg-dc-secondary-600 rounded justify-center items-center gap-2 inline-flex cursor-pointer"
                : "w-full h-[46px] px-5 py-3 bg-Subtle rounded justify-center items-center gap-2 inline-flex cursor-pointer"
            }
            onClick={handleSaveQuiz}
          >
            <div
              className={
                activateCreateQuizButton
                  ? "text-white text-lg font-semibold font-sans leading-snug select-none"
                  : "text-neutral-400 text-lg font-semibold font-sans leading-snug select-none"
              }
            >
              {selectedData ? "Save Quiz" : "Create Quiz"}
            </div>
          </div>
        </div>
      )}
      {popUpState === "add-question" && (
        <AddQuestion
          questionsList={questionsList}
          setQuestionsList={setQuestionsList}
          setPopUpState={setPopUpState}
        />
      )}
      {popUpState === "edit-question" && (
        <AddQuestion
          questionIndexToEdit={questionIndexToEdit}
          questionsList={questionsList}
          setQuestionsList={setQuestionsList}
          setPopUpState={setPopUpState}
        />
      )}
    </>
  );
};

export default CreateNewQuiz;
