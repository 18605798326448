// Library imports
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useLayoutEffect,
} from "react";
import { useMonaco } from "@monaco-editor/react";
import SplitPane, { Pane } from "split-pane-react";
import { throttle } from "lodash";

// Local imports
import ResizeableSash from "../../assets/icons/editor/ResizeableSashIcon";
import { theme } from "./utils/theme";
import "split-pane-react/esm/themes/default.css";
import languageSpecificScript from "./utils/languageSpecificScript";
import RightPane from "./RightPane/RightPane";
import { CodeFile } from "./LeftPane/CodeEditorPane";
import LeftPane from "./LeftPane/LeftPane";
import smoothResize from "./utils/smoothResize";
import { motion } from "framer-motion";

interface CodeEditorProps {
  files: CodeFile[];
  hasPdf: boolean;
  onFileChange: (files: CodeFile[]) => void;
  toShrink: boolean;
  hide: boolean;
}

const CodeEditor: React.FC<CodeEditorProps> = ({files, hasPdf, onFileChange, toShrink, hide}) => {
  /**
   * Paramters
   */
  // Panels size limits
  const minLeftPaneWidth = 110; // minimum left panel width in px
  const minRightPaneWidth = 110; // minimum right panel width in px

  // Animation limits
  const lrScreenPercTrigger = 0.75; // When the "x"% of the left-right pane is achieved, the animation starts

  // Get the half of the window size
  const halfWindowSize = Math.round(window.innerWidth / 2);

  /**
   * CodeEditor Init
   */
  // States definition
  const [activeTab, setActiveTab] = useState<string>(
    files[0]?.name || "default"
  );
  const [activeOutput, setActiveOutput] = useState<string>("Output");
  const [code, setCode] = useState<string>(
    files[0]?.content || "// Write your code here"
  );
  const [language, setLanguage] = useState<string>("txt");
  const [filesState, setFilesState] = useState<CodeFile[]>(files);
  const [leftRightSizes, setLeftRightSizes] = useState([
    halfWindowSize,
    halfWindowSize,
  ]);
  const [srcDoc, setSrcDoc] = useState<string>("");
  const [isResizingOutput, setIsResizingOutput] = useState<boolean>(false);
  const [iframeReloadTrigger, setIframeReloadTrigger] = useState(0);
  const [logs, setLogs] = useState<any[]>([]);
  const [normalisedLeftWidth, setNormalisedLeftWidth] = useState<number>(1);
  const [normalisedRightWidth, setNormalisedRightWidth] = useState<number>(1);
  const [showOutputRunCode, setShowOutputRunCode] = useState(false);
  const [consoleNotification, setConsoleNotification] =
    useState<boolean>(false);
  const [inputConsoleBool, setInputConsoleBool] = useState<boolean>(false);
  // References definition
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const consoleBottomRef = useRef<HTMLDivElement>(null);
  const editorPanelRef = useRef<HTMLDivElement>(null);
  const consolePanelRef = useRef<HTMLDivElement>(null);
  const leftPanelRef = useRef<any>(null); // To define the type of this element
  const rightPanelRef = useRef<HTMLDivElement>(null);
  const editorPanesRef = useRef<HTMLDivElement>(null);
  const [shrink, setShrink] = useState(false);
  const [scaleFactor, setScaleFactor] = useState(1);
  const [scaledRightOffset, setScaledRightOffset] = useState(0);
  const [scaledTopOffset, setScaledTopOffset] = useState(0);

  useLayoutEffect(() => {
    const handleResize = () => {
      const targetWidth = 180;
      const originalWidth = document.body.clientWidth - 96; // 96 is x padding
      const widthScale = targetWidth / originalWidth;
      const targetHeight = 111;
      const originalHeight = document.body.clientHeight - 155; // 155 is top offset
      const heightScale = targetHeight / originalHeight;
      const rightOffset = (targetWidth - (originalWidth * heightScale)) / 2;
      const topOffset = (targetHeight - (originalHeight * widthScale)) / 2;

      if (widthScale < heightScale) {
        setScaleFactor(widthScale);
        setScaledTopOffset(topOffset);
        setScaledRightOffset(0);
      } else {
        setScaleFactor(heightScale);
        setScaledTopOffset(0);
        setScaledRightOffset(rightOffset);
      }
    }
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setShrink(toShrink)
  }, [toShrink]);

  // Monaco editor definition
  const monaco = useMonaco();

  // Get the limit pixel of the animation
  const startAnimPx = Math.round((1 - lrScreenPercTrigger) * window.innerWidth);

  /*
   * throttle console scroll to bottom
   */
  const throttleScroll = throttle(() => {
    // Apply only when left pane is open (otherwise the console opening
    // animation goes wrong), and when we finished moving the pane
    if (normalisedLeftWidth === 1 && !isResizingOutput) {
      consoleBottomRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }, 100); // Execute at most once per 100ms

  /*
   * scroll console to bottom
   */
  useEffect(() => {
    throttleScroll();
    return () => throttleScroll.cancel();
  }, [logs, throttleScroll]);

  /*
   * handle console output from output window
   */
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === "IFRAME_CONSOLE") {
        const { method, args } = event.data;
        const decodedLogs = args
          .map((arg: any[]) =>
            typeof arg === "object" ? JSON.stringify(arg) : arg
          )
          .join(" ");
        // Select the method to use
        if (method === "clear") {
          setLogs([]);
        } else {
          setLogs((logs) => [...logs, { method: method, data: [decodedLogs] }]);
        }
      } else if (event.data.type === "SELECT_INPUT") {
        setInputConsoleBool(true);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  /*
   * catch ctrl + s to run the code
   */
  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (e.ctrlKey && (e.key === "s" || e.keyCode === 13)) {
        e.preventDefault();
        handleRunCode();
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  /*
   * run the client code in the output window
   */
  const handleRunCode = useCallback(() => {
    // setLogs([
    //   ...logs,
    //   { method: "log", data: [`>>>>>> Executing ${activeTab}...`] },
    // ]);
    const srcTemplate = `
        ${
          language === "javascript"
            ? '<script src="/p5.min.js"></script>'
            : '<script src="http://ajax.googleapis.com/ajax/libs/jquery/1.9.0/jquery.min.js" type="text/javascript"></script><script src="/skulpt.min.js" type="text/javascript"></script><script src="/skulpt-stdlib.js" type="text/javascript"></script>'
        }
        <script>
          ${languageSpecificScript(code, language)}
        </script>
        <pre id="output"></pre> 
        <!-- If you want turtle graphics include a canvas -->
        <div id="mycanvas"></div>
    `;
    setSrcDoc(srcTemplate);
    setIframeReloadTrigger((prev) => prev + 1);

    // Set active output tab
    setActiveOutput("Output");
  }, [activeTab, code, language, logs]);

  /**
   * Activate notifications only when there are new logs and the console is closed
   */
  useEffect(() => {
    // If it's closed and we run the code, then show a notification
    if (leftPanelRef.current?.isConsoleClosed()) {
      setConsoleNotification(true);
    }
  }, [logs]);

  /*
   * set monaco theme, stop f1 keybinding, and add ctrl+enter to run code
   */
  useEffect(() => {
    if (monaco) {
      monaco.editor.remeasureFonts();
      monaco.editor.defineTheme("dc", theme);
      monaco.editor.setTheme("dc");
      monaco.editor.addEditorAction({
        id: "stopF1",
        label: "Stop the F1 panel from appearing",
        keybindings: [monaco.KeyCode.F1],
        contextMenuGroupId: "2_execution",
        run: () => {},
      });
      monaco.editor.addEditorAction({
        id: "run-code",
        label: "Run Code",
        contextMenuOrder: 2,
        contextMenuGroupId: "2_execution",
        keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter],
        run: handleRunCode,
      });
    }
  }, [handleRunCode, monaco]);

  /*
   * handle switching code files when new tab selected
   */
  useEffect(() => {
    const file = filesState.find((file) => file.name === activeTab);
    setCode(file ? file.content : "");
    setLanguage(
      activeTab.endsWith(".js")
        ? "javascript"
        : activeTab.endsWith(".py")
        ? "python"
        : "txt"
    );
  }, [activeTab, filesState]);
  /*
   * handle code changes in monaco editor
   */
  const handleEditorChange = useCallback(
    (value: string | undefined) => {
      if (value !== undefined) {
        setFilesState((prevFiles) => {
          const newFiles = prevFiles.map((file) =>
            file.name === activeTab ? { ...file, content: value } : file
          );
          onFileChange(newFiles);
          return newFiles;
        });
        setCode(value);
      }
    },
    [activeTab]
  );

  /*
   * handle adding a new file
   */
  const handleAddFile = useCallback(() => {
    const extension = activeTab.endsWith(".js")
      ? ".js"
      : activeTab.endsWith(".py")
      ? ".py"
      : ".txt";
    const newFileName = `file${filesState.length + 1}` + extension;
    const code =
      extension === ".js"
        ? `// ${newFileName}`
        : extension === ".py"
        ? `# ${newFileName}`
        : newFileName;

    setFilesState((prevFiles) => [
      ...prevFiles,
      { name: newFileName, content: code },
    ]);

    setActiveTab(newFileName);
  }, [activeTab, filesState.length]);

  /*
   * calculate normalised left and right width for close animations
   */
  useEffect(() => {
    setNormalisedLeftWidth(
      Math.min(
        Math.max((leftRightSizes[0] - minLeftPaneWidth) / startAnimPx, 0),
        1
      )
    );
    setNormalisedRightWidth(
      Math.min(
        Math.max((leftRightSizes[1] - minRightPaneWidth) / startAnimPx, 0),
        1
      )
    );
  }, [leftRightSizes]);

  /**
   * Set automatically the pane sizes when the editor is reaching the limit
   */
  const handlePanesResizeWidthEnd = useCallback(() => {
    let leftPaneWidth = editorPanelRef.current?.clientWidth ?? 0;
    let rightPaneWidth = rightPanelRef.current?.clientWidth ?? 0;

    // Aconditionate the numbers
    // 1. To correct from the bias of taking the current values
    // 2. To not exceed the limits
    leftPaneWidth = Math.max(minLeftPaneWidth, leftPaneWidth + 20); // 20 is per the compensation because we're taking the size of the editor
    rightPaneWidth = Math.max(minRightPaneWidth, rightPaneWidth + 20); // 20 is per the compensation because we're taking the size of the editor

    // Create the current size array for the smoothResize function
    let currentSizes = [leftPaneWidth, rightPaneWidth];

    // Left pane logic
    if (
      leftPaneWidth < minLeftPaneWidth + startAnimPx &&
      leftPaneWidth !== minLeftPaneWidth
    ) {
      smoothResize(
        [minLeftPaneWidth, leftPaneWidth + rightPaneWidth - minLeftPaneWidth],
        currentSizes,
        setLeftRightSizes,
        100
      );
    }

    // Right pane logic
    if (
      rightPaneWidth < minRightPaneWidth + startAnimPx &&
      rightPaneWidth !== minRightPaneWidth
    ) {
      smoothResize(
        [leftPaneWidth + rightPaneWidth - minRightPaneWidth, minRightPaneWidth],
        currentSizes,
        setLeftRightSizes,
        100
      );
    }
  }, [leftRightSizes, smoothResize]);

  /**
   * Maximise automatically the right pane when the active output is selected
   */
  useEffect(() => {
    if (activeOutput === "Instructions") {
      let leftPaneWidth = editorPanelRef.current?.clientWidth ?? 0;
      let rightPaneWidth = rightPanelRef.current?.clientWidth ?? 0;

      // Aconditionate the numbers
      // 1. To correct from the bias of taking the current values
      // 2. To not exceed the limits
      leftPaneWidth = Math.max(minLeftPaneWidth, leftPaneWidth + 20); // 20 is per the compensation because we're taking the size of the editor
      rightPaneWidth = Math.max(minRightPaneWidth, rightPaneWidth + 20); // 20 is per the compensation because we're taking the size of the editor

      // Create the current size array for the smoothResize function
      let currentSizes = [leftPaneWidth, rightPaneWidth];

      // Maximize Right pane logic
      smoothResize(
        [minLeftPaneWidth, leftPaneWidth + rightPaneWidth - minLeftPaneWidth],
        currentSizes,
        setLeftRightSizes,
        100
      );
    }
  }, [activeOutput]);

  return (
    <motion.div
      className={`w-screen h-[calc(100vh)] overflow-hidden absolute top-0 ${shrink || hide ? '-z-10' : 'z-10'}`}
      initial={{
        marginTop: 0,
      }}
      animate={{
        marginTop: shrink ? 0 : 0,
      }}
    >
      <motion.div
        className="h-[calc(100vh-155px)] w-full absolute right-0 origin-top-right"
        ref={editorPanesRef}
        initial={{
          height: "calc(100vh-155px)",
          transform: "translateX(0%) scale(1)",
          top: 0,
          right: 0,
          opacity: 1,
        }}
        animate={{
          transform: shrink ? `translateX(0%) scale(${scaleFactor*0.98})` : `translateX(0%) scale(1)`,
          top: hide ? "100%" : shrink ? 24 + scaledTopOffset : 0,
          right: shrink ? 48 + scaledRightOffset : 0,
          opacity: hide ? 0: 1,
          transition: {ease: "linear"},
        }}
      >
        <SplitPane
          className={`relative`}
          split="vertical"
          sizes={leftRightSizes}
          onChange={setLeftRightSizes}
          resizerSize={40}
          onDragStart={() => {
            setIsResizingOutput(true);
          }}
          onDragEnd={() => {
            setIsResizingOutput(false);
            handlePanesResizeWidthEnd();
          }}
          sashRender={() => (
            <div className="flex h-full items-center">
              <ResizeableSash />
            </div>
          )}
        >
          <Pane
            minSize={minLeftPaneWidth}
            maxSize="100%"
            className="w-full h-full pl-0 pr-5 pb-8 overflow-visible"
          >
            <LeftPane
              filesState={filesState}
              activeTab={activeTab}
              language={language}
              code={code}
              logs={logs}
              consoleNotification={consoleNotification}
              editorPanelRef={editorPanelRef}
              consolePanelRef={consolePanelRef}
              consoleBottomRef={consoleBottomRef}
              normalisedLeftWidth={normalisedLeftWidth}
              handleAddFile={handleAddFile}
              handleRunCode={handleRunCode}
              handleEditorChange={handleEditorChange}
              setActiveTab={setActiveTab}
              setFilesState={setFilesState}
              setConsoleNotification={setConsoleNotification}
              setLogs={setLogs}
              ref={leftPanelRef}
              leftRightSizes={leftRightSizes}
              inputConsoleBool={inputConsoleBool}
              setInputConsoleBool={setInputConsoleBool}
              setShowOutputRunCode={setShowOutputRunCode}
            />
          </Pane>
          <Pane
            minSize={minRightPaneWidth}
            maxSize="100%"
            className="w-full h-full pl-5 pr-0 pb-8 overflow-visible"
          >
            <RightPane
              normalisedRightWidth={normalisedRightWidth}
              showOutputRunCode={showOutputRunCode}
              setActiveOutput={setActiveOutput}
              activeOutput={activeOutput}
              srcDoc={srcDoc}
              iframeRef={iframeRef}
              hasPdf={hasPdf}
              iframeReloadTrigger={iframeReloadTrigger}
              handleRunCode={handleRunCode}
              isResizingOutput={isResizingOutput}
              rightPanelRef={rightPanelRef}
            />
          </Pane>
        </SplitPane>
      </motion.div>
    </motion.div>
  );
};

export default CodeEditor;
