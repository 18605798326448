/*
 * smooth resize the console size when closing either console or editor
 */
const smoothResize = (
  targetSizes: number[],
  currentSizes: number[],
  setStateSizes: (value: React.SetStateAction<number[]>) => void,
  duration = 100
) => {
  const startTime = performance.now();
  var initialSizes: number[];
  initialSizes = [...currentSizes];

  const updateSizes = (currentTime: number) => {
    const elapsedTime = currentTime - startTime;
    const fraction = Math.min(elapsedTime / duration, 1);

    const newSizes = initialSizes.map((size, index) => {
      const distance = targetSizes[index] - size;
      return size + distance * fraction;
    });

    setStateSizes(newSizes);

    if (fraction < 1) {
      requestAnimationFrame(updateSizes); // Continue the animation
    }
  };
  requestAnimationFrame(updateSizes); // Start the animation
};

export default smoothResize;
