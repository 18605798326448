import { Link } from "react-router-dom";
import {
  ReactElement,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import DashboardHeaderOptions from "./DashboardHeaderOptions";
import CustomersHeaderOptions from "./CustomersHeaderOptions";
import ClassesHeaderOptions from "./ClassesHeaderOptions";
import LessonsHeaderOptions from "./LessonsHeaderOptions";
import AnimatedBackButton from "../components/AnimatedBackButton";

interface MainHeaderProps {
  userRole: string;
  activeItem: string;
  backPressedToggle: boolean;
  setBackPressedToggle: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSideBarMenu: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveItem: React.Dispatch<React.SetStateAction<string>>;
  lessonsRef: React.MutableRefObject<any>;
  classesRef: React.MutableRefObject<any>;
  customersRef: React.MutableRefObject<any>;
  dashboardRef: React.MutableRefObject<any>;
  quizzesRef: React.MutableRefObject<any>;
  subHeaderRef: React.MutableRefObject<any>;
  ref: any;
}

const MainHeader: React.FC<MainHeaderProps> = forwardRef(
  (
    {
      userRole,
      activeItem,
      backPressedToggle,
      setBackPressedToggle,
      setShowSideBarMenu,
      setActiveItem,
      lessonsRef,
      classesRef,
      customersRef,
      dashboardRef,
      quizzesRef,
      subHeaderRef,
    },
    ref
  ) => {
    // States definition
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState<ReactElement | string>("");
    const [showBackButton, setShowBackButton] = useState(false);

    // References definition
    const dashboardHeaderRef: React.Ref<any> = useRef();
    const lessonsHeaderRef = useRef();
    const classesHeaderRef = useRef();
    const customersHeaderRef = useRef();

    /**
     * Define the list of functions that can be used from external components
     * using a reference
     */
    useImperativeHandle(ref, () => ({
      // Outer function to change the tab
      setHeaderTitle(title: string) {
        setTitle(title);
      },
      setHeaderDescription(description: string) {
        setDescription(description);
      },
      setHeaderBackButton(bool: boolean) {
        setShowBackButton(bool);
      },
      setShowSideBarMenu(bool: boolean) {
        setShowSideBarMenu(bool);
      },
      setShowBackButton(bool: boolean) {
        setShowBackButton(bool);
      },
      lessons() {
        return lessonsHeaderRef.current;
      },
      classes() {
        return classesHeaderRef.current;
      },
      customers() {
        return customersHeaderRef.current;
      },
      dashboard() {
        return dashboardHeaderRef.current;
      },
    }));

    /**
     * Function to execute on back button click
     */
    const onBackButtonClick = () => {
      // First of all, send a signal
      setBackPressedToggle(!backPressedToggle);

      // If lessonsRef is activated
      if (lessonsRef.current) lessonsRef.current.list().onBackButtonClick();
      if (dashboardRef.current) dashboardHeaderRef.current.onDiscard();
    }

    useEffect(() => {
      let currentHandler: any;
      if (dashboardRef.current && dashboardHeaderRef.current) currentHandler = dashboardRef.current;
      else return;
      if (!currentHandler.student()) return;
      if (dashboardHeaderRef) {
        dashboardHeaderRef.current.setFirstName(currentHandler.student().getFirstName())
        dashboardHeaderRef.current.setLastName(currentHandler.student().getLastName())
      }
    }, [dashboardRef, dashboardHeaderRef])

    return (
      <div className="flex flex-col gap-0">
        <div className="w-full h-24 justify-between items-center inline-flex">
          <div className="grow shrink basis-0 justify-start items-center gap-12 flex">
            <Link to="/">
              <img className="w-[85.57px] h-14" src={"/logo.svg"} />
            </Link>
            <div className="w-px self-stretch justify-start items-start gap-2 flex">
              <div className="w-px self-stretch bg-neutral-200" />
            </div>
            <div className="grow shrink basis-0 h-12 justify-start items-center gap-3 flex">
              <AnimatedBackButton
                show={showBackButton}
                onClick={onBackButtonClick}
              />
              <div className="grow flex-col justify-center items-start gap-0.5 inline-flex">
                <div className="text-neutral-600 text-[23px] font-bold font-sans leading-7">
                  {title}
                </div>
                <div className="text-neutral-500 text-sm font-normal font-sans leading-[17px] whitespace-nowrap">
                  {description}
                </div>
              </div>
            </div>
          </div>
          {activeItem === "dashboard" && (
            <DashboardHeaderOptions
              ref={dashboardHeaderRef}
              userRole={userRole}
              setActiveItem={setActiveItem}
              setShowSideBarMenu={setShowSideBarMenu}
              setTitle={setTitle}
              setDescription={setDescription}
              setShowBackButton={setShowBackButton}
              dashboardRef={dashboardRef}
              customersRef={customersRef}
              subHeaderRef={subHeaderRef}
            />
          )}
          {activeItem === "school-options" && (
            <CustomersHeaderOptions
              ref={customersHeaderRef}
              userRole={userRole}
              setActiveItem={setActiveItem}
              setShowSideBarMenu={setShowSideBarMenu}
              setTitle={setTitle}
              setDescription={setDescription}
              setShowBackButton={setShowBackButton}
              customersRef={customersRef}
            />
          )}
          {["classes", "users"].includes(activeItem) && (
            <ClassesHeaderOptions
              ref={classesHeaderRef}
              userRole={userRole}
              setShowSideBarMenu={setShowSideBarMenu}
              setTitle={setTitle}
              setDescription={setDescription}
              setShowBackButton={setShowBackButton}
              classesRef={classesRef}
            />
          )}
          {activeItem === "lessons" && (
            <LessonsHeaderOptions
              ref={lessonsHeaderRef}
              userRole={userRole}
              setShowSideBarMenu={setShowSideBarMenu}
              setTitle={setTitle}
              setDescription={setDescription}
              setShowBackButton={setShowBackButton}
              lessonsRef={lessonsRef}
            />
          )}
        </div>
      </div>
    );
  }
);

export default MainHeader;
