import React, { useEffect, useRef, useState } from "react";
import { MdArrowDropDown, MdOutlineArrowCircleRight } from "react-icons/md";
import PopUpPanel from "../pop-up-panels/PopUpPanel";

interface BasicDetailsProps {
  firstName: string;
  middleName: string;
  lastName: string;
  dob: string;
  gender: string;
  showGenderPopUp: boolean;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  setMiddleName: React.Dispatch<React.SetStateAction<string>>;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  setDob: React.Dispatch<React.SetStateAction<string>>;
  setGender: React.Dispatch<React.SetStateAction<string>>;
  setStage: React.Dispatch<React.SetStateAction<number>>;
  setShowGenderPopUp: React.Dispatch<React.SetStateAction<boolean>>;
}

const BasicDetails: React.FC<BasicDetailsProps> = ({
  firstName,
  middleName,
  lastName,
  dob,
  gender,
  showGenderPopUp,
  setFirstName,
  setMiddleName,
  setLastName,
  setDob,
  setGender,
  setStage,
  setShowGenderPopUp
}) => {
  // States definition
  const [nextAvailable, setNextAvailable] = useState(true);

  // Reference for the date input
  const dateInputRef = useRef<HTMLInputElement>(null);

  const handleClickDate = () => {
    if (dateInputRef.current)
      dateInputRef.current.showPicker();
  };

  /**
   * Condition to activate the next button
   */
  useEffect(() => {
    const condition = !!(firstName && lastName && dob && gender);
    setNextAvailable(condition);
  }, [firstName, lastName, dob, gender]);

  /**
   * Go to the second stage
   */
  const handleNext = () => {
    if (nextAvailable) setStage(2);
  };

  return (
    <div className="w-[562px] self-stretch p-6 rounded-3xl justify-start items-start flex">
      <div className="w-[514px] flex-col justify-start items-start gap-12 inline-flex">
        <div className="self-stretch h-fit flex-col justify-start items-start flex text-neutral-700 text-[23px] font-semibold font-sans leading-7">
          Basic details
        </div>
        <div className="self-stretch h-fit flex-col justify-start items-start gap-5 flex">
          <div className="self-stretch h-fit flex-col justify-start items-start gap-1.5 flex">
            <div className="rounded justify-start items-start gap-2 inline-flex text-neutral-500 text-xs font-sans uppercase leading-[14px] tracking-wide">
              First Name*
            </div>
            <div className="self-stretch  bg-neutral-50 rounded-lg justify-start items-center">
              <input
                type="text"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
                className="w-full h-full p-3 text-neutral-700 placeholder:text-neutral-300 text-base font-sans leading-[19px] bg-transparent border-none focus:outline-none"
                placeholder="Enter first name"
              />
            </div>
          </div>
          <div className="self-stretch h-fit flex-col justify-start items-start gap-1.5 flex">
            <div className="rounded justify-start items-start gap-2 inline-flex text-neutral-500 text-xs font-sans uppercase leading-[14px] tracking-wide">
              Middle name (optional)
            </div>
            <div className="self-stretch  bg-neutral-50 rounded-lg justify-start items-center">
              <input
                type="text"
                value={middleName}
                onChange={(event) => setMiddleName(event.target.value)}
                className="w-full h-full p-3 text-neutral-700 placeholder:text-neutral-300 text-base font-sans leading-[19px] bg-transparent border-none focus:outline-none"
                placeholder="Enter middle name"
              />
            </div>
          </div>
          <div className="self-stretch h-fit flex-col justify-start items-start gap-1.5 flex">
            <div className="rounded justify-start items-start gap-2 inline-flex text-neutral-500 text-xs font-sans uppercase leading-[14px] tracking-wide">
              Last Name*
            </div>
            <div className="self-stretch bg-neutral-50 rounded-lg justify-start items-center">
              <input
                type="text"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
                className="w-full h-full p-3 text-neutral-700 placeholder:text-neutral-300 text-base font-sans leading-[19px] bg-transparent border-none focus:outline-none"
                placeholder="Enter last name"
              />
            </div>
          </div>
        </div>
        <div className="w-full h-fit flex-col justify-start items-start gap-5 flex">
          <div className="self-stretch h-fit flex-col justify-start items-start gap-1.5 flex">
            <div className="rounded justify-start items-start gap-2 inline-flex text-neutral-500 text-xs font-sans uppercase leading-[14px] tracking-wide">
              Date of Birth*
            </div>
            <div className="self-stretch bg-neutral-50 rounded-lg justify-start items-center">
              <input
                ref={dateInputRef}
                type="date"
                value={dob}
                onChange={(event) => setDob(event.target.value)}
                onClick={handleClickDate}
                className={
                  dob
                    ? "w-full h-full p-3 text-neutral-700 text-base font-sans leading-[19px] bg-transparent border-none focus:outline-none cursor-pointer"
                    : "w-full h-full p-3 text-neutral-300 text-base font-sans leading-[19px] bg-transparent border-none focus:outline-none cursor-pointer"
                }
              />
            </div>
          </div>
          <div className="self-stretch h-fit flex-col justify-start items-start gap-1.5 flex">
            <div className="rounded justify-start items-start gap-2 inline-flex text-neutral-500 text-xs font-sans uppercase leading-[14px] tracking-wide">
              Gender*
            </div>
            <div
              className="self-stretch p-3 bg-neutral-50 rounded-lg justify-start items-center gap-2 inline-flex cursor-pointer"
              onClick={() => setShowGenderPopUp(true)}
            >
              <div className="grow shrink basis-0 h-[27px] py-1 justify-start items-start gap-2 flex">
                <div
                  className={
                    gender
                      ? "grow shrink basis-0 h-[19px] text-neutral-700 text-base font-normal font-sans leading-[19px]"
                      : "grow shrink basis-0 h-[19px] text-neutral-300 text-base font-normal font-sans leading-[19px]"
                  }
                >
                  {gender
                    ? gender
                    : "Select an option"}
                </div>
              </div>
              <div className="w-5 h-5 relative">
                <MdArrowDropDown className="w-full h-full fill-neutral-600" />
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-[46px] self-stretch justify-between items-center inline-flex">
          <div className="opacity-0 rounded justify-center items-center gap-2 flex" />
          <div
            className="rounded justify-center items-center gap-2 flex cursor-pointer"
            onClick={handleNext}
          >
            <div
              className={
                nextAvailable
                  ? "text-dc-secondary-600 text-lg font-semibold font-sans leading-snug"
                  : "text-stone-300 text-lg font-semibold font-sans leading-snug"
              }
            >
              Next
            </div>
            <div className="w-6 h-6 relative">
              <MdOutlineArrowCircleRight
                className={
                  nextAvailable
                    ? "w-full h-full fill-dc-secondary-600"
                    : "w-full h-full fill-stone-300"
                }
              />
            </div>
          </div>
        </div>
      </div>
      {/* Pop up panels */}
      <PopUpPanel
          type="gender"
          showPopUp={showGenderPopUp}
          setShowPopUp={setShowGenderPopUp}
          setOption={setGender}
      />
    </div>
  );
};

export default BasicDetails;
