import { GoArrowLeft } from "react-icons/go";
import { MdAddCircleOutline } from "react-icons/md";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { QuizListType } from "utils/interfaces";
import { getCollectionRequest } from "services/apiRequests";
import QuizCheckbox from "./QuizCheckbox";
import CreateNewQuiz from "./CreateNewQuiz";
import EmptyViewPopUp from "components/super-admin/utils/pop-up-panels/EmptyViewPopUp";
import WarningPopUpMessage from "components/super-admin/utils/pop-up-panels/WarningPopUpMessage";

interface QuizPanelProps {
  ref?: any;
  selectedData?: QuizListType | null;
  selectedQuizzes: QuizListType[];
  showWarningMessage?: boolean;
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedQuizzes: React.Dispatch<React.SetStateAction<QuizListType[]>>;
  setShowWarningMessage?: React.Dispatch<React.SetStateAction<boolean>>;
}

const QuizPanel: React.FC<QuizPanelProps> = forwardRef(
  (
    {
      selectedData,
      selectedQuizzes,
      showWarningMessage = false,
      setShowPopUp,
      setSelectedQuizzes,
      setShowWarningMessage,
    },
    ref
  ) => {
    // States definition
    const [quizData, setQuizData] = useState<QuizListType[]>([]);
    const [popUpState, setPopUpState] = useState<
      "main" | "create-new" | "add-question" | "edit-question"
    >(selectedData ? "create-new" : "main");
    const [selectedQuizDataIds, setSelectedQuizDataIds] = useState<string[]>(
      []
    );
    const [activateConfirmChanges, setActivateConfirmChanges] = useState(false);

    /**
     * Define the list of functions that can be used from external components
     * using a reference
     */
    useImperativeHandle(ref, () => ({
      getPopUpState() {
        return popUpState;
      },
    }));

    /**
     * Definition of the function when pressed back button
     */
    const handlePressBack = () => {
      // If create new was pressed, then come back to the regular option
      if (["add-question", "edit-question"].includes(popUpState)) {
        setPopUpState("create-new");
      } else if (popUpState === "create-new") {
        // If we are just editing, then close pop up from teacher
        if (selectedData) setShowPopUp(false);
        // Otherwise, just come back
        else setPopUpState("main");
      }
      // Otherwise, close the pop up
      else setShowPopUp(false);
    };

    /**
     * Add the new selected data to the current one
     */
    const handleConfirmChange = () => {
      // Define the new quizzes
      const newQuizzes = quizData.filter((record: QuizListType) =>
        selectedQuizDataIds.includes(record._id)
      );

      // And set the new quizzes to state
      setSelectedQuizzes(newQuizzes);

      // And close the pop-up
      setShowPopUp(false);
    };

    /**
     * Get the quizzes
     * @returns data
     */
    const getData = async () => {
      // Get the data from the database record
      const data = await getCollectionRequest("/api/quizzes");
      return data;
    };

    /**
     * Load quiz data when init
     */
    useEffect(() => {
      // Get the data
      getData().then((data) => {
        // Set the quiz data
        setQuizData(data);

        // Define the current selected ids
        setSelectedQuizDataIds(
          selectedQuizzes.map((quiz: QuizListType) => quiz._id)
        );
      });
    }, []);

    /**
     * Definition of the function executed after creating a quiz
     * in the database
     */
    const afterCreateQuiz = () => {
      // If we are editing, come back to the regular view (depending on the justCreateNew option)
      if (selectedData) {
        // Update the selected data
        getData().then((data) => {
          setQuizData(data);

          // Define the new quizzes
          const newQuizzes = data.filter((record: QuizListType) =>
            selectedQuizDataIds.includes(record._id)
          );

          // And set the new quizzes to state
          setSelectedQuizzes(newQuizzes);

          // And close the pop-up
          setShowPopUp(false);
        });
      } else {
        // Update the data
        getData().then((data) => {
          setQuizData(data);
        });
        // And come back to the main view
        setPopUpState("main");
      }
    };

    /**
     * Timer for the warning pop up message
     */
    useEffect(() => {
      if (showWarningMessage) {
        // Starting the timer
        const timer = setTimeout(() => {
          // Once finished, close the pop up
          if (setShowWarningMessage) setShowWarningMessage(false);
        }, 5000);
        return () => clearTimeout(timer);
      }
    }, [showWarningMessage]);

    return (
      <div className="relative w-full h-fit max-h-[80vh] flex-col justify-start items-start gap-6 inline-flex">
        <div className="self-stretch justify-between items-center inline-flex">
          <div className="justify-start items-center gap-3 flex">
            <div
              className="p-1.5 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex cursor-pointer"
              onClick={handlePressBack}
            >
              <div className="w-5 h-5 justify-center items-center flex">
                <div className="w-5 h-5 relative">
                  <GoArrowLeft className="w-full h-full fill-dc-secondary-600" />
                </div>
              </div>
            </div>
            <div className="text-neutral-600 text-lg font-normal font-['Poppins'] leading-snug">
              {popUpState === "main"
                ? "Add/Remove Quizzes"
                : popUpState === "create-new"
                ? selectedData
                  ? "Edit Quiz"
                  : "Create new Quiz"
                : popUpState === "add-question"
                ? "Add Question"
                : "Edit Question"}
            </div>
          </div>
          <div className="p-2 opacity-0 bg-neutral-50 rounded-full justify-start items-start gap-2 flex">
            <div className="w-4 h-4 p-[3.61px] justify-center items-center flex" />
          </div>
          {/* Hide create new button when creating parent */}
          {popUpState === "main" && (
            <div
              className="rounded justify-center items-center gap-2 flex cursor-pointer"
              onClick={() => setPopUpState("create-new")}
            >
              <div className="w-6 h-6 relative">
                <MdAddCircleOutline className="w-full h-full fill-dc-secondary-600" />
              </div>
              <div className="text-dc-secondary-600 text-lg font-semibold font-['Poppins'] leading-snug">
                Create new
              </div>
            </div>
          )}
        </div>
        {popUpState === "main" && (
          <>
            <div className="self-stretch justify-start items-start gap-[5px] inline-flex overflow-y-auto pop-up-scrollbar">
              <div className="w-full flex-col justify-start items-start gap-4 inline-flex">
                {quizData.length > 0 ? (
                  quizData.map((quiz, index) => (
                    <QuizCheckbox
                      key={index}
                      quiz={quiz}
                      selectedQuizDataIds={selectedQuizDataIds}
                      setSelectedQuizDataIds={setSelectedQuizDataIds}
                      setActivateConfirmChanges={setActivateConfirmChanges}
                    />
                  ))
                ) : (
                  <EmptyViewPopUp />
                )}
              </div>
            </div>
            {quizData.length > 0 && (
              <>
                {activateConfirmChanges ? (
                  <div
                    className="self-stretch px-5 py-3 bg-dc-secondary-600 rounded justify-center items-center gap-2 inline-flex cursor-pointer"
                    onClick={handleConfirmChange}
                  >
                    <div className="text-white text-lg font-semibold font-['Poppins'] leading-snug">
                      Confirm Changes
                    </div>
                  </div>
                ) : (
                  <div className="self-stretch px-5 py-3 bg-Subtle rounded justify-center items-center gap-2 inline-flex cursor-pointer">
                    <div className="text-neutral-400 text-lg font-semibold font-['Poppins'] leading-snug">
                      Confirm Changes
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
        {popUpState !== "main" && (
          <CreateNewQuiz
            selectedData={selectedData}
            popUpState={popUpState}
            setPopUpState={setPopUpState}
            afterCreateFunction={afterCreateQuiz}
          />
        )}
        {setShowWarningMessage && (
          <WarningPopUpMessage
            showWarningMessage={showWarningMessage}
            setShowWarningMessage={setShowWarningMessage}
          />
        )}
      </div>
    );
  }
);

export default QuizPanel;
