import React from "react";
import { MdDeleteOutline } from "react-icons/md";

interface PopUpDeleteFileType {
  handlePopOutDeleteOption: () => void;
  handleDeleteFile: () => void;
}

const PopUpDeleteFile: React.FC<PopUpDeleteFileType> = ({
  handlePopOutDeleteOption,
  handleDeleteFile,
}) => {
  return (
    <div
      className="relative m-auto w-[467px] h-[218px] p-8 bg-white rounded-3xl flex-col justify-start items-center gap-[29px] inline-flex"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="w-[403px] justify-center items-center gap-2 inline-flex">
        <div className="grow shrink basis-0 text-zinc-800 text-lg font-semibold font-sans leading-snug">
          Confirm file deletion
        </div>
      </div>
      <div className="w-[403px] justify-center items-center gap-2 inline-flex">
        <div className="grow shrink basis-0 text-zinc-800 text-base font-normal font-sans leading-7">
          This action cannot be undone.
        </div>
      </div>
      <div className="self-stretch justify-end items-center gap-5 inline-flex">
        <div
          className="text-zinc-800 text-base font-semibold font-sans leading-7 cursor-pointer px-3 py-2"
          onClick={handlePopOutDeleteOption}
        >
          Keep File
        </div>
        <div
          className="h-[45px] px-3 py-2 bg-red-600 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex cursor-pointer"
          onClick={handleDeleteFile}
        >
          <div className="w-6 h-6 relative">
            <MdDeleteOutline className="w-6 h-6 text-white" />
          </div>
          <div className="text-white text-base font-semibold font-sans leading-7">
            Delete File
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUpDeleteFile;
