import { MdCheckCircle } from "react-icons/md";

interface SavedSuccessfulPanelProps {
  successfulType: "student" | "school" | "class";
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  pressCreateAnother?: () => void
  headerRef?: React.MutableRefObject<any>;
}

const SavedSuccessfulPanel: React.FC<SavedSuccessfulPanelProps> = ({
  successfulType,
  setShowPopUp,
  pressCreateAnother,
  headerRef,
}) => {
  // Function for "Create another" option
  const handleCreateAnother = () => {
    
    console.log("paso pre");
    // Check if the header reference is included
    if (!headerRef) return;
    
    console.log("paso");

    // Redirect to create student again
    if (successfulType === "student") {
      headerRef.current.classes().pressCreateNewStudent();
    } else if (successfulType === "school") {
      headerRef.current.customers().pressCreateNewSchool();
    } else if (successfulType === "class") {
      console.log("paso");
      if (pressCreateAnother) pressCreateAnother();
      return;
    }
    // And close the pop up
    setShowPopUp(false);
  };

  return (
    <>
      <div className="self-stretch px-3.5 py-9 justify-between items-start inline-flex">
        <div className="w-8 h-8 p-[7.21px] opacity-0 justify-center items-center flex" />
        <div className="w-[108px] h-[108px] relative">
          <MdCheckCircle className="w-full h-full fill-success-500" />
        </div>
        <div className="w-8 h-8 p-[7.21px] opacity-0 justify-center items-center flex" />
      </div>
      <div className="w-full h-fit flex-col justify-start items-start gap-6 flex">
        <div className="self-stretch h-fit flex-col justify-start items-start gap-6 flex">
          <div className="self-stretch h-7 flex-col justify-start items-start gap-3 flex">
            <div className="self-stretch justify-start items-start gap-2 inline-flex">
              <div className="grow shrink basis-0 text-stone-700 text-[23px] font-semibold font-sans leading-7">
                {successfulType === "student"
                  ? "Student Added Successfully"
                  : successfulType === "school"
                  ? "School Added Successfully "
                  : successfulType === "class"
                  ? "Class created Successfully"
                  : ""}
              </div>
            </div>
          </div>
          <div className="self-stretch justify-start items-start gap-2 inline-flex">
            <div className="grow shrink basis-0 text-stone-700 text-base font-normal font-sans leading-7">
              {successfulType === "student"
                ? "The new student has been successfully added to the system."
                : successfulType === "school"
                ? "The new school has been successfully added to the system."
                : successfulType === "class"
                ? "The new class has been successfully created."
                : ""}
            </div>
          </div>
          {successfulType !== "class" && (
            <div className="self-stretch justify-start items-start gap-2 inline-flex">
              <div className="grow shrink basis-0 text-stone-700 text-base font-normal font-sans leading-7">
                {successfulType === "student"
                  ? "If you wish to add another student, click the 'Add Another' button below."
                  : successfulType === "school"
                  ? "Feel free to dive in to start customizing the school's profile, setting up classes, and inviting staff, students, and parents to join the platform."
                  : ""}
              </div>
            </div>
          )}
        </div>
        <div
          className="self-stretch h-[46px] px-5 py-3 bg-dc-secondary-600 rounded justify-center items-center gap-2 inline-flex cursor-pointer"
          onClick={handleCreateAnother}
        >
          <div className="text-white text-lg font-semibold font-sans leading-snug">
            {["student", "school"].includes(successfulType)
              ? "Add another"
              : "Create another"}
          </div>
        </div>
        <div className="self-stretch h-[46px] px-5 py-3 rounded justify-center items-center gap-2 inline-flex">
          <div
            className="text-dc-secondary-600 text-lg font-semibold font-sans leading-snug cursor-pointer"
            onClick={() => setShowPopUp(false)}
          >
            Close
          </div>
        </div>
      </div>
    </>
  );
};

export default SavedSuccessfulPanel;
