import LoginForm from "./LoginForm";
import backgroundImg from "assets/imgs/uluru.jpeg";
import windowsLogo from "assets/imgs/windowsLogo.svg";
import { FcGoogle } from "react-icons/fc";
import { getOAuthLogin } from "services/loginRequests";
import { externalNavigate } from "utils/externalNavigate";
import { Link } from "react-router-dom";
import { MdLogin } from "react-icons/md";

const Login: React.FC = () => {
  const handleGoogleLogin = async () => {
    let loginUrl = await getOAuthLogin("google");
    externalNavigate(loginUrl);
  };

  const handleMicrosoftLogin = async () => {
    let loginUrl = await getOAuthLogin("microsoft");
    externalNavigate(loginUrl);
  };

  return (
    <div className="w-full h-full relative">
      <div className="fixed w-full h-screen min-w-[1436px] z-0">
        <img
          className="absolute grow basis-0 size-full flex z-0 resize-none"
          src={backgroundImg}
        />
      </div>
      <div className="w-full h-full p-14 z-10">
        <div className="w-full h-full min-w-[1276px] p-20 bg-neutral-50 bg-opacity-70 rounded-2xl shadow backdrop-blur-[32px] justify-start items-center gap-24 inline-flex">
          <div className="grow shrink basis-0 self-stretch flex-col justify-start items-center gap-10 inline-flex">
            <div className="self-stretch justify-start items-center inline-flex">
              <Link className="w-fit h-fit p-2 rounded-xl bg-neutral-50" to="/">
                <img className="h-[101px]" src={"/logo.svg"} />
              </Link>
            </div>
            <div className="self-stretch text-neutral-700 text-base font-normal font-sans leading-7">
              Deadly Coders is a community-managed not-for-profit on a mission
              to ignite learning and career pathways in STEM to Aboriginal and
              Torres Straight Islander primary and secondary students across
              Australia. We provide a culturally safe, connected space for
              learning and creativity in digital technologies – our fully
              subsidised  programs include coding, robotics and digital
              technologies curriculum.
            </div>
          </div>
          <div className="w-px self-stretch justify-start items-start gap-2 flex">
            <div className="w-px self-stretch bg-white" />
          </div>
          <div className="grow shrink basis-0 flex-col justify-center items-center gap-8 inline-flex">
            <div className="self-stretch h-[79px] flex-col justify-start items-start gap-6 flex">
              <div className="self-stretch justify-start items-center gap-2 inline-flex">
                <div className="grow shrink basis-0 text-neutral-700 text-3xl font-semibold font-sans leading-[34px]">
                  Sign In to your account
                </div>
              </div>
              <div className="self-stretch justify-start items-center gap-2 inline-flex">
                <div className="grow shrink basis-0 text-neutral-700 text-base font-normal font-sans leading-[21px]">
                  Welcome back! Sign in to access your account
                </div>
              </div>
            </div>
            <LoginForm />
            <div className="self-stretch justify-center items-center gap-4 inline-flex">
              <hr className="flex-1 border-stone-700" />
              <span className="flex-shrink mx-4 text-stone-700 uppercase">
                or
              </span>
              <hr className="flex-1 border-stone-700" />
            </div>
            <div className="self-stretch h-[100px] flex-col justify-start items-center gap-3 inline-flex">
              <div className="self-stretch text-center text-neutral-700 text-sm font-semibold font-sans leading-[17px]">
                Sign in with
              </div>
              <div className="self-stretch px-28 justify-between items-start inline-flex">
                <div className="flex-col justify-start items-start gap-1 inline-flex">
                  <div className="self-stretch px-5 py-3 rounded border-2 border-dc-secondary-600 justify-center items-center gap-2 inline-flex cursor-pointer" onClick={handleMicrosoftLogin}>
                    <img src={windowsLogo} className="w-6 h-6" />
                  </div>
                  <div className="self-stretch h-[19px] text-center text-neutral-700 text-xs font-semibold font-sans leading-[14.40px]">
                    Microsoft
                  </div>
                </div>
                <div className="flex-col justify-start items-start gap-1 inline-flex">
                  <div className="self-stretch px-5 py-3 rounded border-2 border-dc-secondary-600 justify-center items-center gap-2 inline-flex cursor-pointer" onClick={handleGoogleLogin}>
                    <div className="w-6 h-6 relative">
                      <FcGoogle className="w-full h-full" />
                    </div>
                  </div>
                  <div className="self-stretch h-[19px] text-center text-neutral-700 text-xs font-semibold font-sans leading-[14.40px]">
                    Google
                  </div>
                </div>
                <div className="flex-col justify-start items-start gap-1 inline-flex">
                  <div className="self-stretch px-5 py-3 rounded border-2 border-dc-secondary-600 justify-center items-center gap-2 inline-flex cursor-pointer">
                    <div className="w-6 h-6 relative">
                      <MdLogin className="w-full h-full fill-neutral-700" />
                    </div>
                  </div>
                  <div className="self-stretch h-[19px] text-center text-neutral-700 text-xs font-semibold font-sans leading-[14.40px] cursor-pointer">
                    SSO
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full justify-start items-start gap-2 inline-flex">
              <div className="text-neutral-700 text-sm font-normal font-sans leading-[17px]">
                Don’t have an account?
              </div>
              <Link to="/how-to-sign-up">
                <div className="text-neutral-700 text-sm font-semibold font-sans underline leading-[17px]">
                  Find out how to sign up
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
