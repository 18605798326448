import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import ViewHeader from "components/super-admin/utils/view-header/ViewHeader";
import CustomersList from "./CustomersList";
import { SchoolListType } from "utils/interfaces";
import AddNewSchool from "./add-new-school-view/AddNewSchool";
import PopUpPanel from "./pop-up-panels/PopUpPanel";
import CustomerAdminDashboard from "../dashboard/customer-admin/CustomerAdminDashboard";

interface CustomersProps {
  ref: any;
  userRole: string;
  backPressedToggle: boolean;
  headerRef: React.MutableRefObject<any>;
  subHeaderRef: React.MutableRefObject<any>;
  customerAdminDashboardRef?: React.MutableRefObject<any>;
  from?: "main" | "dashboard";
}

const Customers: React.FC<CustomersProps> = forwardRef(
  (
    {
      userRole,
      backPressedToggle,
      headerRef,
      subHeaderRef,
      customerAdminDashboardRef,
      from = "dashboard"
    },
    ref
  ) => {
    // States definition
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedData, setSelectedData] = useState<SchoolListType | null>(
      null
    );
    const [showSavedSuccessfully, setShowSavedSuccessfully] = useState(false);
    const [display, setDisplay] = useState<"main" | "create-edit" | "panel">(
      "main"
    );
    const [createSchool, setCreateSchool] = useState(false);

    // References definition
    const customersListRef = useRef(null);
    // Add customer reference definition
    const addCustomerRef = useRef(null);

    /**
     * Define the list of functions that can be used from external components
     * using a reference
     */
    useImperativeHandle(ref, () => ({
      setCreateSchool(bool: boolean) {
        setDisplay(bool ? "create-edit" : "main");
      },
      pressSaveButton() {
        let currentHandler: any;
        if (addCustomerRef.current) currentHandler = addCustomerRef.current;
        currentHandler.handleSaveSchool();
      },
      setSelectedData(data: SchoolListType | null) {
        setSelectedData(data);
      },
      showSchool(id: string, currentActiveItem: string) {
        let currentHandler: any;
        if (customersListRef.current) currentHandler = customersListRef.current;
        currentHandler.setSchoolToShow(id, currentActiveItem);
      },
    }));

    /**
     * Reference to get data
     */
    const getData = async () => {
      if (customersListRef.current)
        await (customersListRef.current as any).getData();
    };

    /**
     * Everytime the backPressedToggle signal is activated, we come back to the root view
     */
    useEffect(() => {
      let currentHandler;
      if (from === "dashboard"){
        currentHandler = headerRef.current.dashboard();
        // Force to come back to the customers view
        currentHandler.setCreateTypeMasterAdmin("customers");
      } else {
        currentHandler = headerRef.current.customers();
      }
      // Once pressed, come back to the root view
      currentHandler.onDiscard();

      // Define the tab title and description once opened
      headerRef.current.setHeaderTitle("Schools");
      headerRef.current.setHeaderDescription(`List of current schools`);

      // And force unselect data
      setSelectedData(null);
    }, [backPressedToggle]);

    return (
      <div className="w-full self-stretch flex-col justify-start items-start gap-4 inline-flex">
        {display === "main" ? (
          <>
            <ViewHeader
              userRole={userRole}
              activeItem={""}
              searchQuery={searchQuery}
              tabOptions={[]}
              setActiveItem={() => {}}
              setSearchQuery={setSearchQuery}
              headerRef={headerRef}
            />
            <CustomersList
              ref={customersListRef}
              searchQuery={searchQuery}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              setDisplay={setDisplay}
              headerRef={headerRef}
              from={from}
            />
          </>
        ) : display === "create-edit" ? (
          <AddNewSchool
            ref={addCustomerRef}
            userRole={userRole}
            getData={getData}
            selectedData={selectedData}
            setShowSavedSuccessfully={setShowSavedSuccessfully}
            headerRef={headerRef}
            from={from}
          />
        ) : (
          <CustomerAdminDashboard
            ref={customerAdminDashboardRef}
            userRole={userRole}
            headerRef={headerRef}
            subHeaderRef={subHeaderRef}
            backPressedToggle={backPressedToggle}
          />
        )}
        <PopUpPanel
          type="saved-successful"
          showPopUp={showSavedSuccessfully}
          setShowPopUp={setShowSavedSuccessfully}
          headerRef={headerRef}
        />
      </div>
    );
  }
);

export default Customers;
