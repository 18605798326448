/**
 * apiRequests.ts
 * -----------------
 * This file contains general functions used to perform
 * the requests to the server.
 */

import mongoose from "mongoose";
import { BACKEND_URL } from "utils/getUrl";

const API_URL = BACKEND_URL;

export const getCollectionRequest = async (
  urlAddress: string,
  fields: string[] = [],
  filter: Object = {},
  populateOptions: mongoose.PopulateOptions[] = []
) => {
  // Transform fields into a string
  const fieldsString = fields.join(" ");
  // Transform filter into a string
  const filterString = JSON.stringify(filter);
  // Transform populate option into string
  const populateString = JSON.stringify(populateOptions);

  // Define the field query
  let query = new URLSearchParams({
    fields: fieldsString,
    filter: filterString,
    populateOptions: populateString,
  });

  // Perform the requests
  const response = await fetch(`${API_URL}${urlAddress}?${query}`);

  // Check if the status of the answer is ok
  if (!(response.status === 200)) return [];

  // Get the response
  const data = await response.json();
  // Check if it does not have content, return an empty array
  if (!data.content) {
    return [];
  }

  // Return the content
  return data.content;
};

export const createRecordRequest = async (
  record: object,
  url: string,
  contentType: string = "application/json"
) => {
  // Set up the options for the request
  const options = {
    method: "POST",
    headers: {
      "Content-Type": contentType,
    },
    body: JSON.stringify(record),
  };

  // Post the record to the specified
  const response = await fetch(`${API_URL}${url}`, options);

  // Check if the status of the answer is ok
  if (response.status === 200) {
    // Get the response
    return await response.json();
  }
};

export const updateRecordRequest = async (
  infoToUpdate: object,
  url: string
) => {
  // Sending to the server
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(infoToUpdate),
  };

  // Post the user
  const response = await fetch(`${API_URL}${url}`, options);

  // Check if the status of the answer is ok
  if (response.status === 200) {
    // Get the response
    return await response.json();
  }
};

// Populates code editor with AWS files
export const populateEditor = async (
  type: string | undefined,
  moduleId: string
) => {
  if (type === undefined || (type !== "modules" && type !== "projects")) {
    throw new Error("type must be 'modules' or 'projects'");
  }
  try {
    const response = await fetch(`${API_URL}/api/${type}/${moduleId}/code`);

    if (response.status === 200) {
      let data = await response.json();

      // Map files received
      const filesFromApi = data.files.map((file: any) => ({
        name: file.name,
        content: file.content,
      }));

      return {
        title: data.title,
        videoUrl: data.videoUrl,
        files: filesFromApi,
        planName: data.planName,
        language: data.language,
      };
    } else {
      throw new Error(`Failed to fetch courses: ${response.statusText}`);
    }
  } catch (error) {
    console.error("Fetch error:", error);
  }
};
