import React, { useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { motion, useAnimation } from 'framer-motion';

interface LeaderboardItemProps {
  name: string;
  progress: number;
  aptitude: number;
  status: string;
  showStatus: boolean;
}

const getStatusColor = (status: string): string => {
  return status === 'Active' ? 'text-emerald-500' : 'text-red-500';
};

const getProgressColor = (value: number): string => {
  if (value < 40) return '#FF1744'; // Red
  if (value >= 40 && value < 75) return '#F4A261'; // Orange
  if (value >= 75 && value <= 85) return '#00E0C7'; // Light Green/Blue
  return '#00C853'; // Dark Green
};

const LeaderboardItem: React.FC<LeaderboardItemProps> = ({ name, progress, aptitude, status, showStatus }) => {
  const controls = useAnimation();

  useEffect(() => {
    controls.start({
      opacity: 1,
      transition: { duration: 1 }
    });
  }, [controls]);

  return (
    <motion.div
      className="w-full px-4 py-2 bg-Canvas rounded-lg flex items-center space-x-4"
      initial={{ opacity: 0 }}
      animate={controls}
    >
      <div className="flex-1 text-left text-neutral-600 text-sm">{name}</div>
      <div className={`flex-1 flex items-center ${showStatus ? 'justify-center' : 'justify-center'} relative`}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="relative flex items-center justify-center"
        >
          <CircularProgress
            variant="determinate"
            value={progress}
            size={35}
            thickness={5}
            style={{
              color: getProgressColor(progress),
              strokeLinecap: 'round',
            }}
          />
          <div className="absolute flex items-center justify-center w-[35px] h-[35px]">
            <div className="text-center text-neutral-600 text-sm">{progress}</div>
          </div>
        </motion.div>
      </div>
      <div className={`flex-1 flex items-center ${showStatus ? 'justify-center' : 'justify-end'} relative`}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="relative flex items-center justify-center"
        >
          <CircularProgress
            variant="determinate"
            value={aptitude}
            size={35}
            thickness={5}
            style={{
              color: getProgressColor(aptitude),
              strokeLinecap: 'round',
            }}
          />
          <div className="absolute flex items-center justify-center w-[35px] h-[35px]">
            <div className="text-center text-neutral-600 text-sm">{aptitude}</div>
          </div>
        </motion.div>
      </div>
      {showStatus && (
        <motion.div
          className={`flex-1 text-center ${getStatusColor(status)} text-xs font-semibold`}
          initial={{ opacity: 0 }}
          animate={controls}
          transition={{ duration: 1 }}
        >
          {status}
        </motion.div>
      )}
      {!showStatus && <div/>}
    </motion.div>
  );
};

export default LeaderboardItem;
