import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import ViewHeader from "../utils/view-header/ViewHeader";
import capitaliseFirstChar from "utils/capitaliseFirstChar";
import ClassesList from "./ClassesList";

interface ClassesProps {
  ref: any;
  userRole: string;
  backPressedToggle: boolean;
  headerRef: React.MutableRefObject<any>;
}

const Classes: React.FC<ClassesProps> = forwardRef(
  ({ userRole, backPressedToggle, headerRef }, ref) => {
    // States definition
    const [activeClass, setActiveClass] = useState("classes");
    const [searchQuery, setSearchQuery] = useState("");
    const [showTabSearchBar, setShowTabSearchBar] = useState(true);

    // References definition
    const classesListRef = useRef();

    /**
     * Define the list of functions that can be used from external components
     * using a reference
     */
    useImperativeHandle(ref, () => ({
      // Outer function to change the tab
      setShowTabSearchBar(bool: boolean) {
        setShowTabSearchBar(bool);
      }, 
      getActiveClass() {
        return activeClass;
      },
      setCreateNewStudent(bool: boolean) {
        let currentHandler: any;
        if (classesListRef.current) currentHandler = classesListRef.current;
        currentHandler.setCreateNewStudent(bool);
      },
      setShowCreateNewClass(bool: boolean) {
        let currentHandler: any;
        if (classesListRef.current) currentHandler = classesListRef.current;
        currentHandler.setShowCreateNewClass(bool);
      },
      pressSaveButton(){
        let currentHandler: any;
        if (classesListRef.current) currentHandler = classesListRef.current;
        currentHandler.handleSaveNewStudent();
      },
    }));

    /**
     * Define the tab title and description once opened
     */
    useEffect(() => {
      headerRef.current.setHeaderTitle(capitaliseFirstChar(activeClass));
      headerRef.current.setHeaderDescription(`List of current ${activeClass}`);
    }, []);

    return (
      <div className="w-full self-stretch flex-col justify-start items-start gap-4 inline-flex">
        {showTabSearchBar && (
          <ViewHeader
            userRole={userRole}
            activeItem={activeClass}
            searchQuery={searchQuery}
            tabOptions={["Classes", "Teachers", "Students", "Parents"]}
            setActiveItem={setActiveClass}
            setSearchQuery={setSearchQuery}
            headerRef={headerRef}
          />
        )}
        <ClassesList
          ref={classesListRef}
          activeClass={activeClass}
          searchQuery={searchQuery}
          backPressedToggle={backPressedToggle}
          headerRef={headerRef}
        />
      </div>
    );
  }
);

export default Classes;
