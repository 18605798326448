import React from "react";
import LeaderboardItem from "./leaderboardItem";
import { StudentType } from "utils/interfaces";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

interface LeaderboardProps {
  students: StudentType[];
  showStatus: boolean;
  showRightArrow?: boolean;
  arrow?: string;
}

const Leaderboard: React.FC<LeaderboardProps> = ({
  students,
  showStatus,
  showRightArrow,
}) => {
  return (
    <div className="w-full h-fit p-6 bg-neutral-25 rounded-3xl border-neutral-400 flex flex-col gap-3">
      <div className="self-stretch justify-between items-center flex">
        <div className="text-gray-600 text-lg font-normal">Deadly Board</div>
        {showRightArrow && (
          <button className="w-10 h-10 p-2 bg-neutral-200 rounded-full backdrop-blur-[32px] flex justify-center items-center">
            <ArrowForwardIcon className="text-red-700" />
          </button>
        )}
      </div>
      <div className="self-stretch p-4 flex space-x-4">
        <div className="flex-1 text-left text-neutral-500 text-[10px] font-semibold leading-3 tracking-tight">
          Name
        </div>
        <div className="flex-1 text-center text-neutral-500 text-[10px] font-semibold leading-3 tracking-tight">
          Progress
        </div>
        <div
          className={`flex-1 ${
            showStatus ? "text-center" : "text-end"
          } text-neutral-500 text-[10px] font-semibold leading-3 tracking-tight`}
        >
          Aptitude
        </div>
        {showStatus && (
          <div className="flex-1 text-center text-neutral-500 text-[10px] font-semibold leading-3 tracking-tight">
            Status
          </div>
        )}
        {!showStatus && <div></div>}
      </div>
      <div className="w-full h-full overflow-y-scroll custom-scroll flex flex-col gap-3">
        {students.map((item, index: number) => (
          <LeaderboardItem
            key={index}
            name={`${item.firstName} ${item.lastName}`}
            progress={item.progressRate || 0}
            aptitude={item.aptitudeRate || 0}
            status={showStatus ? item.status || "Inactive" : ""}
            showStatus={showStatus}
          />
        ))}
      </div>
    </div>
  );
};

export default Leaderboard;
