/**
 * PopUpMessages.tsx
 * -----------------
 * This file contains the components which display messages when
 * there is an error or something to highlight.
 */
import { motion } from "framer-motion";
import { MdOutlineCheckCircle, MdError } from "react-icons/md";

export const InputError = ({ message }: { message: string }) => {
  return (
    <motion.p
      className="flex items-center gap-1 px-2 font-semibold text-red-500 bg-red-100 rounded-md"
      {...framer_error}
    >
      <MdError />
      {message}
    </motion.p>
  );
};

export const InputEmailOK = ({ message }: { message: string }) => {
  return (
    <motion.p
      className="flex items-center gap-1 px-2 font-semibold text-green-500 bg-green-100 rounded-md"
      {...framer_error}
    >
      <MdOutlineCheckCircle />
      {message}
    </motion.p>
  );
};

const framer_error = {
  initial: { opacity: 0, y: 0 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 0 },
  transition: { duration: 0.5 },
};
