import SplitPane, { Pane } from "split-pane-react";
import CodeEditorPane, { CodeFile } from "./CodeEditorPane";
import ConsolePane from "./ConsolePane";
import { motion } from "framer-motion";
import ResizeableSash from "../../../assets/icons/editor/ResizeableSashIcon";
import React, { useCallback, useEffect, useImperativeHandle, useState } from "react";
import smoothResize from "../utils/smoothResize";

export interface LeftPaneType {
  editorPanelRef: React.RefObject<HTMLDivElement>;
  normalisedLeftWidth: number;
  filesState: CodeFile[];
  activeTab: string;
  language: string;
  code: string;
  consoleNotification: boolean;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  setFilesState: React.Dispatch<React.SetStateAction<CodeFile[]>>;
  setConsoleNotification: React.Dispatch<React.SetStateAction<boolean>>;
  setLogs: React.Dispatch<React.SetStateAction<any[]>>;
  handleAddFile: () => void;
  handleRunCode: () => void;
  handleEditorChange: (value: string | undefined) => void;
  consolePanelRef: React.RefObject<HTMLDivElement>;
  consoleBottomRef: React.RefObject<HTMLDivElement>;
  logs: any[];
  ref: any;
  leftRightSizes: number[];
  inputConsoleBool: boolean;
  setInputConsoleBool: React.Dispatch<React.SetStateAction<boolean>>;
  setShowOutputRunCode: React.Dispatch<React.SetStateAction<boolean>>;
}

const LeftPane: React.FC<LeftPaneType> = React.forwardRef(
  (
    {
      filesState,
      activeTab,
      language,
      code,
      logs,
      consoleNotification,
      editorPanelRef,
      consolePanelRef,
      consoleBottomRef,
      normalisedLeftWidth,
      leftRightSizes,
      inputConsoleBool,
      handleAddFile,
      handleRunCode,
      handleEditorChange,
      setActiveTab,
      setFilesState,
      setConsoleNotification,
      setLogs,
      setInputConsoleBool,
      setShowOutputRunCode,
    },
    ref
  ) => {
    // Panels size limits
    const baseMinEditorHeight = 70; // minimum editor panel height in px
    const baseMinConsoleHeight = 70; // minimum console panel height in px
    const baseMaxConsoleHeight = 180; // maximum editor panel height in px, before applying the console animation
    const consoleEditorSashOpacityLimit = 0.3; // This value allows to define the amount of opacity fading on the console-editor sash when scrolling left-right

    // States definition
    const [consoleEditorSizes, setConsoleEditorSizes] = useState([
      window.innerHeight - 155 - baseMinConsoleHeight - 64, // 64 is the px of padding all the panes (32px from top and 32px from bottom), 155 is height of the navbar
      baseMinConsoleHeight,
    ]);
    const [normalisedEditorHeight, setNormalisedEditorHeight] =
      useState<number>(1);
    const [normalisedConsoleHeight, setNormalisedConsoleHeight] =
      useState<number>(1);
    const [minEditorHeight, setMinEditorHeight] =
      useState<number>(baseMinEditorHeight); // Adjustable minimum editor panel height in px
    const [minConsoleHeight, setMinConsoleHeight] =
      useState<number>(baseMinConsoleHeight); // Adjustable minimum console panel height in px
    const [maxConsoleHeight, setMaxConsoleHeight] =
      useState<number>(baseMaxConsoleHeight); // Adjustable minimum console panel height in px
    const [isConsoleOpen, setIsConsoleOpen] = useState<boolean>(false);
    const [consoleLabelTransition, setConsoleLabelTransition] = useState<string>("transform 0.05s ease-in-out");

    // Parameters
    const topDownScreenPercTrigger = 0.92; // Percentage of the screen size needed to trigger the smooth transition
    const consoleDelta = 45; // Amount of px that the console needs to increase when double folding
    const editorDelta = 73; // Amount of px that the console needs to increase when double folding

    // Get the limit pixel of the animation
    const startAnimPy = Math.round(
      (1 - topDownScreenPercTrigger) * window.innerHeight
    );
    const startAnimOpPy = 30; // px

    /*
     * To control the left pane reference to let it know to the <CodeEditor/> if the console is closed.
     */
    useImperativeHandle(ref, () => ({
      isConsoleClosed: () => {
        return normalisedConsoleHeight === 0;
      },
      isEditorClosed: () => {
        return normalisedEditorHeight === 0;
      },
    }));

    /*
     * Every time we open the console, we ensure to clear the notification
     */
    useEffect(() => {
      if (normalisedConsoleHeight > 0) {
        setConsoleNotification(false);
      }
    }, [normalisedConsoleHeight]);

    /*
     * Calculate normalised editor and console height for close animations
     */
    useEffect(() => {
      setNormalisedEditorHeight(
        Math.min(
          Math.max((consoleEditorSizes[0] - minEditorHeight) / startAnimPy, 0),
          1
        )
      );
      setNormalisedConsoleHeight(
        Math.min(
          Math.max((consoleEditorSizes[1] - minConsoleHeight) / startAnimPy, 0),
          1
        )
      );
    }, [consoleEditorSizes]);

    /*
     * Re-define the minConsoleHeight and minEditorHeight based on the value of
     * the normalisedConsoleHeight for animation
     */
    useEffect(() => {
      // Define the new limits when the editor and console are closed
      if (normalisedLeftWidth < 1) {
        setMinEditorHeight(baseMinEditorHeight + editorDelta);
        setMinConsoleHeight(baseMinConsoleHeight + consoleDelta);
      } else {
        // Return to normal when the condition is not achieved
        setMinEditorHeight(baseMinEditorHeight);
        setMinConsoleHeight(baseMinConsoleHeight);
      }

      // Only apply this logic when the height of the console is closed or in the closing process
      if (normalisedConsoleHeight < 1) {
        // Define the amount of increasing
        const delta = (1 - normalisedLeftWidth) * consoleDelta;
        const modifiedMinConsoleHeight = baseMinConsoleHeight + delta;
        setMinConsoleHeight(modifiedMinConsoleHeight);
        setConsoleEditorSizes([
          consoleEditorSizes[0] +
            consoleEditorSizes[1] -
            modifiedMinConsoleHeight,
          modifiedMinConsoleHeight,
        ]);
      }

      // Only apply this logic when the height of the editor is closed or in the closing process
      if (normalisedEditorHeight < 1) {
        // Define the amount of increasing
        const delta = (1 - normalisedLeftWidth) * editorDelta;
        const modifiedMinEditorHeight = baseMinEditorHeight + delta;
        setMinEditorHeight(modifiedMinEditorHeight);
        setConsoleEditorSizes([
          modifiedMinEditorHeight,
          consoleEditorSizes[0] +
            consoleEditorSizes[1] -
            modifiedMinEditorHeight,
        ]);
      }
    }, [normalisedLeftWidth]);

    /**
     * Define if the run code button should appear on the output panel (after transitions)
     */
    useEffect(() => {
      setShowOutputRunCode(normalisedLeftWidth === 0 || normalisedEditorHeight === 0);
    }, [normalisedLeftWidth, normalisedEditorHeight])

    const handleConsoleResizeEnd = useCallback(() => {
      var editorHeight = editorPanelRef?.current?.clientHeight ?? 0;
      var consoleHeight = consolePanelRef?.current?.clientHeight ?? 0;

      // Aconditionate the numbers
      // 1. To correct from the bias of taking the current values
      // 2. To not exceed the limits
      editorHeight = Math.max(baseMinEditorHeight, editorHeight + 20); // 20 is per the compensation because we're taking the size of the editor
      consoleHeight = Math.max(baseMinConsoleHeight, consoleHeight + 20); // 20 is per the compensation because we're taking the size of the editor

      // Create the current size array for the smoothResize function
      var currentSizes = [editorHeight, consoleHeight];

      // P.S.: the condition is achieved with the "baseMinHeight" because we
      // don't want to move the "trigger" of the animation. However, we
      // use the "realMinHeight" because those are the real values of the
      // elements.
      if (
        editorHeight < baseMinEditorHeight + startAnimPy &&
        editorHeight !== baseMinEditorHeight
      ) {
        smoothResize(
          [minEditorHeight, editorHeight + consoleHeight - minEditorHeight],
          currentSizes,
          setConsoleEditorSizes,
          100
        );
      };
      if (
        (consoleHeight < baseMinConsoleHeight + startAnimPy) &&
        (consoleHeight >= baseMinConsoleHeight)
      ) {
        smoothResize(
          [editorHeight + consoleHeight - minConsoleHeight, minConsoleHeight],
          currentSizes,
          setConsoleEditorSizes,
          100
        );
        // And define that it's closed
        setIsConsoleOpen(false);
        setMaxConsoleHeight(baseMaxConsoleHeight);
      };
      
      if ((!isConsoleOpen) && (consoleHeight > baseMaxConsoleHeight - startAnimOpPy)) {
        setIsConsoleOpen(true);
        setMaxConsoleHeight(Infinity);
        setConsoleLabelTransition("transform 0.5s ease-in-out")

        // Add some pixels for the opening effect
        smoothResize(
          [editorHeight + consoleHeight - (baseMaxConsoleHeight + 30), baseMaxConsoleHeight + 30],
          currentSizes,
          setConsoleEditorSizes,
          500
        );

        // After the resize is done, set the transition to normal value
        setTimeout(() => setConsoleLabelTransition("transform 0.05s ease-in-out"), 1000);
      }
    }, [smoothResize, minConsoleHeight, minEditorHeight, isConsoleOpen]);

    
    return (
      <div className="flex w-full h-full">
        <SplitPane
          split="horizontal"
          sizes={consoleEditorSizes}
          onChange={setConsoleEditorSizes}
          onDragEnd={handleConsoleResizeEnd}
          resizerSize={40}
          sashRender={() => (
            <div className="ml-12 h-10 align-middle flex items-center justify-center debug">
              <motion.div
                className="rotate-90"
                style={{
                  opacity:
                    (1 - consoleEditorSashOpacityLimit) * normalisedLeftWidth +
                    consoleEditorSashOpacityLimit,
                }}
              >
                <ResizeableSash></ResizeableSash>
              </motion.div>
            </div>
          )}
        >
          <Pane
            className="w-full h-full pb-5 overflow-visible"
            minSize={`${minEditorHeight}px`}
          >
            <CodeEditorPane
              editorPanelRef={editorPanelRef}
              normalisedLeftWidth={normalisedLeftWidth}
              normalisedEditorHeight={normalisedEditorHeight}
              filesState={filesState}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              handleAddFile={handleAddFile}
              handleRunCode={handleRunCode}
              setFilesState={setFilesState}
              language={language}
              code={code}
              handleEditorChange={handleEditorChange}
              leftRightSizes={leftRightSizes}
              consoleEditorSizes={consoleEditorSizes}
            />
          </Pane>
          <Pane
            className="w-full h-full pt-5 overflow-visible"
            minSize={`${minConsoleHeight}px`}
            maxSize={`${maxConsoleHeight}px`}
          >
            <ConsolePane
              normalisedLeftWidth={normalisedLeftWidth}
              normalisedConsoleHeight={normalisedConsoleHeight}
              consoleNotification={consoleNotification}
              consolePanelRef={consolePanelRef}
              consoleBottomRef={consoleBottomRef}
              logs={logs}
              setLogs={setLogs}
              isConsoleOpen={isConsoleOpen}
              consoleEditorSizes={consoleEditorSizes}
              consoleLabelTransition={consoleLabelTransition}
              inputConsoleBool={inputConsoleBool}
            setInputConsoleBool={setInputConsoleBool}
            setConsoleNotification={setConsoleNotification}
          />
          </Pane>
        </SplitPane>
      </div>
    );
  }
);

export default LeftPane;
