import React, { useState, useEffect, useCallback, useLayoutEffect } from "react";
import "split-pane-react/esm/themes/default.css";
import CodeEditor from "components/Editor/CodeEditor";
import { populateEditor } from "services/apiRequests";
import { useParams } from "react-router-dom";
import { CodeFile } from "utils/interfaces";
import Assistant from "components/assistant/Assistant";
import CodeEditorHeader from "components/Editor/utils/CodeEditorHeader";
import { motion } from "framer-motion";


const Editor: React.FC = () => {
  const [activeItem, setActiveItem] = useState<"editor" | "assistant">(
    "editor"
  ); // to change between editor and assistant views
  const [videoExpanded, setVideoExpanded] = useState(false);
  const [name, setName] = useState<string>("");
  const [video, setVideo] = useState<string>("");
  const [fileState, setFileState] = useState<CodeFile[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [pdf, setPdf] = useState<string>("");
  const [language, setLanguage] = useState<"Python" | "JavaScript" | "Scratch" | null>();
  const { moduleOrProject, id } = useParams();

  const [scaleFactor, setScaleFactor] = useState(1);
  const [scaledTopOffset, setScaledTopOffset] = useState(0);
  const [scaledRightOffset, setScaledRightOffset] = useState(0);
  const [isResizingOutput, setIsResizingOutput] = useState<boolean>(false);

  // Retrieve module data from db
  const fetchModuleData = useCallback(
    async (id: string) => {
      if (typeof id !== "string") {
        console.error("Invalid moduleId:", id);
        return;
      }
      try {
        const data = await populateEditor(moduleOrProject, id);
        if (data) {
          setFileState(data.files);
          setName(data.title);
          setVideo(data.videoUrl);
          setPdf(data.planName);
          setLanguage(data.language)
          setLoaded(true);
        } else {
          console.error("Invalid module data received from backend");
        }
      } catch (error) {
        console.error("Error fetching module data:", error);
      }
    },
    [moduleOrProject]
  );

  useEffect(() => {
    if (id) {
      fetchModuleData(id);
    }
  }, [id, fetchModuleData]);

  useEffect(() => {
    setLoaded(
      fileState.length > 0 &&
        name !== undefined &&
        video !== undefined &&
        !!moduleOrProject
    );
  }, [fileState.length, moduleOrProject, name, video]);

  // For resizing Scratch IFrame
  useLayoutEffect(() => {
    const handleResize = () => {
      const targetWidth = 180;
      const originalWidth = document.body.clientWidth - 96; // 96 is x padding
      const widthScale = targetWidth / originalWidth;
      const targetHeight = 111;
      const originalHeight = document.body.clientHeight - 155; // 155 is top offset
      const heightScale = targetHeight / originalHeight;
      const rightOffset = (targetWidth - (originalWidth * heightScale)) / 2;
      const topOffset = (targetHeight - (originalHeight * widthScale)) / 2;

      if (widthScale < heightScale) {
        setScaleFactor(widthScale);
        setScaledTopOffset(topOffset);
        setScaledRightOffset(0);
      } else {
        setScaleFactor(heightScale);
        setScaledTopOffset(0);
        setScaledRightOffset(rightOffset);
      }
    }
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  //------------- Demo for how to send data to iframe-------------
  // Get iframe reference
  const scratch_iframe = document.getElementById('scratch-gui') as HTMLIFrameElement;
  //Retrieve correct iframe with .contentWindow, then use .postMessage as communication protocol
  const sendMessageToScratchGUI = (message: string) => {
    if (scratch_iframe && scratch_iframe.contentWindow){
      scratch_iframe.contentWindow.postMessage(message, 'http://localhost:8602'); // specify desination address
    }
  };

  //------------ Demo for how to receive data from iframe --------------------
  // Add an event listener
  window.addEventListener('message', function(event: any) {
        if (event.origin !== 'http://localhost:8602') return; // only accept messages from specified address
        console.log("Message from GUI: ", event.data);
  });

  // There is a button commented out in the return statement below, this allows you to test sending data from top level to iframe.
  // To test sending data from iframe to top level:
  // Open console --> Right of the clear console button click Top and change this to the scratch GUI domain
  // Paste this into the console and you should see a response:
  //  window.top.postMessage("Sending data from iframe to top", 'http://localhost:3000')


  return (
    <div className={`h-screen overflow-hidden ${language === "Scratch" ? "flex flex-col" : ""}`}>
      <CodeEditorHeader
        name={name}
        video={video}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        videoExpanded={videoExpanded}
        setVideoExpanded={setVideoExpanded}
        hideVideo={activeItem === "assistant"}
        showAssistant={language !== 'Scratch'}
      />
      <div className={`flex-grow relative ${ activeItem === "assistant" ? 'w-full h-[calc(100vh-155px)]' : videoExpanded ? '-mt-[155px] -z-10' : 'z-10'}`}>
        {language !== 'Scratch' ? (
          <>
            <div
              className="w-full h-full absolute"
              style={{ display: activeItem === "assistant" ? "block" : "none" }}
            >
              <Assistant
                key={`${name}`}
                files={fileState}
                setActiveItem={setActiveItem}
              />
            </div>
            {loaded && (
              <div className={`w-full h-full ${activeItem === "assistant" ? "hidden" : "block"}`}>
                <CodeEditor
                  key={`${name}`}
                  files={fileState}
                  hasPdf={!!pdf}
                  onFileChange={(x) => setFileState(x)}
                  toShrink={videoExpanded}
                  hide={activeItem === "assistant"}
                />
              </div>
            )}
          </>
        ) : (
          <motion.div
          className="w-screen h-screen overflow-hidden absolute top-0 left-0"
          style={{ 
            zIndex: videoExpanded ? -10 : 'auto',
          }}
        >
            <motion.div
              className="w-full h-full absolute origin-top-right"
              initial={{
                transform: "translateX(0%) scale(1)",
                top: 0,
                right: 0,
              }}
              animate={{
                transform: videoExpanded ? `translateX(0%) scale(${scaleFactor*0.75})` : "translateX(0%) scale(1)",
                top: videoExpanded ? 26 + scaledTopOffset : 0,
                right: videoExpanded ? 60 + scaledRightOffset : 0,
                transition: { ease: "linear" },
              }}
              onAnimationStart={() => setIsResizingOutput(true)}
              onAnimationEnd={() => setIsResizingOutput(false)}
            >
              <div className="relative w-full h-full pointer-events-auto flex flex-col">
                <div className="h-px bg-gray-300 w-full"></div>
                <iframe
                  id="scratch-gui"
                  src="https://scratch.deadlycodersonline.kazacos.ai"
                  className="w-full border-none"
                  style={{ height: 'calc(100% - 155px)' }}
                  allowFullScreen
                  title="Embedded Content"
                />
              </div>
            </motion.div>
          </motion.div>
        )}
      </div>
    </div>
  );
  
};

export default Editor;