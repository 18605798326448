/*
  This page facilitates communication with an AI assistant.
*/
import React, { useState, useCallback, useEffect } from "react";
import watermarkImage1 from "../../assets/imgs/assistantWatermark1.png";
import watermarkImage2 from "../../assets/imgs/assistantWatermark2.png";
import AIAvatar from "../../assets/imgs/AIAvatar.png";
import ChatInterface from "components/assistant/ChatInterface";
import { useParams } from "react-router-dom";
import { CodeFile, Module } from "utils/interfaces";
import CodeEditorHeader from "components/Editor/utils/CodeEditorHeader";
import { MdOutlineSend } from "react-icons/md";
import styled from "@emotion/styled";
import ChatMessages from "./ChatMessages";
import ChatMessageInput from "./ChatMessageInput";
import { BACKEND_URL } from "utils/getUrl";

const API_URL = BACKEND_URL;

export interface Message {
  content: string;
  role: "user" | "assistant";
}

interface AssistantParams {
  files: CodeFile[];
  setActiveItem: React.Dispatch<React.SetStateAction<"editor" | "assistant">>;
}

const defaultMessages = [
  "I'm stuck, Can you help me?",
  "Please check my code",
  // "How can I run the code",
  "I don't understand",
  "I can see an error",
];

export const guardrailCatchMessage = "Let's stay on topic. I'm here to help with coding questions or any issues you're facing. How can I assist you today?";

const Assistant: React.FC<AssistantParams> = ({ files, setActiveItem }) => {
  const [waitingForAiResponse, setWaitingForAiResponse] = useState(false);
  const { moduleOrProject, id } = useParams();
  const [messages, setMessages] = useState<Message[]>([
    {
      content:
        "## Jingeri!\n\nI'm Little C and I'm a Yugumbeh boy. Jingeri is how we say welcome on my country. I'm here to help you become a Deadly Coder!\n\nPick an option below or enter your question to get started",
      role: "assistant",
    },
  ]);
  const [inputMessage, setInputMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  console.log(BACKEND_URL);

  const messagesToSend = (messages: Message[]) => {
    let previousItemShouldBeFiltered = false;
    const _messages = [...messages];
  
    return _messages.reverse().filter((message, index, arr) => {
      const shouldFilter = previousItemShouldBeFiltered || (message.role === "assistant" && message.content === guardrailCatchMessage);
      previousItemShouldBeFiltered = message.role === "assistant" && message.content === guardrailCatchMessage;
  
      return !shouldFilter;
    }).reverse();
  }

  // TODO: handle changing avatar based on user ID
  // if (getUserIdFromToken() == null) { }

  const getAiMarks = useCallback(async () => {
    const message = "Please check my code";
    try {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          question: message,
          myCode: files[0].content,
          id: id,
          messages: messagesToSend(messages),
          type: moduleOrProject,
        }),
      };
      const response = await fetch(
        `${API_URL}/api/assistant/mark-code`,
        options
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const text = await response.text();
      setMessages((prev) => [...prev, { content: text, role: "assistant" }]);
    } catch (error) {
      console.error("Error fetching code:", error);
    }
  }, [files, messages, id]);

  const getAiResponse = useCallback(
    async (message: string) => {
      try {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            question: message,
            myCode: files[0].content,
            id: id,
            messages: messagesToSend(messages),
            type: moduleOrProject,
          }),
        };
        const response = await fetch(`${API_URL}/api/assistant/query`, options);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const text = await response.text();
        setMessages((prev) => [...prev, { content: text, role: "assistant" }]);
      } catch (error) {
        console.error("Error fetching code:", error);
      }
    },
    [files, messages, id]
  );

  useEffect(() => {
    if (waitingForAiResponse) {
      const timer = setTimeout(() => {
        if (waitingForAiResponse) {
          setShowLoading(true);
        }
      }, 750);
      return () => clearTimeout(timer);
    } else {
      setShowLoading(false);
    }
  }, [waitingForAiResponse]);

  // For sending messages via the conversation starters
  const handleUserMessage = useCallback(
    async (text: string) => {
      if (waitingForAiResponse) {
        return
      }
      setWaitingForAiResponse(true);

      setMessages((prev) => [...prev, { content: text, role: "user" }]);
      setInputMessage("");
      if (text === "Please check my code") {
        await getAiMarks();
      } else {
        await getAiResponse(text);
      }
      setWaitingForAiResponse(false);
      setShowLoading(false);
    },
    [getAiMarks, getAiResponse, waitingForAiResponse]
  );

  return (
    <div className="max-w-[767px] h-full flex flex-col mx-auto bg-white z-10">
      {/* Watermarks */}
      <img
        src={watermarkImage1}
        alt="Watermark 1"
        className="absolute top-0 right-0 w-72 h-72 opacity-30 -rotate-90 -z-10"
      />
      <img
        src={watermarkImage2}
        alt="Watermark 2"
        className="absolute bottom-0 left-0 w-52 h-72 opacity-30 -rotate-15 -z-10"
      />
      <ChatMessages messages={messages} showLoading={showLoading} />
      <div className="flex-none h-fit w-full pb-12">
        <div className="flex space-x-2 overflow-x-hidden py-4">
          {defaultMessages.map((text, index) => (
            <div
              key={index}
              onClick={() => handleUserMessage(text)}
              className={`px-3 py-1 bg-zinc-100 rounded-xl border border-amber-700 flex-shrink-0 whitespace-nowrap select-none ${waitingForAiResponse ? "cursor-default" : "cursor-pointer"}`}
            >
              <div className="text-amber-700 text-xs font-semibold font-['Poppins']">
                {text}
              </div>
            </div>
          ))}
        </div>
        <ChatMessageInput inputEnabled={waitingForAiResponse} handleSend={handleUserMessage} />
      </div>
    </div>
  );
};

export default Assistant;
