import watermarkImage from "assets/imgs/watermark1.png";
import JourneyBadge from "./cards/JourneyBadge";
import { ArrayElement, Project } from "utils/interfaces";

interface MyLearningJourneyProps {
  courseName: string;
  projectsToDisplay: Project[];
  numBadges: number;
}

const MyLearningJourney: React.FC<MyLearningJourneyProps> = ({
  courseName,
  projectsToDisplay,
  numBadges,
}) => {
  return (
    <div className="w-full h-[555px] p-6 bg-neutral-25 rounded-3xl flex-col justify-between items-start inline-flex overflow-hidden relative">
      <img
        className="absolute top-1/2 left-1/2 min-w-[1000px] max-w-[1000px] rotate-45 transform -translate-x-1/2 -translate-y-1/2 opacity-10 select-none"
        src={watermarkImage}
      />
      <div className="w-full h-[555px] rounded-3xl flex-col justify-start gap-6 items-start  inline-flex">
        <div className="self-stretch flex-col justify-start items-start inline-flex">
          <div className="self-stretch text-neutral-700 text-lg font-normal font-sans leading-snug">
            My Learning Journey
          </div>
          <div className="self-stretch text-neutral-500 text-xs font-normal font-sans leading-[14px]">
            {courseName || "..."}
          </div>
        </div>
        <div className="w-full justify-items-center grid grid-cols-3 justify-between gap-6 overflow-x-clip overflow-y-auto custom-scroll z-10">
          {projectsToDisplay && projectsToDisplay.length > 0 ? (
            projectsToDisplay.map(
              (
                project: ArrayElement<typeof projectsToDisplay>,
                index: number
              ) => (
                <JourneyBadge
                  key={index}
                  title={project.title}
                  badgeImage={project.badgeImage}
                  completed={index < numBadges}
                  toComplete={index === numBadges}
                />
              )
            )
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="self-stretch justify-center items-center gap-2 inline-flex">
        <div className="w-8 h-2 bg-neutral-500 rounded-xl" />
        <div className="w-2 h-2 bg-neutral-200 rounded-xl" />
        <div className="w-2 h-2 bg-neutral-200 rounded-xl" />
        <div className="w-2 h-2 bg-neutral-200 rounded-xl" />
      </div>
    </div>
  );
};

export default MyLearningJourney;
