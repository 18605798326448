import { getCollectionRequest } from "./apiRequests";

export const getProcessedClasses = async () => {
  let data = await getCollectionRequest(
    "/api/classes",
    ["_id", "name", "teachers"],
    {},
    [
      {
        path: "teachers",
        select: ["firstName", "lastName", "email", "phone"],
      },
      {
        path: "students",
        select: [
          "aptitudeRate",
          "progressRate",
          "averageScore",
          "firstName",
          "lastName",
        ],
      },
    ]
  );

  data.forEach((classItem: any) => {
    if (classItem.students !== undefined && classItem.students.length > 0) {
      classItem.enrollmentRate = (100 * classItem.students.length) / 10;
      classItem.licenses = 10;
      classItem.progressRate = Math.floor(
        classItem.students.reduce(
          (partialSum: number, student: any) =>
            partialSum + (student.progressRate || 0),
          0
        ) / classItem.students.length
      );
      classItem.aptitudeRate = Math.floor(
        classItem.students.reduce(
          (partialSum: number, student: any) =>
            partialSum + (student.aptitudeRate || 0),
          0
        ) / classItem.students.length
      );
      classItem.averageScore = Math.floor(
        classItem.students.reduce(
          (partialSum: number, student: any) =>
            partialSum + (student.averageScore || 0),
          0
        ) / classItem.students.length
      );
    } else {
      classItem.enrollmentRate = 0;
      classItem.licenses = 0;
      classItem.progressRate = 0;
      classItem.aptitudeRate = 0;
      classItem.averageScore = 0;
    }
  });

  return data;
};
