import StudentCard from "./cards/StudentCard";
import ClassesListCards from "./cards/ClassesListCards";
import ParentCard from "./cards/ParentCard";
import TeacherCard from "./cards/TeacherCard";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import AddNewStudent from "./add-new-student-view/AddNewStudent";
import PopUpPanel from "./pop-up-panels/PopUpPanel";
import { getCollectionRequest } from "services/apiRequests";
import {
  ClassListType,
  ContactListType,
  ParentListType,
  StudentListType,
  TeacherListType,
} from "utils/interfaces";
import { getProcessedClasses } from "services/classesRequests";

interface ClassesListProps {
  ref: any;
  activeClass: string;
  searchQuery: string;
  backPressedToggle: boolean;
  headerRef: React.MutableRefObject<any>;
}

const ClassesList: React.FC<ClassesListProps> = forwardRef(
  ({ activeClass, searchQuery, backPressedToggle, headerRef }, ref) => {
    // States definition
    /**
     * The general version of "data" had to be changed because
     * it was having race conditions with when rendering, and
     * it was throwing erros when the element was rendering before
     * we get the data.
     */
    const [selectedData, setSelectedData] = useState<
      ClassListType | TeacherListType | ParentListType | StudentListType | null
    >(null);
    const [classes, setClasses] = useState<ClassListType[]>([]);
    const [teachers, setTeachers] = useState<TeacherListType[]>([]);
    const [students, setStudents] = useState<StudentListType[]>([]);
    const [parents, setParents] = useState<ParentListType[]>([]);
    const [showSavedSuccessfully, setShowSavedSuccessfully] = useState(false);
    const [createNewStudent, setCreateNewStudent] = useState(false);
    const [showCreateNewClass, setShowCreateNewClass] = useState(false);
    // States for edit pop ups
    const [showEditClass, setShowEditClass] = useState(false);
    const [showEditTeacher, setShowEditTeacher] = useState(false);
    const [showEditParent, setShowEditParent] = useState(false);
    // State for view pop up
    const [selectedTeachers, setSelectedTeachers] = useState<ContactListType[]>(
      []
    );
    const [showTeachersList, setShowTeachersList] = useState(false);

    // Add student reference definition
    const addStudentRef = useRef(null);

    /**
     * Define the list of functions that can be used from external components
     * using a reference
     */
    useImperativeHandle(ref, () => ({
      setCreateNewStudent(bool: boolean) {
        setCreateNewStudent(bool);
      },
      setShowCreateNewClass(bool: boolean) {
        setShowCreateNewClass(bool);
      },
      handleSaveNewStudent() {
        let currentHandler: any;
        if (addStudentRef.current) currentHandler = addStudentRef.current;
        currentHandler.handleSaveNewStudent();
      },
    }));

    /**
     * Root definition
     */
    useEffect(() => {
      getData();
    }, [activeClass]);

    /**
     * Everytime the backPressedToggle signal is activated, we come back to the root view
     */
    useEffect(() => {
      // Once pressed, come back to the root view
      headerRef.current.classes().onDiscard();
      // And set any selectedData to null
      setSelectedData(null);
    }, [backPressedToggle]);

    /**
     * Set selectedData to null when close edit pop ups
     */
    useEffect(() => {
      // If all of them are closed, then set selectedData to null
      if (!(showEditClass || showEditTeacher || showEditParent)) {
        setSelectedData(null);
      }
    }, [showEditClass, showEditTeacher, showEditParent]);

    /**
     * Function applied when we click on cards
     */
    const handleCardClick = (
      card: ClassListType | TeacherListType | ParentListType | StudentListType
    ) => {
      // Select the current data
      setSelectedData(card);

      // Action depending on the shown tab
      if (activeClass === "classes") {
        setShowEditClass(true);
      } else if (activeClass === "teachers") {
        setShowEditTeacher(true);
      } else if (activeClass === "students") {
        // Define the type of the variable (for TypeScript)
        const studentCard = card as StudentListType;
        // Show the back button
        headerRef.current.setHeaderBackButton(true);
        // Set the same view as when creating a school
        headerRef.current
          .classes()
          .pressEditStudent(
            "Student",
            `${studentCard.firstName} ${studentCard.lastName}`
          );
      } else if (activeClass === "parents") {
        setShowEditParent(true);
      }
    };

    /**
     * Function to get the data depending on the class
     * @returns data
     */
    const getData = async () => {
      let data: any;
      if (activeClass === "classes") {
        data = await getProcessedClasses();
      } else if (activeClass === "teachers") {
        // Get the data using this filter
        data = await getCollectionRequest(
          "/api/users",
          ["firstName", "lastName", "classes", "phone", "email"],
          {
            role: "class admin",
          }
        );
      } else if (activeClass === "students") {
        // Get the data using this filter
        data = await getCollectionRequest(
          "/api/users",
          [
            "firstName",
            "middleName",
            "lastName",
            "dateOfBirth",
            "gender",
            "heritage",
            "classes",
            "parents",
            "badges",
            "averageScore",
            "progressRate",
            "notes",
          ],
          {
            role: "student",
          },
          [
            {
              path: "parents",
              select: ["firstName", "lastName", "phone", "email"],
            },
            {
              path: "classes",
              select: ["name", "teachers"],
              populate: {
                path: "teachers",
                select: ["firstName", "lastName"],
              },
            },
          ]
        );
      } else if (activeClass === "parents") {
        // Get the data using this filter
        data = await getCollectionRequest(
          "/api/users",
          ["firstName", "lastName", "phone", "email", "students"],
          { role: "parent" },
          [
            {
              path: "students",
              select: ["firstName", "middleName", "lastName"],
            },
          ]
        );
      }

      // Assign the data to the corresponding element
      if (activeClass === "classes") setClasses(data);
      else if (activeClass === "teachers") setTeachers(data);
      else if (activeClass === "students") setStudents(data);
      else if (activeClass === "parents") setParents(data);
      return data;
    };

    /**
     * Function that renders the list of cards depending of the activeClass selected
     * @returns a list of cards
     */
    const cardsToRender = () => {
      if (activeClass === "classes") {
        return (
          classes.length > 0 && (
            <ClassesListCards
              canEdit={true}
              classes={classes}
              searchQuery={searchQuery}
              setSelectedTeachers={setSelectedTeachers}
              setShowTeachersList={setShowTeachersList}
              onCardClick={handleCardClick}
            />
          )
        );
      } else if (activeClass === "teachers") {
        return (
          teachers.length > 0 &&
          teachers
            .filter((teacher: TeacherListType) => {
              let name = teacher.firstName + " " + teacher.lastName;
              return name.toLowerCase().includes(searchQuery.toLowerCase());
            })
            .map((teacher: TeacherListType, index: number) => (
              <TeacherCard
                key={index}
                teacher={teacher}
                handleCardClick={handleCardClick}
              />
            ))
        );
      } else if (activeClass === "students") {
        return (
          students.length > 0 &&
          students
            .filter((student: StudentListType) => {
              let name = student.firstName + " " + student.lastName;
              return name.toLowerCase().includes(searchQuery.toLowerCase());
            })
            .map((student: StudentListType, index: number) => (
              <StudentCard
                key={index}
                student={student}
                handleCardClick={handleCardClick}
              />
            ))
        );
      } else if (activeClass === "parents") {
        return (
          parents.length > 0 &&
          parents
            .filter((parent: ParentListType) => {
              let name = parent.firstName + " " + parent.lastName;
              return name.toLowerCase().includes(searchQuery.toLowerCase());
            })
            .map((parent: ParentListType, index: number) => (
              <ParentCard
                key={index}
                parent={parent}
                handleCardClick={handleCardClick}
              />
            ))
        );
      } else return false;
    };

    return (
      <div className="w-full h-full flex-col justify-start items-start gap-4 inline-flex overflow-y-auto custom-scroll">
        {!createNewStudent ? (
          cardsToRender()
        ) : (
          <AddNewStudent
            ref={addStudentRef}
            getData={getData}
            selectedData={selectedData as StudentListType}
            setShowSavedSuccessfully={setShowSavedSuccessfully}
            headerRef={headerRef}
          />
        )}
        {/* Definition of the possible pop up panels in this view */}
        <PopUpPanel
          type="saved-successful"
          showPopUp={showSavedSuccessfully}
          setShowPopUp={setShowSavedSuccessfully}
          headerRef={headerRef}
        />
        <PopUpPanel
          type="create-class"
          showPopUp={showCreateNewClass}
          setShowPopUp={setShowCreateNewClass}
          getData={getData}
          headerRef={headerRef}
        />
        <PopUpPanel
          type="edit-class"
          selectedData={selectedData as ClassListType}
          showPopUp={showEditClass}
          setShowPopUp={setShowEditClass}
          getData={getData}
          headerRef={headerRef}
        />
        <PopUpPanel
          type="edit-teacher"
          selectedData={selectedData as TeacherListType}
          showPopUp={showEditTeacher}
          setShowPopUp={setShowEditTeacher}
          getData={getData}
          headerRef={headerRef}
        />
        <PopUpPanel
          type="edit-parent"
          selectedData={selectedData}
          showPopUp={showEditParent}
          setShowPopUp={setShowEditParent}
          getData={getData}
          headerRef={headerRef}
        />
        <PopUpPanel
          type="view-class-teachers"
          selectedTeachers={selectedTeachers}
          showPopUp={showTeachersList}
          setShowPopUp={setShowTeachersList}
        />
      </div>
    );
  }
);

export default ClassesList;