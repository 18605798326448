import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";

interface TabButtonProps {
  text: string;
  isActive: boolean;
  isEditable?: boolean;
  onClick?: () => void;
  onRename?: (newText: string) => void;
  negativeTabColor?: boolean;
  renameSignal?: boolean;
}

const TabButton: React.FC<TabButtonProps> = ({
  text,
  isActive,
  isEditable = false,
  onClick,
  onRename,
  negativeTabColor = false,
  renameSignal
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editedText, setEditedText] = useState<string>(text);
  const inputRef = useRef<HTMLInputElement>(null);
  const textWidthRef = useRef<HTMLSpanElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [inputWidth, setInputWidth] = useState<number>(0);
  const didMount = useRef<boolean>(false); // Useful to avoid run the effect of the signal on rendering

  useEffect(() => {
    const updateInputWidth = () => {
      const spanWidth = textWidthRef.current?.offsetWidth;
      if (spanWidth) {
        setInputWidth(spanWidth); // Adding a little extra space to prevent clipping
      }
    };

    if (isEditing) {
      updateInputWidth();
    }

    if (text && !isEditing) {
      updateInputWidth();
    }
  }, [text, isEditing]);

  useEffect(() => {
    // Correctly typed as a DOM MouseEvent handler
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isEditing &&
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsEditing(false);
        onRename?.(editedText);
      }
    };

    if (isEditing) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editedText, isEditing, onRename]);

  /**
   * handle the signal sent by the rename button in the options menu
   */
  useEffect(() => {
    if (didMount.current) {
      if (isActive && isEditable) {
        setIsEditing(true);
      }
    } else {
      didMount.current = true;
    }
  }, [renameSignal]);

  const handleClick = () => {
    // If we select the tab again, then apply a rename logic
    if (isActive && isEditable) {
      setIsEditing(true);
    }

    // And execute the onClick inherited event (as originally)
    if (onClick != null) {
      onClick();
    }
  };

  const handleDoubleClick = () => {
    if (isEditable) {
      setIsEditing(true);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      setIsEditing(false);
      onRename?.(editedText);
    } else if (event.key === "Escape") {
      setIsEditing(false);
      setEditedText(text); // Revert to original text on escape
    }
  };

  useEffect(() => {
    if (isEditing) {
      const currentWidth = textWidthRef.current?.offsetWidth;
      if (inputRef.current) {
        inputRef.current.focus();
      }
      if (currentWidth) {
        setInputWidth(currentWidth);
      }
    }
  }, [editedText, isEditing]);

  return (
    <div ref={containerRef}>
      <div
        onClick={handleClick}
        className={clsx(
          "w-fit h-11 mx-1 mb-2 py-1 rounded-full border justify-center items-center inline-flex select-none cursor-pointer px-4",
          negativeTabColor
            ? isActive
              ? "bg-white"
              : "bg-transparent"
            : isActive
            ? "bg-neutral-700"
            : "border-neutral-600"
        )}
        title={`${editedText} tab`}
        onDoubleClick={handleDoubleClick}
      >
        {isEditing ? (
          <>
            <input
              ref={inputRef}
              type="text"
              value={editedText}
              placeholder={"text"}
              onChange={(e) => setEditedText(e.target.value.slice(0, 32))}
              maxLength={32}
              onKeyDown={handleKeyDown}
              onBlur={() => {
                setIsEditing(false);
                onRename?.(editedText);
              }}
              className="text-base text-center font-normal font-sans leading-7 text-white bg-transparent outline-none border-none"
              style={{ width: `${inputWidth}px` }}
            />
            <span
              ref={textWidthRef}
              className="absolute opacity-0 pointer-events-none"
            >
              {editedText}
            </span>
          </>
        ) : (
          <div
            className={clsx(
              "text-base font-normal font-sans leading-7",
              negativeTabColor
                ? { "text-neutral-600": isActive, "text-white": !isActive }
                : { "text-neutral-600": !isActive, "text-white": isActive }
            )}
          >
            {text ? text : "text"}
          </div>
        )}
      </div>
    </div>
  );
};

export default TabButton;
