interface TabBarProps {
  options: string[];
  selectedTab: number;
  selectedLesson: string;
  fromCreateNew: boolean;
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
  headerRef: React.MutableRefObject<any>;
}

const TabBar: React.FC<TabBarProps> = ({
  options,
  selectedTab,
  selectedLesson, 
  fromCreateNew,
  setSelectedTab,
  headerRef
}) => {
  // Handler function for the click of the tab
  const onClickHandler = (index: number) => {
    return () => {
      // Change the tab
      setSelectedTab(index)
      
      // And send the signal to change the header icon based on the new index
      if (index === 0) {
        if (selectedLesson === "courses") headerRef.current.lessons().setShowAddButton(!fromCreateNew);
        else if (selectedLesson === "projects") headerRef.current.lessons().setShowAddManageButtons(!fromCreateNew);
        else if (selectedLesson === "modules") headerRef.current.lessons().setShowAddManageButtons(!fromCreateNew);
        headerRef.current.lessons().setSaveButton({show: false, disabled: true});
      } else if (index === 1) {
        if (selectedLesson === "courses") headerRef.current.lessons().setShowAddButton(false);
        else if (selectedLesson === "projects") headerRef.current.lessons().setShowAddManageButtons(false);
        else if (selectedLesson === "modules") headerRef.current.lessons().setShowAddManageButtons(false);
        headerRef.current.lessons().setSaveButton({show: true, disabled: true});
      }
    }
  }

  return (
    <div className="w-full h-[59px] flex-col justify-center items-center gap-2 inline-flex mt-4 mb-2">
      <div className="p-1.5 bg-neutral-200 rounded-full justify-start items-center gap-1.5 inline-flex">
        {options.map((option, index) => (
          <div
            key={index}
            className={`px-6 py-3 rounded-full justify-center items-center gap-2 flex cursor-pointer ${
              selectedTab === index ? "bg-white" : ""
            }`}
            onClick={onClickHandler(index)}
          >
            <div className="text-[19px] font-semibold font-sans leading-[23px] text-dc-secondary-600 select-none">
              {option}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabBar;
