import { GoArrowLeft } from "react-icons/go";
import { MdAddCircleOutline } from "react-icons/md";
import capitaliseFirstChar from "utils/capitaliseFirstChar";
import AddRemoveCard from "./AddRemoveCard";
import {
  getCollectionRequest,
  updateRecordRequest,
} from "services/apiRequests";
import { useEffect, useState } from "react";
import { Program, Course, Project, Module } from "utils/interfaces";
import removeItem from "utils/removeItem";
import lessonInformation from "components/super-admin/lessons/utils/lessonInformation";
import EmptyViewPopUp from "components/super-admin/utils/pop-up-panels/EmptyViewPopUp";

interface AddRemovePanelProps {
  selectedData?: Program | Course | Project | Module;
  selectedLesson: string;
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  setLinkedId: React.Dispatch<React.SetStateAction<string>>;
  setLinkedLesson: React.Dispatch<React.SetStateAction<string>>;
  headerRef?: React.MutableRefObject<any>;
  lessonsListRef?: any;
}

const AddRemovePanel: React.FC<AddRemovePanelProps> = ({
  selectedData,
  selectedLesson,
  setShowPopUp,
  setLinkedId,
  setLinkedLesson,
  headerRef,
  lessonsListRef,
}) => {
  // States definition
  const [lessonData, setLessonData] = useState<
    Program[] | Course[] | Project[] | Module[]
  >([]);
  const [selectedCardsIds, setSelectedCardsIds] = useState<string[]>([]);
  const [lessonsToAdd, setLessonsToAdd] = useState<string[]>([]);
  const [lessonsToRemove, setLessonsToRemove] = useState<string[]>([]);
  const [activateConfirmChanges, setActivateConfirmChanges] = useState(false);

  /**
   * Function to get all the data of the list
   * @returns data: Program | Course | Project | Module
   */
  const getData = async () => {
    // Get the data from the database record
    const data = await getCollectionRequest(`/api/${selectedLesson}`);
    return data;
  };

  /**
   * Definition of the Add or remove action
   * @param id of the selected id in the pop up menu
   * @param action "add" or "remove" "selectedData._id" from "id"
   */
  const selectAction = (id: string, action: "add" | "remove") => {
    if (action === "add") {
      setLessonsToAdd(lessonsToAdd.concat([id])); // Or setLessonsToAdd((list) => list.concat([id]));
    } else {
      // Remove from the new Lessons list
      setLessonsToAdd(removeItem(lessonsToAdd, id)); // Or setLessonsToAdd((list) => removeItem(list, id));
      // But check if it's being removed from the previous selected (database) as well
      if (selectedCardsIds.includes(id))
        setLessonsToRemove(lessonsToRemove.concat([id]));
    }
  };

  /**
   * Function used to apply the changes made in this window
   */
  const handleConfirmChange = async () => {
    // Check if the selectedData exists
    if (!selectedData) return;
    // Define the interest lesson to be applied
    const interestLesson = lessonInformation(selectedLesson).parent;

    // Add the data to be added
    const responseAdd = await updateRecordRequest(
      {
        _id: selectedData._id,
        toUpdate: { $addToSet: { [selectedLesson]: lessonsToAdd } },
      },
      `/api/${interestLesson}`
    );

    const responseRemove = await updateRecordRequest(
      {
        _id: selectedData._id,
        toUpdate: { $pullAll: { [selectedLesson]: lessonsToRemove } },
      },
      `/api/${interestLesson}`
    );

    // And update the selected data
    await updateSelectedData();

    // If everything goes well, close this pop up
    setShowPopUp(false);
  };

  /**
   * Function used to update the selectedData state to view the changes
   * applied at the end of the "Confirm change" process
   */
  const updateSelectedData = async () => {
    // Check if the selectedData exists
    if (!selectedData || !lessonsListRef) return;

    // Define the interest lesson to be applied
    const interestLesson = lessonInformation(selectedLesson).parent;

    // Get the required data
    const populateOptions = interestLesson === "programs" ? [{
      path: "courses",
      select: ["_id", "title"]
    }] : []
    const updatedData = await getCollectionRequest(
      `/api/${interestLesson}`,
      [],
      {
        _id: selectedData._id,
      },
      populateOptions
    );

    // Check if the query got a result
    if (updatedData.length === 0) return;

    // Update the selectedData
    lessonsListRef.current.setSelectedData(updatedData[0]);
  };

  /**
   * Select create new inside the pop up
   */
  const handleCreateNew = () => {
    // Check if the header reference is included
    if (!headerRef) return;

    // Define the contextual parent lesson to be used when clicking
    // "Create new" from the "add-remove/manage" lessons pop up window
    if (selectedData){
      setLinkedId(selectedData ? selectedData._id : "");
      setLinkedLesson(lessonInformation(selectedLesson).parent);
    }

    // Navigate to create a new lesson
    if (selectedLesson === "programs")
      headerRef.current.lessons().pressCreateNewProgram();
    else if (selectedLesson === "courses")
      headerRef.current.lessons().pressCreateNewCourse();
    else if (selectedLesson === "projects")
      headerRef.current.lessons().pressCreateNewProject();
    else if (selectedLesson === "modules")
      headerRef.current.lessons().pressCreateNewModule();

    // And close the pop up
    setShowPopUp(false);
  };

  /**
   * Load lesson data when init
   */
  useEffect(() => {
    getData().then((data: Program[] | Course[] | Project[] | Module[]) => {
      // Set the data
      setLessonData(data);

      // Define the currently selected cards
      if (
        selectedData &&
        ["programs", "courses", "projects", "modules"].includes(selectedLesson)
      ) {
        // Done this way to avoid the TypeScript lint
        let selCards: any =
          selectedData[selectedLesson as keyof typeof selectedData];

        // Particular case of courses (populated)
        if (selectedLesson === "courses")
          selCards = selCards ? selCards.map((item: Course) => item._id) : [];

        setSelectedCardsIds(selCards || []);
      }
    });
  }, []);

  /**
   * Check if there is any new change to apply to activate the "confirm changes" button
   */
  useEffect(() => {
    if (lessonsToAdd.length === 0 && lessonsToRemove.length === 0) {
      setActivateConfirmChanges(false);
    } else {
      setActivateConfirmChanges(true);
    }
  }, [lessonsToAdd, lessonsToRemove]);

  return (
    <>
      <div className="self-stretch justify-between items-center inline-flex">
        <div className="justify-start items-center gap-3 flex">
          <div
            className="p-1.5 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center gap-2 flex cursor-pointer"
            onClick={() => setShowPopUp(false)}
          >
            <div className="w-5 h-5 justify-center items-center flex">
              <div className="w-5 h-5 relative">
                <GoArrowLeft className="w-full h-full fill-dc-secondary-600" />
              </div>
            </div>
          </div>
          <div className="text-neutral-600 text-lg font-normal font-sans leading-snug">
            Add/Remove {capitaliseFirstChar(selectedLesson)}
          </div>
        </div>
        <div className="p-2 opacity-0 bg-neutral-50 rounded-full justify-start items-start gap-2 flex">
          <div className="w-4 h-4 p-[3.61px] justify-center items-center flex" />
        </div>
        <div
          className="rounded justify-center items-center gap-2 flex cursor-pointer"
          onClick={handleCreateNew}
        >
          <div className="w-6 h-6 relative">
            <MdAddCircleOutline className="w-full h-full fill-dc-secondary-600" />
          </div>
          <div className="text-dc-secondary-600 text-lg font-semibold font-sans leading-snug">
            Create new
          </div>
        </div>
      </div>
      <div className="max-h-[400px] grow shrink justify-start items-start gap-[5px] inline-flex overflow-y-auto pop-up-scrollbar pr-[5px]">
        <div className="w-[403px] h-fit flex-col justify-start items-start gap-4 inline-flex">
          {lessonData.length > 0 ? (
            lessonData.map((item: any, index: number) => (
              <AddRemoveCard
                key={index}
                id={item._id}
                title={item.title}
                level={item.level}
                points={item.experiencePoints}
                language={item.language}
                previouslyAdded={selectedCardsIds.includes(item._id)}
                selectAction={selectAction}
              />
            ))
          ) : (
            <EmptyViewPopUp />
          )}
        </div>
      </div>
      {activateConfirmChanges ? (
        <div
          className="self-stretch px-5 py-3 bg-dc-secondary-600 rounded justify-center items-center gap-2 inline-flex cursor-pointer"
          onClick={handleConfirmChange}
        >
          <div className="text-white text-lg font-semibold font-sans leading-snug">
            Confirm Changes
          </div>
        </div>
      ) : (
        <div className="self-stretch px-5 py-3 bg-Subtle rounded justify-center items-center gap-2 inline-flex cursor-pointer">
          <div className="text-neutral-400 text-lg font-semibold font-sans leading-snug">
            Confirm Changes
          </div>
        </div>
      )}
    </>
  );
};

export default AddRemovePanel;
