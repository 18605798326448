import ViewSearchBar from "./ViewSearchBar";
import ViewTabBar from "./ViewTabBar";

interface ViewHeaderProps {
  userRole: string;
  activeItem: string;
  searchQuery: string;
  tabOptions: string[];
  setActiveItem: React.Dispatch<React.SetStateAction<string>>;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  headerRef: React.MutableRefObject<any>;
}

const ViewHeader: React.FC<ViewHeaderProps> = ({
  userRole,
  activeItem,
  searchQuery,
  tabOptions,
  setActiveItem,
  setSearchQuery,
  headerRef,
}) => {
  // Boolean to define if the tab options should be included
  const tabOptionsExists =
    ["master admin", "customer admin"].includes(userRole) &&
    tabOptions.length > 0;

  return (
    <>
      <div className="self-stretch justify-between items-center inline-flex">
        {tabOptionsExists && (
            <ViewTabBar
              activeItem={activeItem}
              tabOptions={tabOptions}
              setActiveItem={setActiveItem}
              headerRef={headerRef}
            />
          )}
        <ViewSearchBar
          userRole={userRole}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          tabOptionsExists={tabOptionsExists}
        />
      </div>
    </>
  );
};

export default ViewHeader;
