import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { Message } from './Assistant';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import AIAvatar from "../../assets/imgs/AIAvatar.png";

interface ChatMessageParams {
  index: number;
  message?: Message | any;
  strikethrough?: boolean;
}

const ChatMessage: React.FC<ChatMessageParams> = ({index, message, strikethrough = false}) => {
  return (
    <motion.div className="pb-6 flex w-full">
      {message.role === 'user' && (
        <div className="w-[72px] h-[72px] rounded-full bg-dc-accent-50 border-dc-accent-300 border flex items-center justify-center flex-none">
          <span className="text-4xl font-bold text-dc-secondary-600">KS</span>
        </div>
      )}
      <div 
        className={`p-3 h-fit border rounded-2xl ${
          message.role === 'assistant' 
            ? 'w-full mr-4 bg-dc-secondary-50 border border-dc-primary-300 rounded-tr-none' 
            : 'w-[calc(100%-165px)] ml-4 mr-[175px] bg-dc-accent-50 border border-dc-accent-300 rounded-tl-none'
        }`}
      >
        <AnimatePresence>
          {message.content !== undefined ? (
            <>
              <motion.div
                className={`min-h-6 max-w-full break-words flex flex-col gap-3 ${strikethrough ? "line-through" : ""}`}
                id={`chat-message-${index}`}
                key={`chat-message-${index}`}
                initial={{ height: 84, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.1 }}
              >
                {message.role === "assistant" ? (
                  <Markdown remarkPlugins={[remarkGfm]}>{message.content}</Markdown>
                ): (
                  <>{message.content}</>
                )}
              </motion.div>
            </>
          ) : (
            <>
              <motion.div
                id={`chat-message-${index}`}
                key={`chat-message-${index}`}
                initial={{ height: 84, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.1 }}
              >
                <div className="h-5 w-full bg-dc-secondary-200/80 rounded animate-pulse duration-1000" />
                <div className="h-5 w-full bg-dc-secondary-200/80 rounded my-3 animate-pulse duration-1000 delay-100" />
                <div className="w-3/4 h-5 bg-dc-secondary-200/80 rounded animate-pulse duration-1000 delay-200" />
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </div>
      {message.role === 'assistant' && (
        <div className="w-[72px] h-[72px] rounded-full bg-dc-secondary-50 border border-dc-primary-300 flex items-center justify-center flex-none">
          <img src={AIAvatar} alt="AI Avatar" className="w-full h-full rounded-full" />
        </div>
      )}
    </motion.div>
  )
}

export default ChatMessage;
