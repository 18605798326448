import { ChangeEvent, useEffect, useState } from "react";
import { MdOutlinePublish } from "react-icons/md";
import ProjectPlanCard from "./ProjectPlanCard";
import base64toBlob from "utils/base64toBlob";
import { Course, Program, Project, Module } from "utils/interfaces";
import { BACKEND_URL } from "utils/getUrl";

const API_URL = BACKEND_URL;

interface InputPlanFileProps {
  selectedLesson: string;
  selectedData: Project | Program | Course | Module | null;
  planDocument: string | null;
  planName: string;
  planSize: string;
  setPlanName: React.Dispatch<React.SetStateAction<string>>;
  setPlanSize: React.Dispatch<React.SetStateAction<string>>;
  setPlanDocument: React.Dispatch<React.SetStateAction<string | null>>;
}

const InputPlanFile: React.FC<InputPlanFileProps> = ({
  selectedLesson,
  selectedData,
  planName,
  planSize,
  setPlanName,
  setPlanSize,
  planDocument,
  setPlanDocument,
}) => {
  // Parameters definition
  const formatsAllowed = ["application/pdf"];
  const maxFileSize = 10 * 1024 * 1024; // 10 MB

  /**
   * Function to handle PDF plan uploading
   * @param event
   */
  const handlePDFUpload = (event: ChangeEvent<HTMLInputElement>) => {
    // Get the file
    const file = event.target.files?.[0];

    // Check if there is an inputted file
    if (!file) {
      alert("No file was selected.");
      return;
    }

    // Check if the of the uploaded file is the defined in the
    // formats list allowed for this placeholder
    if (!formatsAllowed.includes(file.type)) {
      alert(
        `Format "${file.type}" is not allowed. Please try with a PDF file.`
      );
      return;
    }
    
    // Check that the size is the allowed one
    if (file.size > maxFileSize) {
      const sizeInMb = (file.size / 1024 / 1024).toFixed(2);
      alert(`File size (${sizeInMb} MB) is larger than allowed.`);
      return;
    }

    // Perform the loading
    const reader = new FileReader();
    reader.onloadend = () => {
      setPlanDocument(reader.result as string);
    };
    reader.readAsDataURL(file);

    // Set the filename of the file uploaded
    setPlanName(file.name);

    // And set the size of the file
    const fileSizeKB = file.size / 1024;
    const fileSizeMB = fileSizeKB / 1024;
    setPlanSize(fileSizeMB.toFixed(1)); // toFixed returns a string
  };

  /**
   * Function used when pressing the delete button on ProjectPlanCard
   */
  const removePlan = () => {
    setPlanDocument("");
    setPlanName("");
    setPlanSize("0");
  };

  /**
   * Function to apply on click name
   */
  const onClickName = () => {
    if (planDocument) {
      const blob = base64toBlob(planDocument.split(",")[1]);
      console.log(blob)
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
    } else if (!!selectedData) {
      window.open(`${API_URL}/files/module/pdf/${selectedData._id}`);
    }
  }

  return (
    <>
      {planName ? (
        <ProjectPlanCard
          filename={planName}
          planSize={planSize}
          removePlan={removePlan}
          onClickName={onClickName}
        />
      ) : (
        <div className="self-stretch px-2 py-4 rounded-xl border border-dashed border-neutral-300 justify-center items-center gap-2 inline-flex relative">
          <div className="w-10 h-10 relative">
            <MdOutlinePublish className="w-full h-full fill-neutral-500" />
          </div>
          <input
            type="file"
            accept="application/pdf"
            onChange={handlePDFUpload}
            className="hidden"
            id="project-plan-upload"
          />
          {/* Label used to hide the on-hover text popup, also helps fix some alignment issues */}
          <label
            id="project-plan-upload-label"
            htmlFor="project-plan-upload"
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          />
          <div className="flex-col justify-center items-start gap-0.5 inline-flex">
            <div className="justify-start items-center gap-2 inline-flex">
              <div className="text-neutral-600 text-xs font-normal font-sans leading-[14px]">
                Upload a {selectedLesson.slice(0, -1)} plan
              </div>
            </div>
            <div className="justify-start items-center gap-2 inline-flex">
              <div className="text-neutral-500 text-[10px] font-sans leading-3 tracking-tight">
                <span className="font-normal">(File type: </span>
                <span className="font-semibold">PDF</span>
                <span className="font-normal">, Max. file size: </span>
                <span className="font-semibold">10 MB</span>
                <span className="font-normal">)</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InputPlanFile;
