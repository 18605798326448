import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import BasicDetails from "./BasicDetails";
import {
  ClassListType,
  ContactListType,
  LocationListType,
  SchoolListType,
} from "utils/interfaces";
import ProgressMap from "components/super-admin/utils/ProgressMap";
import LocationDetails from "./LocationDetails";
import ClassDetails from "./ClassDetails";
import { createRecordRequest, updateRecordRequest } from "services/apiRequests";
import { formatDataSchool } from "utils/formatUpdate";

interface AddNewSchoolProps {
  ref: any;
  userRole: string;
  selectedData?: SchoolListType | null;
  getData: () => Promise<void>;
  setShowSavedSuccessfully: React.Dispatch<React.SetStateAction<boolean>>;
  headerRef: React.MutableRefObject<any>;
  from?: "main" | "dashboard";
}

const AddNewSchool: React.FC<AddNewSchoolProps> = forwardRef(
  ({ userRole, selectedData, getData, headerRef, setShowSavedSuccessfully, from = "dashboard" }, ref) => {
    // States definition
    const [stage, setStage] = useState(1);
    const [schoolName, setSchoolName] = useState("");
    const [badgeImage, setBadgeImage] = useState<string | null>("");
    const [assignedLicenses, setAssignedLicenses] = useState(0);
    const [selectedContacts, setSelectedContacts] = useState<ContactListType[]>(
      []
    );
    const [locations, setLocations] = useState<LocationListType[]>([]);
    const [selectedClasses, setSelectedClasses] = useState<ClassListType[]>([]);
    // Pop up states
    const [activateSaveButton, setActivateSaveButton] = useState(false);

    /**
     * Functions to externalise using refs
     */
    useImperativeHandle(ref, () => ({
      // Function to present all the gathered data in the form
      provideData() {
        return provideData();
      },
      handleSaveSchool() {
        handleSave();
      },
    }));

    /**
     * If there is selected data, then prefill the defined fields
     */
    useEffect(() => {
      if (selectedData) {
        setSchoolName(selectedData.name || "");
        setBadgeImage(selectedData.logo || "");
        setAssignedLicenses(selectedData.licenses || 0);
        setSelectedContacts(selectedData.contacts || "");
        setLocations(selectedData.locations || []);
        setSelectedClasses(selectedData.classes || []);
      }
    }, []);

    /**
     * Function to activate saveButtons
     */
    useEffect(() => {
      // Define the current handler
      let currentHandler;
      if (from === "main"){
        currentHandler = headerRef.current.customers();
      } else if (from === "dashboard") {
        currentHandler = headerRef.current.dashboard();
      }

      // Condition definition
      currentHandler.setSaveButton({
        show: true,
        disabled: !(schoolName && selectedContacts.length > 0),
      });
      setActivateSaveButton(!!(schoolName && selectedContacts.length > 0));
    }, [schoolName, selectedContacts]);

    /**
     * Function to handle save pressing
     */
    const handleSave = async () => {
      // First of all, check if the save button should be working
      if (!activateSaveButton) return;
      
      // Get the data from the settings form
      const data = provideData();

      // Request for create/edit new school
      let response = { successful: false, message: "" };
      if (selectedData) {
        response = await updateRecordRequest(
          { _id: selectedData._id, toUpdate: data },
          "/api/organisations"
        );
      } else {
        // Create a new school
        response = await createRecordRequest(data, "/api/organisations");
      }

      // Check if the response is successful
      if (!response.successful) {
        alert(response.message);
        return;
      }

      // Define the current handler
      let currentHandler;
      if (from === "main"){
        currentHandler = headerRef.current.customers();
      } else if (from === "dashboard") {
        currentHandler = headerRef.current.dashboard();
      }

      // Once pressed, come back to the root view
      currentHandler.onDiscard();
      setShowSavedSuccessfully(true);

      // And refresh the data
      await getData();
    };

    /**
     * Function that returns all the form data
     * @returns data
     */
    const provideData = () => {
      // Get just the ids from the contacts
      const contactIds = selectedContacts.map(
        (contact: ContactListType) => contact._id
      );
      // Get just the ids from the classes
      const classIds = selectedClasses.map((item: ClassListType) => item._id);

      // Return all the gathered data in the form
      const dataToProvide = {
        name: schoolName,
        logo: badgeImage,
        licenses: assignedLicenses,
        contacts: contactIds,
        locations: locations,
        classes: classIds,
      };

      // Formatting the data to be sent as a request
      const formattedData = formatDataSchool(selectedData, dataToProvide);
      
      return formattedData;
    };

    return (
      <div className="w-full h-full flex justify-center overflow-y-auto custom-scroll">
        {stage === 1 ? (
          <BasicDetails
            userRole={userRole}
            schoolName={schoolName}
            badgeImage={badgeImage}
            assignedLicenses={assignedLicenses}
            selectedContacts={selectedContacts}
            setSchoolName={setSchoolName}
            setBadgeImage={setBadgeImage}
            setAssignedLicenses={setAssignedLicenses}
            setSelectedContacts={setSelectedContacts}
            setStage={setStage}
          />
        ) : stage === 2 ? (
          <LocationDetails
            locations={locations}
            setLocations={setLocations}
            setStage={setStage}
          />
        ) : (
          <ClassDetails
            activateSaveButton={activateSaveButton}
            selectedClasses={selectedClasses}
            handleSave={handleSave}
            setStage={setStage}
            setSelectedClasses={setSelectedClasses}
            headerRef={headerRef}
          />
        )}
        <ProgressMap
          stage={stage}
          firstText="Basic details"
          secondText="Location details"
          thirdText="Class details"
        />
      </div>
    );
  }
);

export default AddNewSchool;
