import { useParams } from "react-router-dom";
import { BACKEND_URL } from "utils/getUrl";

const API_URL = BACKEND_URL;

interface PDFViewerType {
  isPaning: boolean;
  isVisible: boolean;
}

const PDFViewer: React.FC<PDFViewerType> = ({ isPaning, isVisible }) => {
  const {moduleOrProject, id} = useParams();

  const fileUrl = `${API_URL}/files/${moduleOrProject}/pdf/${id}`;

  return (
    <div
      className={`${isPaning ? "pointer-events-none" : ""} ${
        isVisible ? "w-full h-full p-4 pt-0" : "w-0 h-0 p-0 invisible"
      }`}
    >
      {/* More reference parameters for pdf: https://stackoverflow.com/questions/20562543/zoom-to-fit-pdf-embedded-in-html*/}
      <iframe
        src={fileUrl + "#view=FitH&toolbar=0"}
        className="w-full h-full custom-scroll"
      ></iframe>
    </div>
  );
};
export default PDFViewer;
