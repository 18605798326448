import React from 'react'
import { MdArrowForward, MdOutlineAddToPhotos, MdOutlineEdit } from 'react-icons/md';
import { QuizType } from 'utils/interfaces';
import image from 'assets/imgs/PLACEHOLDERquizIcon.png'

interface QuizCardProps {
  userRole: string;
  quiz: QuizType;
  handleExpand: (event: React.MouseEvent) => void;
  handleEdit: (event: React.MouseEvent) => void;
  handleAddToClasses: (event: React.MouseEvent) => void;
}

const QuizCard: React.FC<QuizCardProps> = ({
  userRole,
  quiz,
  handleExpand,
  handleEdit,
  handleAddToClasses,
}) => {
  return (
    <div className="w-full h-[108px] px-6 py-5 bg-neutral-25 rounded-xl border border-neutral-100 justify-between items-center inline-flex">
      <div className="h-[68px] justify-start items-center gap-3 flex">
        <img
          src={image}
          alt={quiz.title || ""}
          className="w-[68px] h-[68px] rounded-full"
        />
        <div className="grow shrink basis-0 flex-col justify-center items-start gap-1 inline-flex">
          <div className="self-stretch text-neutral-700 text-lg font-semibold font-['Poppins'] leading-snug">{quiz.title}</div>
          <div className="self-stretch text-neutral-600 text-xs font-normal font-['Poppins'] leading-[18px]">{(quiz.questions ? quiz.questions.length : 0)} question{(quiz.questions ? quiz.questions.length : 0) > 1 && "s"}</div>
        </div>
      </div>
      <div className="flex justify-end items-center gap-5">
        {/* {userRole === "master admin" && (
          <div className="w-12 self-stretch justify-center items-center gap-3 flex">
            <div
              className="p-3 bg-neutral-100 rounded-full justify-start items-start gap-2 flex cursor-pointer z-20"
              onClick={handleEdit}
            >
              <div className="w-6 h-6">
                <MdOutlineEdit className="w-full h-full fill-neutral-700" />
              </div>
            </div>
          </div>
        )} */}
        {/* {["customer admin", "master admin"].includes(userRole) && (
          <div className="w-12 self-stretch justify-center items-center gap-3 flex">
            <div
              className="p-3 bg-neutral-100 rounded-full justify-start items-start gap-2 flex cursor-pointer"
              onClick={handleAddToClasses}
            >
              <div className="w-6 h-6">
                <MdOutlineAddToPhotos className="w-full h-full fill-neutral-700" />
              </div>
            </div>
          </div>
        )} */}
        {["parent", "student", "master admin"].includes(userRole) && (
          <div className="w-12 self-stretch justify-center items-center gap-3 flex">
            <div
              className="p-3 bg-neutral-100 rounded-full justify-start items-start gap-2 flex cursor-pointer"
              onClick={handleExpand}
            >
              <div className="w-6 h-6">
                <MdArrowForward className="w-full h-full fill-neutral-700" />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default QuizCard