/**
 * URLErrorMessage.tsx
 * ------------------
 * Page that appears when the registration process has been completed
 * without problems.
 */
import React from "react";
import { MdCancel } from "react-icons/md";
import { Link } from "react-router-dom";

interface ErrorPageProps {
  text?: string;
}

const URLErrorMessage: React.FC<ErrorPageProps> = ({ text = "page" }) => {
  return (
    <div className="py-10 flex justify-center items-center flex-col">
      <MdCancel className="gap-1 px-2 font-semibold text-red-500 rounded-md text-[200px]" />
      <p className="py-5 text-3xl">This {text} is not available</p>
      <p className="py-5">
        <Link
          to="/"
          className="underline text-blue-500 underline-offset-2 text-2xl"
        >
          Click here to return to Home
        </Link>
      </p>
    </div>
  );
};

export default URLErrorMessage;
