import { GoCheckCircleFill } from "react-icons/go";
import { MdLocationOn } from "react-icons/md";

interface JourneyBadgeProps {
  title: string;
  badgeImage: string;
  completed: boolean;
  toComplete: boolean;
}

const JourneyBadge: React.FC<JourneyBadgeProps> = ({
  title,
  badgeImage,
  completed,
  toComplete,
}) => {
  return (
    <div className="w-[103px] h-[120px] self-stretch flex-col justify-center items-center gap-2 inline-flex relative">
      <div className="w-16 h-16 rounded-full border border-neutral-200 bg-white relative overflow-hidden">
        {completed && (
          <div className="w-full h-full absolute rounded-full bg-neutral-50/70 backdrop-blur-sm justify-center items-center inline-flex">
            <div className="w-8 h-8 relative">
              <GoCheckCircleFill className="w-full h-full fill-success-700" />
            </div>
          </div>
        )}
        <img
          src={badgeImage || "logo.svg"}
          className="w-full h-full rounded-full"
        />
      </div>
      <div className="grow shrink text-center text-neutral-500 text-[10px] font-semibold font-sans leading-3 tracking-tight overflow-auto">
        {title}
      </div>
      
      {toComplete && (
          <div className="absolute right-1 -top-3.5">
            <div className="w-8 h-8 relative">
              <MdLocationOn className="w-full h-full fill-warning-500" />
            </div>
          </div>
        )}
    </div>
  );
};

export default JourneyBadge;
