import watermarkImage2 from "assets/imgs/watermark2.png";

interface MyBadgesProps {
  badges: string[];
}

const MyBadges: React.FC<MyBadgesProps> = ({ badges }) => {
  return (
    <div className="self-stretch h-[390px] bg-neutral-25 rounded-3xl flex-col justify-between items-start flex overflow-hidden relative">
      <img
        className="absolute w-[390px] h-[390px] right-[-140px] rotate-[3.62deg] opacity-20 select-none"
        src={watermarkImage2}
      />
      <div className="self-stretch p-6 items-start inline-flex">
        {badges.map((badge: string, index: number) => (
            <img
              key={index}
              className="w-16 h-16 rounded-full shadow border border-neutral-200 bg-white relative"
              style={{right: index * 40 }}
              src={badge || "/logo.svg"}
            />
          ))}
      </div>
      <div className="self-stretch p-6 justify-between items-end inline-flex">
        <div className="grow shrink basis-0 flex-col justify-start items-start gap-4 inline-flex">
          <div className="self-stretch relative">
            <div className="text-neutral-700 text-[33px] font-semibold font-sans leading-10">
              {badges.length || 0}
            </div>
          </div>
          <div className="self-stretch h-fit flex-col justify-start items-start gap-2 flex">
            <div className="self-stretch text-neutral-700 text-lg font-normal font-sans leading-snug">
              My Badges
            </div>
            <div className="self-stretch text-neutral-500 text-xs font-normal font-sans leading-[14px]">
              You have earned {badges.length} badges so far
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyBadges;
