import { ClassListType, ContactListType, ParentListType, StudentListType, TeacherListType } from "utils/interfaces";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import ClassesListCards from "../../classes/cards/ClassesListCards";
import ClassDashboard from "./ClassDashboard";
import { AnimatePresence, motion } from "framer-motion";
import PopUpPanel from "../../classes/pop-up-panels/PopUpPanel";
import AddNewStudent from "../../classes/add-new-student-view/AddNewStudent";
import { getProcessedClasses } from "services/classesRequests";

interface CustomerAdminDashboard {
  ref?: any;
  userRole: string;
  headerRef: React.MutableRefObject<any>;
  subHeaderRef: React.MutableRefObject<any>;
  backPressedToggle: boolean;
}

const CustomerAdminDashboard: React.FC<CustomerAdminDashboard> = forwardRef(
  ({ userRole, headerRef, subHeaderRef, backPressedToggle }, ref) => {
    // States definition
    const [classes, setClasses] = useState<ClassListType[]>([]);
    const [selectedData, setSelectedData] = useState<
      ClassListType | StudentListType | TeacherListType | ParentListType | null
    >(null);
    const [createNewStudent, setCreateNewStudent] = useState(false);
    const [showCreateNewClass, setShowCreateNewClass] = useState(false);
    const [showSavedSuccessfully, setShowSavedSuccessfully] = useState(false);
    // State for view pop up
    const [selectedTeachers, setSelectedTeachers] = useState<ContactListType[]>(
      []
    );
    const [showTeachersList, setShowTeachersList] = useState(false);

    /**
     * Root definition
     */
    useEffect(() => {
      getData();
    }, []);

    // States definition
    const addStudentRef = useRef(null);

    /**
     * Define the list of functions that can be used from external components
     * using a reference
     */
    useImperativeHandle(ref, () => ({
      setSelectedData(data: ClassListType) {
        setSelectedData(data);
        if (data.name !== null)
          subHeaderRef.current.setSubHeaderTitle(data.name);
      },
      onDiscard() {
        setSelectedData(null);
      },
      onBackClick() {
        backButtonFunction();
      },
      setShowCreateNewClass(bool: boolean) {
        setShowCreateNewClass(bool);
      },
      setCreateNewStudent(bool: boolean) {
        setCreateNewStudent(bool);
      },
      handleSaveNewStudent() {
        let currentHandler: any;
        if (addStudentRef.current) currentHandler = addStudentRef.current;
        currentHandler.handleSaveNewStudent();
      },
    }));

    /**
     * Every time is rendered
     */
    useEffect(() => {
      // Display the bar
      subHeaderRef.current.setShow(true);
      
      // Define the title and description for customer admin
      if (userRole === "customer admin") {
        headerRef.current.setHeaderTitle("Dashboard")
        headerRef.current.setHeaderDescription("Welcome, Customer Admin");
      } 
      
      // Set the subheader text
      subHeaderRef.current.setSubHeaderTitle("Overview");
      // Hide the subheader back button
      subHeaderRef.current.setShowBackButton(false);
    }, [])

    /**
     * Function to execute on back click
     */
    const backButtonFunction = () => {
      // And set any selectedData to null
      setSelectedData(null);

      // Set the subheader text
      subHeaderRef.current.setSubHeaderTitle("Overview");
      // Hide the subheader back button
      subHeaderRef.current.setShowBackButton(false);
    }

    /**
     * Show the create new button based on the selectedData state
     */
    useEffect(() => {
      // Keep it hidden when selectedData is not null or undefined
      headerRef.current.dashboard().setShowCreateNewButton(!selectedData);

      // If we have selected data
      if (selectedData) {
        // Change the sub header title
        subHeaderRef.current.setSubHeaderTitle((selectedData as ClassListType).name);
      } 
    }, [selectedData]);

    const handleClassClick = (card: ClassListType) => {
      setSelectedData(card);
      subHeaderRef.current.setShowBackButton(true);
      headerRef.current.dashboard().setShowCreateNewButton(false);
    };

    /**
     * Everytime the backPressedToggle signal is activated, we come back to the root view
     */
    // useEffect(() => {
    //   // Once pressed, come back to the root view
    //   headerRef.current.dashboard().onDiscard();
    //   // And set any selectedData to null
    //   setSelectedData(null);
    // }, [backPressedToggle]);

    /**
     * Function to get the data depending on the class
     * @returns data
     */
    const getData = async () => {
      const processed = await getProcessedClasses();
      setClasses(processed);
    };

    return (
      <>
        <div className="w-full h-full relative overflow-hidden">
          {!createNewStudent && (
            <>
              <AnimatePresence initial={false}>
                {selectedData === null && (
                  <motion.div
                    key={`${selectedData === null}`}
                    initial={{ x: "calc(-100% - 1.5rem)" }}
                    animate={{ x: 0 }}
                    exit={{ x: "-100%" }}
                    transition={{ ease: "linear", duration: 0.5 }}
                    className="absolute w-full h-full"
                  >
                    <ClassesListCards
                      classes={classes}
                      searchQuery={""}
                      canEdit={false}
                      setSelectedTeachers={setSelectedTeachers}
                      setShowTeachersList={setShowTeachersList}
                      onCardClick={handleClassClick}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
              <AnimatePresence>
                {selectedData !== null && (
                  <motion.div
                    key={`${selectedData === null}`}
                    initial={{ x: "100%" }}
                    animate={{ x: 0 }}
                    exit={{ x: "100%" }}
                    transition={{ ease: "linear", duration: 0.5 }}
                    className="absolute w-full h-full"
                  >
                    <ClassDashboard
                      headerRef={headerRef}
                      classData={selectedData}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </>
          )}
          {createNewStudent && (
            <AddNewStudent
              ref={addStudentRef}
              getData={getData}
              selectedData={selectedData as StudentListType}
              setShowSavedSuccessfully={setShowSavedSuccessfully}
              headerRef={headerRef}
              from="dashboard"
            />
          )}
        </div>
        {/* Definition of the possible pop up panels in this view */}
        <PopUpPanel
          type="create-class"
          showPopUp={showCreateNewClass}
          getData={getData}
          setShowPopUp={setShowCreateNewClass}
          headerRef={headerRef}
        />
        {/* Saved successful message after  */}
        <PopUpPanel
          type="saved-successful"
          showPopUp={showSavedSuccessfully}
          setShowPopUp={setShowSavedSuccessfully}
          headerRef={headerRef}
        />
        {/* When pressing view all on a card */}
        <PopUpPanel
          type="view-class-teachers"
          selectedTeachers={selectedTeachers}
          showPopUp={showTeachersList}
          setShowPopUp={setShowTeachersList}
        />
      </>
    );
  }
);

export default CustomerAdminDashboard;
