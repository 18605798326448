import { Course, Project, StudentType } from "utils/interfaces";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import PopUpPanel from "../pop-up-panels/PopUpPanel";
import capitaliseFirstChar from "utils/capitaliseFirstChar";
import MyProgress from "./MyProgress";
import MyKnowledgePoints from "./MyKnowledgePoints";
import MyBadges from "./MyBadges";
import MyLearningJourney from "./MyLearningJourney";
import { getCollectionRequest } from "services/apiRequests";
import Leaderboard from "../utils/leaderboard";

interface StudentDashboardProps {
  ref?: any;
  headerRef: React.MutableRefObject<any>;
}

const StudentDashboard: React.FC<StudentDashboardProps> = forwardRef(
  ({ headerRef }, ref) => {
    // States definition
    const [showProfilePopUp, setShowProfilePopUp] = useState(false);
    const [userFirstName, setUserFirstName] = useState("Kyah");
    const [userLastName, setUserLastName] = useState("Symons");
    const [searchQuery, setSearchQuery] = useState("");
    // States for the learning journey and the badges
    const [courses, setCourses] = useState<Course[]>([]);
    const desiredDisplayCourseTitle = "Scratch Coding Skills";
    const [displayCourseIndex, setDisplayCourseIndex] = useState(3);
    // Current display
    const [courseName, setCourseName] = useState("");
    const [projectsToDisplay, setProjectsToDisplay] = useState<Project[]>([]);
    // State used to keep loaded information
    const [projectsInCourses, setProjectsInCourses] = useState<
      (Project | null)[]
    >([]);
    const [badges, setBadges] = useState<string[]>([]);

    // Hardcoded parameter for demo purposes
    const numBadges = 5;

    /**
     * Set the header image name
     */
    useEffect(() => {
      // Update the header name when dashboard is selected
      let currentHandler: any;
      if (headerRef.current) currentHandler = headerRef.current;

      // Set the name
      currentHandler.dashboard().setFirstName(userFirstName);
      currentHandler.dashboard().setLastName(userLastName);

      // And set the title and subtitle
      currentHandler.setHeaderTitle("My Dashbord");
      currentHandler.setHeaderDescription(
        `Welcome, ${capitaliseFirstChar(userFirstName)}`
      );
    }, []);

    /**
     * Define the list of functions that can be used from external components
     * using a reference
     */
    useImperativeHandle(ref, () => ({
      // Outer function to change the tab
      setShowProfilePopUp(bool: boolean) {
        setShowProfilePopUp(bool);
      },
      getFirstName() {
        return userFirstName;
      },
      getLastName() {
        return userLastName;
      },
    }));

    const students: StudentType[] = [
      {
        id: 1,
        firstName: "Yakkety",
        lastName: "Yak",
        email: "",
        dateOfBirth: "",
        gender: "",
        password: "",
        passwordSalt: "",
        lastActiveAt: "",
        progressRate: 82,
        aptitudeRate: 92,
        status: "Active",
      },
      {
        id: 2,
        firstName: "Zesty",
        lastName: "Zapus",
        email: "",
        dateOfBirth: "",
        gender: "",
        password: "",
        passwordSalt: "",
        lastActiveAt: "",
        progressRate: 67,
        aptitudeRate: 78,
        status: "Active",
      },
      {
        id: 3,
        firstName: "Artful",
        lastName: "Aardvark",
        email: "",
        dateOfBirth: "",
        gender: "",
        password: "",
        passwordSalt: "",
        lastActiveAt: "",
        progressRate: 91,
        aptitudeRate: 88,
        status: "Active",
      },
    ];

    const activityData = [
      { language: "Javascript", userPerformance: 8, classAverage: 5 },
      { language: "Scratch", userPerformance: 6, classAverage: 3 },
      { language: "Python", userPerformance: 7, classAverage: 5 },
    ];

    /**
     * Get courses function for my learning journey
     */
    const getCourse = async () => {
      const data: Course[] = await getCollectionRequest("/api/courses", [
        "title",
        "projects",
      ]);

      setCourses(data);
      const courseIndex = data.findIndex((course: Course) => course.title === desiredDisplayCourseTitle)
      if (courseIndex >= 0) setDisplayCourseIndex(courseIndex);

      return data;
    };

    /**
     * Get data function for my learning journey
     */
    const getProjects = async (projectIds: string[]) => {
      const data = await getCollectionRequest(
        "/api/projects",
        ["title", "badgeImage"],
        { _id: { $in: projectIds } }
      );

      setProjectsToDisplay(data);

      return data;
    };

    /**
     * Get the data
     */
    useEffect(() => {
      // Once loaded the data, display a course based on the initial
      // state definition
      getCourse().then((data: Course[]) => {
        // Get the course
        if (data[displayCourseIndex]) {
          setCourseName(data[displayCourseIndex].title);

          // Get the projects of the selected course
          getProjects(data[displayCourseIndex].projects as string[]).then(
            (projects) => setProjectsToDisplay(projects)
          );
        }
      });
    }, []);

    /**
     * Display a course based on the index
     */
    useEffect(() => {
      // Get the course
      if (courses && courses.length > 0 && courses[displayCourseIndex]) {
        setCourseName(courses[displayCourseIndex].title);

        // Get the projects of the selected course
        getProjects(courses[displayCourseIndex].projects as string[]).then(
          (projects) => setProjectsToDisplay(projects)
        );
      }
    }, [displayCourseIndex]);

    /**
     * Define the badges once we have the defined projects
     */
    useEffect(() => {
      const badgesToDef = projectsToDisplay.map((projects) =>
        projects ? projects.badgeImage : ""
      );
      setBadges(badgesToDef.slice(0, numBadges));
    }, [projectsToDisplay, numBadges]);

    return (
      <div className="w-full h-full justify-start items-start gap-5 inline-flex overflow-y-auto custom-scroll">
        {/* Main components */}
        <div className="grow shrink basis-0 flex-col justify-start items-start gap-5 inline-flex">
          <div className="self-stretch justify-start items-end gap-[21px] inline-flex">
            <MyLearningJourney
              courseName={courseName}
              projectsToDisplay={projectsToDisplay}
              numBadges={numBadges}
            />
            <MyProgress activity={activityData} />
          </div>
          <Leaderboard
            students={students}
            showStatus={false}
            showRightArrow={false}
            arrow={""}
          />
        </div>
        {/* Sidebar */}
        <div className="w-[344px] h-full flex-col justify-start items-start gap-5 inline-flex">
          <div className="w-full flex-grow flex flex-col gap-5">
            {/* Search bar */}
            {/* <SearchContentBar
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            /> */}
            {/* Knowledge points */}
            <MyKnowledgePoints points={50} />
            {/* Badge points */}
            <MyBadges badges={badges} />
            <div className="flex justify-start items-start gap-2 text-neutral-400 text-[10px] font-normal font-sans leading-3 tracking-tight">
              <div>
                *Knowledge points are based on many factors.{" "}
                <span className="text-gray-500 underline cursor-pointer">
                  Learn more
                </span>
                ...
              </div>
            </div>
          </div>
          <PopUpPanel
            type="profile"
            firstName={userFirstName}
            lastName={userLastName}
            showPopUp={showProfilePopUp}
            setShowPopUp={setShowProfilePopUp}
          />
        </div>
      </div>
    );
  }
);

export default StudentDashboard;
