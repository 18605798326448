/**
 * fieldVValidation.ts
 * -----------------
 * Contains all validation settings for each required entry
 * in the form. It follows the "react-hook-form" register structure.
 * For more information, visit: https://react-hook-form.com/docs/useform/register
 */
import { emailRegex } from "utils/regex";

export const emailValidation = {
  required: {
    value: true,
    message: "Please enter an email address",
  },
  pattern: {
    value: emailRegex,
    message: "Please enter a valid email address",
  },
};

export const testEmailValidation = {
  required: {
    value: true,
    message: "Please enter a value",
  },
  validate: {
    roleRequired: (value: string) =>
      ["master admin", "customer admin", "student"].includes(value) ||
      'Only allowed: "master admin", "customer admin" and "student"',
  },
};

export const passwordValidation = {
  required: {
    value: true,
    message: "You must specify a password",
  },
};
