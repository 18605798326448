import { MdDeleteOutline } from "react-icons/md";

interface ProjectPlanCardProps {
  filename: string;
  planSize: string;
  removePlan: () => void;
  onClickName: () => void;
}

const ProjectPlanCard: React.FC<ProjectPlanCardProps> = ({
  filename,
  planSize,
  removePlan,
  onClickName,
}) => {
  return (
    <div className="self-stretch px-3 py-4 rounded-xl border border-Subtle justify-between items-center inline-flex">
      <div className="grow shrink basis-0 h-fit rounded justify-start items-start gap-2 flex">
        <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
          <div
            className="w-[440px] text-neutral-700 text-base font-normal font-sans leading-[19px] overflow-hidden break-keep whitespace-nowrap text-ellipsis cursor-pointer hover:underline"
            onClick={onClickName}
          >
            {filename}
          </div>
          <div className="self-stretch justify-start items-start gap-2 inline-flex">
            <div className="justify-start items-start gap-1 flex">
              <div className="text-neutral-500 text-xs font-normal font-sans leading-[14px]">
                File type:
              </div>
              <div className="text-neutral-500 text-xs font-normal font-sans leading-[14px]">
                PDF
              </div>
            </div>
            {parseFloat(planSize) > 0 && (
              <>
                <div className="w-px self-stretch justify-start items-start gap-2 flex">
                  <div className="w-px self-stretch bg-neutral-200" />
                </div>
                <div className="grow shrink basis-0 h-3.5 justify-start items-start gap-1 flex">
                  <div className="text-neutral-500 text-xs font-normal font-['Poppins'] leading-[14px]">
                    File size:
                  </div>
                  <div className="text-neutral-500 text-xs font-normal font-['Poppins'] leading-[14px]">
                    {planSize} MB
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div
        className="p-2 bg-neutral-50 rounded-full justify-start items-start gap-2 flex cursor-pointer"
        onClick={removePlan}
      >
        <div className="w-6 h-6 relative">
          <MdDeleteOutline className="w-full h-full fill-neutral-600" />
        </div>
      </div>
    </div>
  );
};

export default ProjectPlanCard;
