// Parameters
const minCharacters = 10;

// Regex expresions
export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const passWordConditions = `The password must have at least ${minCharacters} characters, one uppercase letter, one lowercase letter, one number and one special character (@$!%*?&-_)`;
export const passWordRegex = new RegExp(
  `(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&\-_])[A-Za-z\d@$!%*?&\-_]{${minCharacters},}`
);
export const passWordRegexHookForm = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&\-_])[A-Za-z\d@$!%*?&\-_]{10,}/;

// Define the password Regex rules separated
export const minimumCharactersRegex = new RegExp(
  `[A-Za-z\d@$!%*?&\-_]{${minCharacters},}`
);
export const haveUppercaseRegex = /(?=.*[A-Z])/;
export const haveLowercaseRegex = /(?=.*[a-z])/;
export const haveDigitRegex = /(?=.*\d)/;
