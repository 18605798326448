import { MdAddCircleOutline } from "react-icons/md";
import { GoArrowLeft } from "react-icons/go";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { ClassListType } from "utils/interfaces";
import ClassesCardsList from "./ClassesCardsList";
import CreateNewClass from "./CreateNewClass";
import SavedSuccessfulPanel from "components/super-admin/utils/pop-up-panels/saved-successful-pop-up/SavedSuccessfulPanel";
import WarningPopUpMessage from "../WarningPopUpMessage";

interface AssignClassPanelProps {
  ref?: any;
  selectedData?: ClassListType;
  selectedClasses?: ClassListType[];
  closeOnceClickOnCard?: boolean;
  justCreateNew?: boolean;
  showWarningMessage?: boolean;
  selectedLesson?: string;
  lessonIdToAssign?: string;
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedClasses?: React.Dispatch<React.SetStateAction<ClassListType[]>>;
  setShowWarningMessage?: React.Dispatch<React.SetStateAction<boolean>>;
  getData?: () => Promise<any>;
  headerRef?: React.MutableRefObject<any>;
}

/**
 * Element that represent the pop up panel for adding/creating a class
 * @param selectedData: Object that contains a selected class. It's used to load the information
 *                      when editing a class.
 * @param selectedClasses: List of selected classes
 * @param closeOnceClickOnCard: Indicates if the pop up should be closed once the card is selected
 * @param justCreateNew: Indicates if the pop up should behave just from the "Create New Classes"
 *                       view. This is used on "Create" button in the header of users view.
 * @param showWarningMessage: Indicates if showing the warning message when click out 
 *                            the pop up window.
 * @param selectedLesson: Indicates if it's being used from the assign lesson button on each card
 *                          in the customers admin view. False means that is part of a form.
 * @param setShowPopUp: Function used to define if the pop should be showed or closed.
 * @param setSelectedClasses: Function used to set the selected classes.
 * @param setShowWarningMessage: Function used to define the warning message when click out the
 *                               pop up window.
 * @param getData: Function used to refresh an external data after creating a class.
 * @param headerRef: Reference to perform header actions and modify it's content.

 * @returns
 */
const AssignClassPanel: React.FC<AssignClassPanelProps> = forwardRef(
  (
    {
      selectedData,
      selectedClasses,
      closeOnceClickOnCard = true,
      justCreateNew = false,
      showWarningMessage = false,
      selectedLesson,
      lessonIdToAssign,
      setShowPopUp,
      setSelectedClasses,
      setShowWarningMessage,
      getData,
      headerRef,
    },
    ref
  ) => {
    // States definition
    // -> Start from "create-new" if we just want to use it for create
    const [popUpState, setPopUpState] = useState<
      "main" | "create-new" | "add-teacher"
    >(justCreateNew ? "create-new" : "main");
    const [showSaveSuccessPopUp, setShowSaveSuccessPopUp] = useState(false);

    /**
     * Define the list of functions that can be used from external components
     * using a reference
     */
    useImperativeHandle(ref, () => ({
      getPopUpState() {
        return popUpState;
      },
      getShowSaveSuccessPopUp() {
        return showSaveSuccessPopUp;
      },
      setPopUpState(state: "main" | "create-new" | "add-teacher"){
        setPopUpState(state);
      }
    }));

    /**
     * Definition of the function when pressed back button
     */
    const handlePressBack = () => {
      // If create new was pressed, then come back to the regular option
      if (popUpState === "add-teacher") setPopUpState("create-new");
      else if (popUpState === "create-new") {
        // If we are using the create new option, then close the pop up
        // once clicked (we will not want to watch the assign menu when
        // just creating)
        if (justCreateNew) closePopUpAction();
        else setPopUpState("main");
      }
      // Otherwise, close the pop up
      else closePopUpAction();
    };

    const closePopUpAction = () => {
      // Ensure the warning message is closed
      if (setShowWarningMessage) setShowWarningMessage(false);

      // Close the pop up
      setShowPopUp(false);
    };

    /**
     * Definition of the function executed after creating a parent
     * in the database
     */
    const afterCreateClass = () => {
      // Come back to the regular view (depending on the justCreateNew option)
      if (justCreateNew) setPopUpState("create-new");
      else setPopUpState("main");

      // And display the success message (if it has been created, not when editing)
      if (!selectedData) setShowSaveSuccessPopUp(true);
      // But if editing, then close the pop up
      else setShowPopUp(false);

      // And refresh the view if we are creating data from the main
      // view
      if (justCreateNew && getData) getData();
    };

    /**
     * Function to execute once clicked "Create another"
     */
    const pressCreateAnother = () => {
      // Hide the success pop up
      setShowSaveSuccessPopUp(false);

      // And go to create-new state in the pop up
      setPopUpState("create-new");
    }

    /**
     * Timer for the warning pop up message
     */
    useEffect(() => {
      if (showWarningMessage) {
        // Starting the timer
        const timer = setTimeout(() => {
          // Once finished, close the pop up
          if (setShowWarningMessage) setShowWarningMessage(false);
        }, 5000);
        return () => clearTimeout(timer);
      }
    }, [showWarningMessage]);

    return (
      <div className="relative w-full h-fit max-h-[80vh] flex-col justify-start items-start gap-6 inline-flex">
        {!showSaveSuccessPopUp && (
          <>
            {popUpState !== "add-teacher" && (
              <div className="self-stretch justify-between items-center inline-flex">
                <div className="justify-start items-center gap-3 flex">
                  <div
                    className="p-1.5 bg-neutral-50 rounded-full backdrop-blur-[32px] justify-center items-center flex cursor-pointer"
                    onClick={handlePressBack}
                  >
                    <div className="w-5 h-5 justify-center items-center flex">
                      <div className="w-5 h-5 relative">
                        <GoArrowLeft className="w-full h-full fill-dc-secondary-600" />
                      </div>
                    </div>
                  </div>
                  <div className="text-neutral-600 text-lg font-normal font-sans leading-snug">
                    {popUpState === "main"
                      ? selectedLesson && lessonIdToAssign
                        ? "Assign to class"
                        : "Classes"
                      : selectedData
                      ? "Edit Class"
                      : popUpState === "create-new"
                      ? "Create new class"
                      : ""}
                  </div>
                </div>
                {popUpState === "main" && (
                  <div
                    className="rounded justify-center items-center gap-2 flex cursor-pointer"
                    onClick={() => setPopUpState("create-new")}
                  >
                    <div className="w-6 h-6 relative">
                      <MdAddCircleOutline className="w-full h-full fill-dc-secondary-600" />
                    </div>
                    <div className="text-dc-secondary-600 text-lg font-semibold font-sans leading-snug">
                      Create new
                    </div>
                  </div>
                )}
              </div>
            )}
            {popUpState === "main" && (
              <ClassesCardsList
                selectedClasses={selectedClasses || []}
                closeOnceClickOnCard={closeOnceClickOnCard}
                selectedLesson={selectedLesson}
                lessonIdToAssign={lessonIdToAssign}
                setShowPopUp={setShowPopUp}
                setSelectedClasses={
                  setSelectedClasses ? setSelectedClasses : () => {}
                }
              />
            )}
            {["create-new", "add-teacher"].includes(popUpState) && (
              <CreateNewClass
                selectedData={selectedData}
                popUpState={popUpState}
                setPopUpState={setPopUpState}
                setShowPopUp={setShowPopUp}
                afterCreateFunction={afterCreateClass}
              />
            )}
          </>
        )}
        {showSaveSuccessPopUp && (
          <SavedSuccessfulPanel
            successfulType="class"
            setShowPopUp={justCreateNew ? setShowPopUp : setShowSaveSuccessPopUp}
            pressCreateAnother={pressCreateAnother}
            headerRef={headerRef}
          />
        )}
        {setShowWarningMessage && (
          <WarningPopUpMessage
            showWarningMessage={showWarningMessage}
            setShowWarningMessage={setShowWarningMessage}
          />
        )}
      </div>
    );
  }
);

export default AssignClassPanel;
