interface ViewTabBarProps {
  activeItem: string;
  tabOptions: string[];
  setActiveItem: React.Dispatch<React.SetStateAction<string>>;
  headerRef: React.MutableRefObject<any>;
}

const ViewTabBar: React.FC<ViewTabBarProps> = ({
  activeItem,
  tabOptions,
  setActiveItem,
  headerRef
}) => {
  // Function used when option is clicked
  const handleOnClick = (option: string) => {
    return () => {
      // Set the active lesson
      setActiveItem(option.toLowerCase());
      // And the header names
      headerRef.current.setHeaderTitle(option);
      headerRef.current.setHeaderDescription(`List of current ${option.toLowerCase()}`);
    }
  }

  return (
    <div className="p-1.5 bg-Tab rounded-full justify-start items-center gap-1.5 flex">
      {tabOptions.map((option) => (
        <div
          key={option}
          className={
            (activeItem === option.toLowerCase())
              ? "px-3 py-1.5 bg-white rounded-full justify-center items-center gap-2 flex cursor-pointer"
              : "px-3 py-1.5 rounded-full justify-center items-center gap-2 flex cursor-pointer"
          }
          onClick={handleOnClick(option)}
        >
          <div className="text-dc-secondary-600 text-sm font-semibold font-sans leading-[17px] select-none">
            {option}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ViewTabBar;
