import React, { ReactElement, useEffect, useRef, useState } from "react";
import watermarkImage from "../../assets/imgs/watermark1.png";
import { MdHowToReg, MdModelTraining, MdPsychology } from "react-icons/md";
import { motion, progress } from "framer-motion";
import { getProgressBarColor } from "components/super-admin/utils/progressUtils";

interface ProgressCardParams {
  percentage: number;
  progressType: "Enrolment rate" | "Progress" | "Aptitude";
  children?: React.ReactNode;
}

const ProgressCard: React.FC<ProgressCardParams> = ({
  percentage,
  progressType,
  children,
}) => {
  const [progressBarWidth, setProgressBarWidth] = useState<number>(0);
  const progressCardRef: any = useRef<React.RefObject<HTMLElement>>();

  useEffect(() => {
    setProgressBarWidth(
      (percentage * progressCardRef.current.clientWidth) / 100
    );
  }, [percentage]);

  return (
    <div
      ref={progressCardRef}
      className="w-1/3 h-[232px] bg-neutral-25 rounded-3xl border-neutral-300 flex-col justify-between items-start inline-flex grow overflow-hidden"
    >
      {/* Row containing the logo */}
      <div className="self-stretch m-6 justify-between items-start inline-flex">
        {/* icon */}
        <div className="w-10 h-10">
          {progressType === "Enrolment rate" ? (
            <MdHowToReg className="w-full h-full" color={"#999999"} />
          ) : progressType === "Progress" ? (
            <MdModelTraining className="w-full h-full" color={"#999999"} />
          ) : (
            <MdPsychology className="w-full h-full" color={"#999999"} />
          )}
        </div>
        <div className="w-10 h-10 opacity-0" />
      </div>

      {/* text and percentage */}
      <div className="m-6 h-[88px] flex-col justify-start items-start gap-2 inline-flex text-neutral-700">
        <div className="h-10">
          <div className="flex-col justify-center items-center inline-flex">
            <div className="text-[33px] font-semibold font-['Poppins'] leading-10">
              {percentage}%
            </div>
          </div>
        </div>
        <div className="self-stretch h-10 mr-3 flex-col justify-start items-start gap-1 flex">
          <div className="self-stretch text-neutral-700 text-lg font-normal font-['Poppins'] leading-snug">
            {progressType}
          </div>
          <div className="self-stretch text-xs font-normal leading-[14px]">
            {children}
          </div>
        </div>
      </div>

      {/* Progress bar */}
      <div className="self-stretch h-1.5 flex-col justify-between items-start flex">
        <motion.div
          initial={{
            width: 0,
          }}
          animate={{
            width: `${progressBarWidth}px`,
            transition: { delay: 1 },
          }}
          exit={{
            width: 0,
            transition: { delay: 0 },
          }}
          className={`h-1.5 rounded-[1px] ${getProgressBarColor(percentage)}`}
        />
      </div>
    </div>
  );
};

export default ProgressCard;
