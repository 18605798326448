import { useEffect, useState } from "react";
import {
  MdArrowDropDown,
  MdOutlineRadioButtonChecked,
  MdOutlineRadioButtonUnchecked,
} from "react-icons/md";
import { LocationListType } from "utils/interfaces";

interface CreateLocationProps {
  popUpState: "main" | "create-new" | "select-state";
  setPopUpState: React.Dispatch<
    React.SetStateAction<"main" | "create-new" | "select-state">
  >;
  setLocations: React.Dispatch<React.SetStateAction<LocationListType[]>>;
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateLocation: React.FC<CreateLocationProps> = ({
  popUpState,
  setPopUpState,
  setLocations,
  setShowPopUp
}) => {
  // Parameters definition
  const statesList = ["NSW", "VIC", "QLD", "WA", "SA", "TAS", "ACT", "NT"];

  // States definition
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postCode, setPostCode] = useState("");
  const [activateAddLocationButton, setActivateAddLocationButton] =
    useState(false);

  /**
   * Function to activate the add location button
   */
  useEffect(() => {
    // Define the condition to activate the save button
    setActivateAddLocationButton(!!(address && city && state && postCode));
  }, [address, city, state, postCode]);

  /**
   * Function to save the information
   */
  const handleAddLocation = () => {
    // Check if the button is activated
    if (!activateAddLocationButton) return;

    // If it's available to save the information, then add it to the list
    // of selected locations
    setLocations((currentList) => [...currentList, {
      address: address,
      city: city,
      state: state,
      postCode: postCode
    }])

    // And close the pop up
    setShowPopUp(false);
  }

  return (
    <>
      {popUpState === "create-new" && (
        <div className="self-stretch h-full flex-col justify-between items-start inline-flex">
          <div className="self-stretch h-fit flex-col justify-start items-start gap-6 inline-flex">
            <div className="w-full h-[51px] bg-neutral-50 rounded-lg justify-start items-center gap-2 inline-flex">
              <input
                type="text"
                value={address}
                onChange={(event) => setAddress(event.target.value)}
                className={
                  address
                    ? "w-full h-full p-3 text-neutral-700 text-base font-normal font-sans leading-[19px] bg-transparent border-none focus:outline-none"
                    : "w-full h-full p-3 text-neutral-300 text-base font-normal font-sans leading-[19px] bg-transparent border-none focus:outline-none"
                }
                placeholder="Street number and name"
              />
            </div>
            <div className="w-full h-[51px] bg-neutral-50 rounded-lg justify-start items-center gap-2 inline-flex">
              <input
                type="text"
                value={city}
                onChange={(event) => setCity(event.target.value)}
                className={
                  city
                    ? "w-full h-full p-3 text-neutral-700 text-base font-normal font-sans leading-[19px] bg-transparent border-none focus:outline-none"
                    : "w-full h-full p-3 text-neutral-300 text-base font-normal font-sans leading-[19px] bg-transparent border-none focus:outline-none"
                }
                placeholder="Suburb/Locality"
              />
            </div>
            <div
              className="self-stretch p-3 bg-neutral-50 rounded-lg justify-start items-center gap-2 inline-flex cursor-pointer"
              onClick={() => setPopUpState("select-state")}
            >
              <div className="grow shrink basis-0 h-[27px] py-1 justify-start items-start gap-2 flex">
                <div
                  className={
                    state
                      ? "grow shrink basis-0 h-[19px] text-neutral-700 text-base font-normal font-sans leading-[19px]"
                      : "grow shrink basis-0 h-[19px] text-neutral-300 text-base font-normal font-sans leading-[19px]"
                  }
                >
                  {state ? state : "State/Territory"}
                </div>
              </div>
              <div className="w-5 h-5 relative">
                <MdArrowDropDown className="w-full h-full fill-neutral-600" />
              </div>
            </div>
            <div className="w-full h-[51px] bg-neutral-50 rounded-lg justify-start items-center gap-2 inline-flex">
              <input
                type="text"
                value={postCode}
                onChange={(event) => setPostCode(event.target.value)}
                className={
                  postCode
                    ? "w-full h-full p-3 text-neutral-700 text-base font-normal font-sans leading-[19px] bg-transparent border-none focus:outline-none"
                    : "w-full h-full p-3 text-neutral-300 text-base font-normal font-sans leading-[19px] bg-transparent border-none focus:outline-none"
                }
                placeholder="Postcode"
              />
            </div>
          </div>
          <div
            className={
              activateAddLocationButton
                ? "w-full h-fit px-5 py-3 bg-dc-secondary-600 rounded justify-center items-center gap-2 inline-flex cursor-pointer"
                : "w-full h-fit px-5 py-3 bg-Subtle rounded justify-center items-center gap-2 inline-flex cursor-pointer"
            }
            onClick={handleAddLocation}
          >
            <div
              className={
                activateAddLocationButton
                  ? "text-white text-lg font-semibold font-sans leading-snug"
                  : "text-neutral-400 text-lg font-semibold font-sans leading-snug"
              }
            >
              Add Location
            </div>
          </div>
        </div>
      )}
      {popUpState === "select-state" && (
        <div className="self-stretch h-full flex-col justify-start items-start gap-6 inline-flex overflow-y-auto pop-up-scrollbar">
          {statesList.map((stateItem: string, index: number) => (
            <div
              key={index}
              className={
                state === stateItem
                  ? "self-stretch p-3 bg-neutral-50 border border-neutral-700 rounded-xl justify-center items-center gap-3 inline-flex cursor-pointer"
                  : "self-stretch p-3 bg-neutral-50 border border-transparent rounded-xl justify-center items-center gap-3 inline-flex cursor-pointer"
              }
              onClick={() => setState(stateItem)}
            >
              <div className="grow shrink basis-0 text-neutral-700 text-base font-semibold font-sans leading-tight">
                {stateItem}
              </div>
              <div className="w-6 h-6 relative">
                {state === stateItem ? (
                  <MdOutlineRadioButtonChecked className="w-full h-full fill-neutral-700" />
                ) : (
                  <MdOutlineRadioButtonUnchecked className="w-full h-full fill-neutral-500" />
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default CreateLocation;
