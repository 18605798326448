import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getCollectionRequest } from 'services/apiRequests';

const theme = createTheme({
  palette: {
    primary: {
      main: '#16a7b6',
    },
  },
});

interface ActivityChartParams {
  hasStudents: boolean;
}

const ActivityChart: React.FC<ActivityChartParams> = ({hasStudents}) => {
  const [chartData, setChartData] = useState([]);
  const [activityPeriod, setActivityPeriod] = useState('week');
  const [key, setKey] = useState(0);

  useEffect(() => {
    if (hasStudents)
      fetchChartData();
  }, [activityPeriod]);

  const fetchChartData = async () => {
    try {
      const response = await getCollectionRequest(
        "/api/activity",
        [],
        {
          activePeriod: activityPeriod
        }
      );
      console.log('got activity response', response)
      setChartData(response);
      setKey(prevKey => prevKey + 1);
    } catch (error) {
      console.error('Error fetching chart data:', error);
    }
  };

  const handlePeriodChange = (event: any) => {
    setActivityPeriod(event.target.value);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="h-[417px] p-6 bg-neutral-25 rounded-3xl flex flex-col justify-start items-start gap-8">
        <div className="w-full flex justify-between items-center">
          <div className="flex flex-col justify-start items-start">
            <div className="text-neutral-600 text-lg font-normal font-sans leading-snug">Activity</div>
            <div className="text-neutral-400 text-[10px] font-normal font-sans leading-3 tracking-tight">Student Engagement in active hours</div>
          </div>
          <FormControl variant="outlined" size="small" style={{ minWidth: 120 }}>
            <InputLabel>Period</InputLabel>
            <Select
              value={activityPeriod}
              onChange={handlePeriodChange}
              label="Period"
            >
              <MenuItem value="week">Week</MenuItem>
              <MenuItem value="month">Month</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="w-full h-[200px]">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={chartData}>
              {/* <CartesianGrid strokeDasharray="3 3" /> */}
              <XAxis dataKey="day" />
              <YAxis width={25}/>
              <Tooltip />
              <Bar barSize={25} radius={4} dataKey="value" fill="#16a7b6">
                {chartData.map((entry, index) => (
                  <rect key={`bar-${index}`} className="bar-animation" />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="w-full flex flex-col justify-start items-start gap-2">
          <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">Legend</div>
          <div className="text-neutral-500 text-[10px] font-normal font-sans leading-3 tracking-tight">Numeric units represent hours which the students spent interacting with the learning content</div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default ActivityChart;