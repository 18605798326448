import { Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ContactListType } from "utils/interfaces";

interface ConfirmPrimaryPopUpProps {
  showPopUp: boolean;
  selectedContacts: ContactListType[];
  setShowPopUp: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedContacts: React.Dispatch<React.SetStateAction<ContactListType[]>>;
  setShowSuccessPopUp: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfirmPrimaryPopUp: React.FC<ConfirmPrimaryPopUpProps> = ({
  showPopUp,
  selectedContacts,
  setShowPopUp,
  setSelectedContacts,
  setShowSuccessPopUp,
}) => {
  // Add as primary contact handler
  const addAsPrimaryContact = () => {
    // Put the last selected contact at first position to be the primary parent,
    // but double check that the selected contacts has a minimum of elements to
    // perform it
    if (selectedContacts.length > 1) {
      setSelectedContacts(
        selectedContacts.slice(-1).concat(selectedContacts.slice(0, -1))
      );
    }

    // Close the pop up
    setShowPopUp(false);

    // And display the pop up successful message
    setShowSuccessPopUp(true);
  };

  // Add as an additional contact handler
  const addAsAdditionalContact = () => {
    // Just close the pop up
    setShowPopUp(false);

    // And display the pop up successful message
    setShowSuccessPopUp(true);
  };

  return (
    <>
      <Transition
        show={showPopUp}
        as={Fragment}
        enter="transition-opacity ease-out duration-300"
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="fixed inset-0 w-full h-full bg-neutral-500 bg-opacity-40 backdrop-blur-[2px] z-10 flex flex-col flex-grow"
          onClick={() => setShowPopUp(false)}
        />
      </Transition>
      <Transition
        show={showPopUp}
        as={"div"}
        className="fixed w-[440px] h-[380px] left-0 right-0 ml-auto mr-auto bottom-5 p-6 border bg-white rounded-3xl flex-col justify-start items-center gap-10 inline-flex z-30"
        enter="transition-opacity ease-out duration-300"
        enterFrom="opacity-0 translate-y-1/2"
        enterTo="opacity-100 translate-y-0"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <>
          <div className="h-[332px] flex-col justify-start items-start gap-6 flex">
            <div className="self-stretch h-48 flex-col justify-start items-start gap-6 flex">
              <div className="self-stretch h-7 flex-col justify-start items-start gap-3 flex">
                <div className="self-stretch justify-center items-center gap-3 inline-flex">
                  <div className="grow shrink basis-0 text-stone-700 text-[23px] font-semibold font-sans leading-7">
                    Adding as Primary contact
                  </div>
                </div>
              </div>
              <div className="self-stretch justify-start items-start gap-2 inline-flex">
                <div className="grow shrink basis-0 text-stone-700 text-base font-normal font-sans leading-7">
                  Would you like to add this contact as the primary
                  parent/guardian for this student?
                </div>
              </div>
              <div className="self-stretch justify-start items-start gap-2 inline-flex">
                <div className="grow shrink basis-0 text-stone-700 text-base font-normal font-sans leading-7">
                  This can be changed later in the settings, as you add
                  additional contacts to this organisation.
                </div>
              </div>
            </div>
            <div className="self-stretch h-[46px] px-5 py-3 rounded justify-center items-center gap-2 inline-flex">
              <div
                className="text-amber-700 text-lg font-semibold font-sans leading-snug cursor-pointer"
                onClick={addAsPrimaryContact}
              >
                Yes, Add as primary contact
              </div>
            </div>
            <div className="self-stretch h-[46px] px-5 py-3 rounded justify-center items-center gap-2 inline-flex">
              <div
                className="text-amber-700 text-lg font-semibold font-sans leading-snug cursor-pointer"
                onClick={addAsAdditionalContact}
              >
                Add as an additional contact
              </div>
            </div>
          </div>
        </>
      </Transition>
    </>
  );
};

export default ConfirmPrimaryPopUp;
