import { MdArrowDropDown, MdOutlineArrowCircleLeft } from "react-icons/md";
import InputPlanFile from "components/super-admin/utils/placeholders/InputPlanFile";
import PopUpPanel from "../pop-up-panels/PopUpPanel";
import { useState } from "react";
import { Course, Program, Project, Module } from "utils/interfaces";

interface AdditionalAttachmentsProps {
  selectedLesson: string;
  selectedData: Program | Course | Project | Module | null;
  activateSaveButton: boolean;
  videoUrl: string;
  codingLanguage: string;
  planDocument: string | null;
  planName: string;
  planSize: string;
  setVideoUrl: React.Dispatch<React.SetStateAction<string>>;
  setCodingLanguage: React.Dispatch<React.SetStateAction<string>>;
  setPlanDocument: React.Dispatch<React.SetStateAction<string | null>>;
  setPlanName: React.Dispatch<React.SetStateAction<string>>;
  setPlanSize: React.Dispatch<React.SetStateAction<string>>;
  setStage: React.Dispatch<React.SetStateAction<number>>
  onSave: () => void;
}

const AdditionalAttachments: React.FC<AdditionalAttachmentsProps> = ({
  selectedLesson,
  selectedData,
  activateSaveButton,
  videoUrl,
  codingLanguage,
  planDocument,
  planName,
  planSize,
  setVideoUrl,
  setCodingLanguage,
  setPlanDocument,
  setPlanName,
  setPlanSize,
  setStage,
  onSave,
}) => {
  // States definition
  const [showCodingLanguagePopUp, setShowCodingLanguagePopUp] = useState(false);

  /**
   * Go to the previous stage
   */
  const handlePrev = () => {
    setStage(2);
  }

  return (
    <div className="w-[562px] h-fit p-6 bg-white rounded-3xl border-neutral-200 justify-start items-start gap-2 inline-flex">
      <div className="w-full flex-col justify-start items-start gap-12 inline-flex">
        <div className="self-stretch h-7 flex-col justify-start items-start gap-12 flex">
          <div className="self-stretch h-7 flex-col justify-start items-start gap-3 flex">
            <div className="self-stretch h-7 flex-col justify-start items-start flex">
              <div className="self-stretch text-neutral-700 text-[23px] font-semibold font-sans leading-7">
                Additional attachments
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch h-fit flex-col justify-start items-start gap-12 flex">
          <div className="self-stretch h-fit flex-col justify-start items-start gap-6 flex">
            <InputPlanFile
              selectedLesson={selectedLesson}
              selectedData={selectedData}
              planDocument={planDocument}
              planName={planName}
              planSize={planSize}
              setPlanName={setPlanName}
              setPlanSize={setPlanSize}
              setPlanDocument={setPlanDocument}
            />
            <div className="self-stretch h-fit flex-col justify-start items-start gap-1.5 flex">
              <div className="rounded justify-start items-start gap-2 inline-flex">
                <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
                  Video URL{selectedLesson === "projects" ? "" : "*"}
                </div>
              </div>
              <div className="w-full h-[51px] bg-neutral-50 rounded-lg justify-start items-center gap-2 inline-flex">
                <input
                  type="text"
                  value={videoUrl}
                  onChange={(event) => setVideoUrl(event.target.value)}
                  className={
                    videoUrl
                      ? "w-full h-full p-3 text-neutral-700 text-base font-normal font-sans leading-[19px] bg-transparent border-none focus:outline-none"
                      : "w-full h-full p-3 text-neutral-300 text-base font-normal font-sans leading-[19px] bg-transparent border-none focus:outline-none"
                  }
                  placeholder={`Add URL for the video`}
                />
              </div>
            </div>
            <div className="self-stretch h-fit flex-col justify-start items-start gap-1.5 flex">
              <div className="rounded justify-start items-start gap-2 inline-flex">
                <div className="text-neutral-500 text-xs font-normal font-sans uppercase leading-[14px] tracking-wide">
                  Coding language{selectedLesson === "projects" ? "" : "*"}
                </div>
              </div>
              <div
                className="self-stretch p-3 bg-neutral-50 rounded-lg justify-start items-center gap-2 inline-flex cursor-pointer"
                onClick={() => setShowCodingLanguagePopUp(true)}
              >
                <div className="grow shrink basis-0 h-[27px] py-1 justify-start items-start gap-2 flex">
                  <div
                    className={
                      codingLanguage
                        ? "grow shrink basis-0 h-[19px] text-neutral-700 text-base font-normal font-sans leading-[19px]"
                        : "grow shrink basis-0 h-[19px] text-neutral-300 text-base font-normal font-sans leading-[19px]"
                    }
                  >
                    {codingLanguage
                      ? codingLanguage
                      : `Pick a language for this ${selectedLesson.slice(
                          0,
                          -1
                        )}`}
                  </div>
                </div>
                <div className="w-5 h-5 relative">
                  <MdArrowDropDown className="w-full h-full fill-neutral-600" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch h-[46px] justify-between items-center inline-flex">
          <div
            className="rounded justify-center items-center gap-2 flex cursor-pointer"
            onClick={handlePrev}
          >
            <div className="w-6 h-6 relative">
              <MdOutlineArrowCircleLeft className="w-full h-full fill-dc-secondary-600" />
            </div>
            <div className="text-dc-secondary-600 text-lg font-semibold font-sans leading-snug">
              Prev
            </div>
          </div>
          {activateSaveButton ? (
            <div
              className="w-[219px] h-[46px] px-5 py-3 bg-dc-secondary-600 flex rounded justify-center items-center gap-2 cursor-pointer"
              onClick={onSave}
            >
              <div className="text-white text-lg font-semibold font-sans leading-snug">
                Save
              </div>
            </div>
          ) : (
            <div className="w-[219px] h-[46px] px-5 py-3 bg-Subtle flex rounded justify-center items-center gap-2 cursor-pointer">
              <div className="text-neutral-400 text-lg font-semibold font-sans leading-snug">
                Save
              </div>
            </div>
          )}
        </div>
      </div>
      <PopUpPanel
        type="language"
        selectedLesson={selectedLesson}
        showPopUp={showCodingLanguagePopUp}
        setShowPopUp={setShowCodingLanguagePopUp}
        setOption={setCodingLanguage}
      />
    </div>
  );
};

export default AdditionalAttachments;
